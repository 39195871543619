import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-modal';

import * as actionCreators from '../../ducks/applications';
import * as generalActions from '../../ducks/general';

const propTypes = {
  authToken: PropTypes.string.isRequired,
  revokeAuthorization: PropTypes.func.isRequired,
  selectedAuthorization: PropTypes.object,
  showPrompt: PropTypes.bool,
  user: PropTypes.shape({
    trackingId: PropTypes.string,
    sip: PropTypes.shape({
      uri: PropTypes.string,
      password: PropTypes.string,
    }),
    phoneNumber: PropTypes.string,
    totalNumberBurners: PropTypes.number,
    credits: PropTypes.number,
    version: PropTypes.number,
    id: PropTypes.string,
    dateCreated: PropTypes.number,
    carrierName: PropTypes.string,
    createdSample: PropTypes.bool,
    lastUpdatedDate: PropTypes.number,
    countryCode: PropTypes.string,
    superUser: PropTypes.bool,
    platform: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    lifetimeSubscriptions: PropTypes.number,
  }).isRequired,
};

const defaultProps = {
  selectedAuthorization: null,
  showPrompt: false,
};

class ConfirmRevokeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.performConfirmAction = this.performConfirmAction.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { showPrompt, selectedAuthorization } = this.props;

    if (prevProps.showPrompt === showPrompt && prevProps.selectedAuthorization === selectedAuthorization) {
      return;
    }
    if (showPrompt && selectedAuthorization) {
      this.openModal();
    } else if (!showPrompt) {
      this.closeModal();
    }
  }

  openModal() {
    this.setState(() => ({
      modalIsOpen: true,
    }));
  }

  closeModal() {
    const { closeConfirmModal } = this.props;

    this.setState(() => ({
      modalIsOpen: false,
    }));
    closeConfirmModal();
  }

  performConfirmAction() {
    const { revokeAuthorization, user, authToken, selectedAuthorization } = this.props;

    return revokeAuthorization(user.id, authToken, selectedAuthorization);
  }

  render() {
    const { modalIsOpen } = this.state;
    const { selectedAuthorization } = this.props;

    return (
      <Modal
        contentLabel="Confirm Revoke Modal"
        isOpen={modalIsOpen}
        onRequestClose={this.closeModal}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        bodyOpenClassName={null}
        className="burner-modal"
        overlayClassName="burner-modal-overlay"
        ariaHideApp={false}
      >
        <div id="revokeModal">
          <div className="row">
            <div className="large-12 columns">
              <h2 className="renewal-title">
                Revoke application {selectedAuthorization ? selectedAuthorization.clientName : ''}?
              </h2>
              <p className="renewal-message">
                Once you revoke access to {selectedAuthorization ? selectedAuthorization.clientName : ''}, the
                application will not have access to your Burner anymore.{' '}
              </p>
              <button type="submit" onClick={this.closeModal} className="cancel-button submit">
                Cancel
              </button>
              <button
                type="submit"
                onClick={() => this.performConfirmAction()}
                className="flat button selection-button submit"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

ConfirmRevokeModal.propTypes = propTypes;
ConfirmRevokeModal.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    authToken: state.auth.authToken,
    selectedAuthorization: state.applicationsPage.selectedAuthorization,
    showPrompt: state.applicationsPage.showPrompt,
    user: state.auth.user,
  };
}

const mapDispatchToProps = { ...actionCreators, ...generalActions };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmRevokeModal);
