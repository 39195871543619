import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { shuffle, slice } from 'lodash';
import { Grid, Box } from '@mui/material';

import { renderNumber } from '../utils/phone';

const propTypes = {
  numbers: PropTypes.arrayOf(
    PropTypes.shape({
      city: PropTypes.string,
      phoneNumber: PropTypes.string,
      state: PropTypes.string,
      rateCenter: PropTypes.string,
      type: PropTypes.string,
    })
  ).isRequired,
  onChosenNumber: PropTypes.func.isRequired,
  maxResultsCount: PropTypes.number,
  clientInfo: PropTypes.shape({
    color: PropTypes.string,
    name: PropTypes.string,
    sampleSku: PropTypes.string,
    thumbnailURL: PropTypes.string,
  }),
};

const defaultProps = {
  maxResultsCount: null,
};

class AvailableNumberList extends Component {
  handleChosenNumber = (number) => {
    const { onChosenNumber } = this.props;
    onChosenNumber(number);
  };

  renderAvailablePhoneNumbers = (numbers) => {
    if (!numbers || numbers.length === 0) {
      return null;
    }

    return (
      <Box sx={{ mt: 4 }} className="PickNumber__container">
        <Grid
          container
          spacing={1.5}
          justifyContent="center"
          className="PickNumber__item-wrappper"
        >
          {numbers.map((item) => (
            <Grid
              item
              key={item.phoneNumber}
              xs={6} sm={6}
              md={4} lg={3}
              className="PickNumber__item"
            >
              <Box
                className="PickNumber__cell medium"
                component="a"
                onClick={() => this.handleChosenNumber(item.phoneNumber)}
              >
                {renderNumber(item.phoneNumber)}
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };

  render() {
    const { numbers, maxResultsCount } = this.props;
    const displayedNumbers = maxResultsCount
      ? slice(shuffle(numbers), 0, maxResultsCount)
      : numbers;

    return this.renderAvailablePhoneNumbers(displayedNumbers);
  }
}

AvailableNumberList.propTypes = propTypes;
AvailableNumberList.defaultProps = defaultProps;

export default AvailableNumberList;
