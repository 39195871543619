export const CLEAR_BAR = 'burner-web/progress-bar/CLEAR_BAR';
export const SET_NEXT_STEP = 'burner-web/progress-bar/SET_NEXT_STEP';
export const SET_PREV_STEP = 'burner-web/progress-bar/SET_PREV_STEP';
export const SET_TOTAL_STEPS_COUNT = 'burner-app/progress-bar/SET_TOTAL_STEPS_COUNT';
export const SET_CURRENT_STEP = 'burner-app/progress-bar/SET_CURRENT_STEP';
export const SHOW_PROGRESS_BAR = 'burner-app/progress-bar/SHOW_PROGRESS_BAR';
export const HIDE_PROGRESS_BAR = 'burner-app/progress-bar/HIDE_PROGRESS_BAR';

export default function reducer(
  state = {
    totalSteps: 4,
    currentStep: 1,
  },
  action
) {
  switch (action.type) {
    case SHOW_PROGRESS_BAR:
      return Object.assign({}, state, {
        isShowBar: true,
        totalSteps: 4,
      });
    case HIDE_PROGRESS_BAR :
      return Object.assign({}, state, {
        isShowBar: false,
      });
    case CLEAR_BAR:
      return Object.assign({}, state, {
        totalSteps: 4,
        currentStep: 1,
      });
    case SET_NEXT_STEP:
      return Object.assign({}, state, {
        currentStep: state.currentStep + 1,
      });
    case SET_PREV_STEP:
      return Object.assign({}, state, {
        currentStep: state.currentStep - 1,
      });
    case SET_TOTAL_STEPS_COUNT:
      return Object.assign({}, state, {
        totalSteps: action.totalSteps,
      });
    case SET_CURRENT_STEP:
      return Object.assign({}, state, {
        currentStep: action.currentStep,
      });
    default:
      return state;
  }
}

export function setCurrentStep(currentStep) {
  return {
    type: SET_CURRENT_STEP,
    currentStep,
  };
}

export function clearProgressBar() {
  return {
    type: CLEAR_BAR,
  };
}

export function setNextStep() {
  return {
    type: SET_NEXT_STEP,
  };
}

export function setPrevStep() {
  return {
    type: SET_PREV_STEP,
  };
}

export function setTotalSteps(totalSteps) {
  return {
    type: SET_TOTAL_STEPS_COUNT,
    totalSteps,
  };
}

export function showProgressBar() {
  return {
    type: SHOW_PROGRESS_BAR,
  };
}

export function hideProgressBar() {
  return {
    type: HIDE_PROGRESS_BAR,
  };
}
