import React from 'react';
import PropTypes from 'prop-types';

import NumberPicker from '../components/purchase/NumberPicker';

import '!style-loader!css-loader!sass-loader!../../styles/utils-rebrand.scss';
import '!style-loader!css-loader!sass-loader!../../styles/number-picker-rebrand.scss';
import '!style-loader!css-loader!sass-loader!../../styles/typography-rebrand.scss';

const propTypes = {
  areaCode: PropTypes.string.isRequired,
  isNumberTaken: PropTypes.bool.isRequired,
  reprovisionNumber: PropTypes.func.isRequired,
  setSelectedNumber: PropTypes.func.isRequired,
  subscription: PropTypes.shape({
    totalConcurrentBurners: PropTypes.number,
    totalBurnersAllowedInPeriod: PropTypes.number,
    sku: PropTypes.string,
  }),
};

function PickNumberPage({
  areaCode,
  isNumberTaken,
  reprovisionNumber,
  setSelectedNumber,
  subscription = null,
}) {
  return (
    <div className="PickNumber__wrapper Main__container Main__container--secondary grid-x align-center-middle">
      <div className="cell small-12 medium-10">
        <div className="text-center number-picker">
          <h1 className="Header__title Header__numberPicker">Choose your number</h1>

          {
            isNumberTaken && (
              <h4 className="Error__text">
                Unfortunately the number you\'ve selected is no longer available. Please select another number.
              </h4>
            )
          }

          <NumberPicker
            className="u-margin-top-35px"
            areaCode={areaCode}
            onChosenNumber={(number) => {
              if (isNumberTaken) {
                reprovisionNumber(number, subscription);
              } else {
                setSelectedNumber(number);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}

PickNumberPage.propTypes = propTypes;

export default PickNumberPage;
