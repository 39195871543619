import React from 'react';

export default function SpamBlockingIcon() {
  return (
    <svg width="72" height="85" viewBox="0 0 72 85" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M36.0263 24.5273C33.483 24.5273 31.0608 25.254 29.0625 26.5257L47.2896 44.7527C48.5613 42.7544 49.2879 40.3322 49.2879 37.7889C49.2274 30.4617 43.2929 24.5273 36.0263 24.5273Z"
        fill="#111111"/>
      <path
        d="M22.7793 37.7275C22.7793 45.0547 28.7137 50.9891 36.0408 50.9891C38.5841 50.9891 41.0063 50.2624 43.0047 48.9907L24.7776 30.7637C23.506 32.8225 22.7793 35.1842 22.7793 37.7275Z"
        fill="#111111"/>
      <path
        d="M68.3525 54.3213V13.3255L36.0161 3.75781L3.67969 13.3255V54.3213L31.4744 84.0538H40.2549L68.3525 54.3213ZM16.699 37.7292C16.699 27.0715 25.3584 18.4121 36.0161 18.4121C46.6738 18.4121 55.3332 27.0715 55.3332 37.7292C55.3332 48.3869 46.6738 57.0463 36.0161 57.0463C25.3584 57.0463 16.699 48.3869 16.699 37.7292Z"
        fill="#111111"/>
      <path
        d="M72 10.5971L36.0303 0L0 10.5971V55.7712L26.4626 84.0504H31.4281L3.6333 54.3179V13.3221L36.0303 3.75441L68.3667 13.3221V54.3179L40.2691 84.0504H45.2346L72 55.7712V10.5971Z"
        fill="#111111"/>
    </svg>
  )
}
