import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import { connect } from 'react-redux';

import { validateAreaCode } from '../../utils/phone';
import Button from '../form/button/Button';

import * as chooseActions from '../../ducks/chooseNumber';
import * as progressBar from '../../ducks/progressBar';
import * as errorActions from '../../ducks/error';

const SUBSCRIBEPAGE = 'SubscribePage';

const propTypes = {
  headlineText: PropTypes.string,
  onAreaCodeEnter: PropTypes.func,
  template: PropTypes.string,
};

/**
 * Area code input box for the subscription purchase page (aka. the premium-3 page).
 */
class AreaCodeInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      areaCode: '',
      errorMessage: null,
      submitButtonMobile: 'fixed',
    };

    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.areaCodePickerField = this.areaCodePickerField.bind(this);
    this.renderErrorSection = this.renderErrorSection.bind(this);
    this.renderSearchBox = this.renderSearchBox.bind(this);
    this.renderPicker = this.renderPicker.bind(this);
    this.renderPickerAlt = this.renderPickerAlt.bind(this);
    this.handleUpdateError = this.handleUpdateError.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.buttonRef = React.createRef();
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    const { error } = this.props;

    if (error && error !== prevProps.error) {
      this.handleUpdateError();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (window.innerWidth < 753) {
      if (!this.inputRef?.current.contains(event.target)) {
        this.handleBlur();
      }

      if (this.buttonRef?.current.contains(event.target)) {
        this.handleSubmit();
      }
    }
  }

  handleFocus() {
    if (window.innerWidth < 753) {
      this.setState({
        submitButtonMobile: 'mobile',
      });
    }
  }

  handleBlur() {
    if (window.innerWidth < 753) {
      this.setState({
        submitButtonMobile: 'fixed',
      });
    }
  }

  handleUpdateError() {
    const { error } = this.props;

    this.setState({
      errorMessage: error,
    });
  }

  handlePhoneChange(values) {
    const { clearErrorNumber, clearErrors } = this.props;
    clearErrorNumber();
    clearErrors();

    this.setState(() => ({
      areaCode: values.value,
      errorMessage: null,
    }));
  }

  handleSubmit(event) {
    event?.preventDefault();

    const { areaCode } = this.state;
    const { onAreaCodeEnter } = this.props;

    const errorMessage = validateAreaCode(areaCode);

    if (errorMessage) {
      this.setState(() => ({
        errorMessage,
      }));
      return;
    }

    onAreaCodeEnter(areaCode);
  }

  areaCodePickerField() {
    const { areaCode } = this.state;

    return (
      <div
        ref={this.inputRef}
      >
        <label htmlFor="AreaCode" className="Form__fieldWrapper Form__fieldArea text-left cell">Area code</label>
        <NumberFormat
          id="AreaCode"
          name="AreaCode"
          format="###"
          type="tel"
          className="area-code Input__field text-center medium"
          onValueChange={this.handlePhoneChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          value={areaCode}
          placeholder="234"
        />
      </div>
    );
  }

  renderErrorSection() {
    const { errorMessage } = this.state;

    return <div className="error error-msg md:center">{errorMessage}</div>;
  }

  renderSearchBox() {
    const { template } = this.props;
    const picker =
      template === SUBSCRIBEPAGE ? this.renderPicker() : this.renderPickerAlt();

    return <div className="CodeInput__wrapper grid-x align-center u-width-full">{picker}</div>;
  }

  renderPicker() {
    const { error, authError } = this.props;
    const { errorMessage } = this.state;

    const errorToShow = errorMessage || error || (authError.isUnauthorized && authError.errorMessage);

    return (
      <div className="CodeInput__container cell medium-5 small-12 position-fixed">
        <form onSubmit={this.handleSubmit} className="CodeInput__form">
          {errorToShow && (
            <div
              className="CodeInput__error text-left medium-text-center Form__outputWrapper grid-x text-error align-center">
              <div className="cell large-12">
                <div className="error error-msg md:center">{errorToShow}</div>
              </div>
            </div>
          )}

          <div className="Form__inputWrapper--area u-margin-x-auto grid-y grid-padding-x align-center">
            {this.areaCodePickerField()}
            <div
              ref={this.buttonRef}
              className={
                classNames('Form__submitWrapper Form__fieldArea--submit ',
                `FieldArea__submit--${this.state.submitButtonMobile}`
                )
              }
            >
              <Button text="Continue" type="submit" withArrow className="AreaCode__cta" />
            </div>
          </div>
        </form>
      </div>
    );
  }

  renderPickerAlt() {
    return (
      <div
        className="small-12 small-offset-0 medium-6 medium-offset-3 large-4 large-offset-4 columns number-input-container">
        <form onSubmit={this.handleSubmit}>
          {this.areaCodePickerField()}
          <button className="button expanded w-button" type="submit">
            Find Numbers
          </button>
          <div className="centered">
            <em>We currently support US and Canadian numbers</em>
          </div>
        </form>
      </div>
    );
  }

  render() {
    return this.renderSearchBox();
  }
}

AreaCodeInput.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    authError: state.error,
    error: state.chooseNumberPage.error,
  };
}

const mapDispatchToProps = { ...chooseActions, ...progressBar, ...errorActions };

export default connect(mapStateToProps, mapDispatchToProps)(AreaCodeInput);
