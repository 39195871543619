import React from 'react';

export function QuotesIcon() {
  return (
    <svg
      width="37"
      height="36"
      viewBox="0 0 37 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.66992 13.3352C1.66992 8.7302 3.96492 6.8852 8.11992 6.8252L8.56992 9.0002C6.16992 9.2552 4.99992 10.5002 5.14992 12.4652H7.30992V18.0002H1.66992V13.3352ZM10.5199 13.3352C10.5199 8.7302 12.8149 6.8852 16.9699 6.8252L17.4199 9.0002C15.0199 9.2552 13.8349 10.5002 13.9999 12.4652H16.1599V18.0002H10.5199V13.3352ZM25.9099 22.6652C25.9099 27.2702 23.6149 29.1152 19.4599 29.1752L19.0249 27.0002C21.4249 26.7602 22.6099 25.5002 22.4449 23.5502H20.2699V18.0002H25.9099V22.6652ZM34.7599 22.6652C34.7599 27.2702 32.4649 29.1152 28.3099 29.1752L27.8599 27.0002C30.2599 26.7602 31.4449 25.5002 31.2799 23.5502H29.1199V18.0002H34.7599V22.6652Z" fill="#111111"/>
    </svg>
  )
}
