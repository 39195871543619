import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import * as generalActionCreators from '../../ducks/general';
import * as verifyAction from '../../ducks/verify';
import * as subscribeAction from '../../ducks/subscribe';
import * as errorAction from '../../ducks/error';
import {getStoragePhoneData} from '../../utils/sessionStorage';

class ResendCodeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: this.props.showResendOptionsModal,
    };

    this.closeModal = this.closeModal.bind(this);
    this.handleResendSms = this.handleResendSms.bind(this);
  }

  closeModal() {
    const { toggleResendModal } = this.props;

    toggleResendModal();
  }

  handleResendSms() {
    const { resendVerifyCode, formState, resetVerifyCodeError } = this.props;
    resetVerifyCodeError();

    const phone = formState?.values ? parsePhoneNumberFromString(
      formState?.values.phoneNumber,
      formState?.values.selectedCountry?.countryCode
    ) : null;
    const phoneFromStorage = getStoragePhoneData();

    resendVerifyCode(phone?.number || phoneFromStorage?.number);
  }

  render() {
    const { showResendOptionsModal } = this.props;

    return (
      <Modal
        contentLabel="Resend Modal"
        isOpen={showResendOptionsModal}
        onRequestClose={this.closeModal}
        bodyOpenClassName={null}
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        className="burner-modal burner-modal--resend"
        overlayClassName="burner-modal-overlay"
        ariaHideApp={false}
      >
        <div id="redendModal">
          <div className="row">
            <h1 className="modal__resend--title medium">
              How would you like to get your code?
            </h1>
            <div className="modal__resend--columns large-12 columns">
              <button
                className="modal__resend--button button u-width-full medium show-for-small hide-for-medium"
                onClick={this.handleResendSms}
              >
                Re-send code via text message
              </button>
              <button
                className="modal__resend--button button u-width-full medium hide-for-small show-for-medium"
                onClick={this.handleResendSms}
              >
                Send via text message
              </button>
              <button type="button" onClick={this.closeModal} className="medium u-link show-for-small hide-for-medium">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

ResendCodeModal.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    error: state.error,
    formState: state.form.subscribe,
    showResendOptionsModal: state.verifyPage.showResendOptionsModal,
    subscription: state.subscribePage.subscription,
    isResend: state.verifyPage.isResend,
    codeWasSent: state.verifyPage.codeWasSent,
  };
}

const mapDispatchToProps = { ...subscribeAction, ...generalActionCreators, ...verifyAction, ...errorAction};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ResendCodeModal));
