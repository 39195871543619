import React, { Component } from 'react';
import NumberFormat from 'react-number-format';

class PhoneInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleRef = this.handleRef.bind(this);
  }

  handleChange(data) {
    const { value } = data;
    const { handleChange, input } = this.props;

    this.setState({ value });

    handleChange();
    return input.onChange(value);
  }

  handleRef(input) {
    if (input && window.innerWidth < 753) {
      input.focus()
    }
  }

  render() {
    const { name, type, id, numberFormat, placeholder, allowLeadingZeros, className, handleBlurField } = this.props;

    return (
      <NumberFormat
        getInputRef={this.handleRef}
        name={name}
        value={this.state.value}
        type={type}
        id={id}
        onValueChange={this.handleChange}
        format={numberFormat}
        placeholder={placeholder}
        allowLeadingZeros={allowLeadingZeros}
        className={className}
        onBlur={handleBlurField}
      />
    );
  }
}

export default React.memo(PhoneInput);
