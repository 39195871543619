import React, { Component } from 'react';
import Lottie from 'react-lottie';
import animationData from './lootie.json';

import '!style-loader!css-loader!sass-loader!../../../styles/lootie-animation.scss';
import classNames from 'classnames';

class ScrollButton extends Component {
  handleScroll = () => {
    const headerHeight = window.innerWidth < 753 ? 50 : 100
    const screenHeight = window.innerHeight - headerHeight;

    const currentScrollPosition = window.scrollY;
    const remainingScroll = screenHeight - (currentScrollPosition % screenHeight);

    window.scrollBy({
      top: remainingScroll,
      behavior: 'smooth',
    });
  };

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData,
    };

    return (
      <button className={classNames('LootieWebflowAnimation__container', this.props.className)} onClick={this.handleScroll}>
        <Lottie options={defaultOptions} height={82} width={82} />
      </button>
    );
  }
}

ScrollButton.defaultProps = {
  className: '',
}

export default ScrollButton;
