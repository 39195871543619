import axios from 'axios';
import cookie from 'react-cookie';
import {
  AUTHENTICATION_KEY,
  CURRENT_USER,
  BASE_URL,
  V2_BASE_URL,
  DEFAULT_SKU,
  CUSTOM_WEB_EVENT,
  SUBMIT_PAYMENT_INFO,
  AMPLITUDE_VERIFY_CODE_SENT,
  VERIFY_SENT, NUMBERS_RECEIVED,
} from '../constants';
import { handleError, RECEIVE_PHONE_NUMBERS } from './chooseNumber';
import { fetchVerifyCode, SUBSCRIPTION_CREATED, RECEIVE_CODE, SUBSCRIBE_MODAL_TOGGLED } from './verify';
import { hasError, validationCodeError, VERIFY_CODE_ERROR, RESET_VERIFY_CODE_ERROR, clearErrors } from './error';
import { clearUserInfo, receiveSubscribtions, requesting } from './general';
import { getCountry } from '../utils/phone';
import { getCurrentUser } from '../utils/user';
import { extractError } from '../utils/request';
import * as amplitude from '@amplitude/analytics-browser';
import {
  amplitudeDeviceProperty,
  trackAmplitudeFailedEvent,
  verifyNumberSignUpEvents,
} from '../utils/analytics';
import {
  addStorageSelectedNumber,
  defaultSessionOption,
  getStoragePlanData,
} from '../utils/sessionStorage';

export const RESET_PHONE_NUMBER_SEARCH = 'burner-app/subscribe/RESET_PHONE_NUMBER_SEARCH';
export const SET_SUBSCRIPTION_PLAN = 'burner-app/subscribe/SET_SUBSCRIPTION_PLAN';
export const SET_SELECTED_NUMBER = 'burner-app/subscribe/SET_SELECTED_NUMBER';
export const NUMBER_IS_TAKEN = 'burner-app/subscribe/NUMBER_IS_TAKEN';
export const NUMBER_ASSOCIATED = 'burner-app/subscribe/NUMBER_ASSOCIATED';
export const PHONE_DOES_NOT_EXIST = 'Could not find a phone number.';
export const SET_PAYMENT_ERROR = 'burner-app/subscribe/SET_PAYMENT_ERROR'
export const SET_TRIAL_PLAN = 'burner-app/subscribe/SET_TRIAL_PLAN';
export const CHECK_USER_SUBSCRIPTION = 'burner-app/subscribe/CHECK_USER_SUBSCRIPTION';
export const SUBSCRIBE_MANAGE_NEXT_STEP = 'burner-app/subscribe/SUBSCRIBE_MANAGE_NEXT_STEP';
export default function reducer(state = { sku: 'com.adhoclabs.burner.subscription.month.1' }, action) {
  switch (action.type) {
    case SUBSCRIPTION_CREATED:
      return Object.assign({}, state, {
        subscription: action.response,
        userEmail: action.email,
        accountPhoneNumber: action.accountPhoneNumber,
      });
    case RECEIVE_CODE:
      return Object.assign({}, state, {
        sku: action.sku || DEFAULT_SKU, // needed for subscribe page
      });
    case RECEIVE_PHONE_NUMBERS:
      return Object.assign({}, state, {
        numbers: action.numbers,
      });
    case RESET_PHONE_NUMBER_SEARCH:
      return Object.assign({}, state, {
        numbers: null,
        selectedSku: null,
        numberAssociated: false,
        selectedNumber: null,
      });
    case SET_SELECTED_NUMBER:
      return Object.assign({}, state, {
        selectedNumber: action.selectedNumber,
      });
    case NUMBER_IS_TAKEN:
      return Object.assign({}, state, {
        numbers: state.numbers?.filter((item) => item.phoneNumber !== state.selectedNumber),
        selectedNumber: null,
        isNumberTaken: true,
        errorNumber: true,
      });
    case VERIFY_CODE_ERROR:
      return Object.assign({}, state, {
        verifyErrorMessage: action.error,
      });
    case RESET_VERIFY_CODE_ERROR:
      return Object.assign({}, state, {
        verifyErrorMessage: null,
      });
    case NUMBER_ASSOCIATED:
      return Object.assign({}, state, {
        numberAssociated: true,
        selectedNumber: action.selectedNumber,
      });
    case SET_SUBSCRIPTION_PLAN:
      return Object.assign({}, state, {
        selectedSku: action.sku,
        price: action.price,
        numLines: action.numLines,
        freeTrialLength: action.freeTrialLength,
        isYearly: action.isYearly,
      });
    case SET_PAYMENT_ERROR:
      return Object.assign({}, state, {
        paymentError: action.paymentError,
      });
    case SET_TRIAL_PLAN:
      return Object.assign({}, state, {
        isFreeTrial: action.isFreeTrial,
      });
    case CHECK_USER_SUBSCRIPTION:
      return Object.assign({}, state, {
        isSubscribeUser: action.isSubscribeUser,
        isAccessToNextStep: action.isAccessToNextStep,
      });
    case SUBSCRIBE_MANAGE_NEXT_STEP :
      return Object.assign({}, state, {
        isAccessToNextStep: action.isAccessToNextStep,
      });
    default:
      return state;
  }
}

export function redirectToNextSubscribePage(isAccessToNextStep) {
  return {
    type: SUBSCRIBE_MANAGE_NEXT_STEP,
    isAccessToNextStep,
  }
}

function setSubscribeExist(isSubscribeUser, isAccessToNextStep) {
  return {
    type: CHECK_USER_SUBSCRIPTION,
    isSubscribeUser,
    isAccessToNextStep,
  }
}

export function setTrialPlan(isFreeTrial) {
  return {
    type: SET_TRIAL_PLAN,
    isFreeTrial,
  }
}

export function setPaymentError(paymentError) {
  return {
    type: SET_PAYMENT_ERROR,
    paymentError,
  };
}

export function selectSubscriptionPlan(plan) {
  return {
    type: SET_SUBSCRIPTION_PLAN,
    sku: plan.sku,
    price: plan.price,
    numLines: plan.numLines,
    freeTrialLength: plan.freeTrialLength,
    isYearly: plan.isYearly,
  };
}

export function setSelectedNumber(selectedNumber) {
  return {
    type: SET_SELECTED_NUMBER,
    selectedNumber,
  };
}

/**
 * Dispatches an event to state that the verify modal has toggled on, so it doesn't toggle again (unless user closes it).
 */
export function modalToggled() {
  return {
    type: SUBSCRIBE_MODAL_TOGGLED,
  };
}

export function resetSearch() {
  return {
    type: RESET_PHONE_NUMBER_SEARCH,
  };
}

function numberIsTaken() {
  return {
    type: NUMBER_IS_TAKEN,
  };
}

function numberAssociated(selectedNumber) {
  return {
    type: NUMBER_ASSOCIATED,
    selectedNumber,
  };
}


export function associateSelectedNumberWithSub(selectedNumber, subscription) {
  const userId = getCurrentUser().id;
  const authToken = cookie.load(AUTHENTICATION_KEY);

  return (dispatch) => {
    dispatch(requesting());

    return axios
      .post(
        `${V2_BASE_URL}/user/${userId}/burners`,
        {
          phoneNumber: selectedNumber,
          subscriptionId: subscription.id,
          useSip: false,
        },
        {
          headers: {
            Authentication: authToken,
            'Content-Type': 'application/json',
          },
        }
      )
      .then(() => {
        dispatch(numberAssociated(selectedNumber));
      })
      .catch((error) => {
        if (error.data && error.data.error && PHONE_DOES_NOT_EXIST === error.data.error) {
          dispatch(numberIsTaken());
        } else {
          dispatch(hasError(extractError(error), error.status));
        }
      });
  };
}

/**
 * Will try to associate a phone number to a user's account. Happens when the initial number failed to be associated with the burner.
 */
export function reprovisionNumber(selectedNumber, subscription) {
  return (dispatch) => {
    dispatch(setSelectedNumber(selectedNumber));
    return dispatch(associateSelectedNumberWithSub(selectedNumber, subscription));
  };
}

function handleSubscriptionCreated(email, accountPhoneNumber, response) {
  return {
    type: SUBSCRIPTION_CREATED,
    accountPhoneNumber,
    email,
    response,
  };
}

export function createSubscription(formData, stripeResponse) {
  const userId = getCurrentUser().id;
  const authToken = cookie.load(AUTHENTICATION_KEY);

  const body = {
    token: stripeResponse.id,
    email: formData.email,
    firstName: formData.firstName,
    lastName: formData.lastName,
    sku: formData.sku || getStoragePlanData('sku'),
  };

  return (dispatch) => {
    dispatch(requesting());
    return axios
      .post(`${BASE_URL}/user/${userId}/subscriptions/stripe`, body, {
        headers: {
          Authentication: authToken,
          'Content-Type': 'application/json',
        },
      })
      .then((resp) => {
        dispatch(handleSubscriptionCreated(formData.email, formData.phoneNumber, resp.data));
        if (formData.selectedNumber) {
          // if the user has chosen a number, then also make a call to associate the number with the new sub
          dispatch(associateSelectedNumberWithSub(formData.selectedNumber, resp.data));
        } else {
          trackAmplitudeFailedEvent('Oops. Something is wrong with selected number. Try again', 'server');
          dispatch(hasError('Oops. Something is wrong with selected number. Try again'))
        }
      })
      .catch((error) => {
        trackAmplitudeFailedEvent(extractError(error), 'stripe');

        dispatch(setPaymentError('Ops. Something is wrong with your payment method. Try again'))
      });
  };
}



export function hasSubscriptionCheck() {
  const userId = getCurrentUser()?.id;
  const isSubscriptionAllowed = getCurrentUser()?.eligibleForPaywall;

  return (dispatch) => {
    dispatch(requesting());

    return axios
      .get(`${BASE_URL}/user/${userId}/subscriptions`, {
        headers: {
          Authentication: cookie.load(AUTHENTICATION_KEY),
        },
      })
      .then((resp) => {
        if (resp.data.length > 0 || !isSubscriptionAllowed) {
          receiveSubscribtions(resp.data);

          dispatch(setSubscribeExist(true, false))

          dataLayer.push({
            event: CUSTOM_WEB_EVENT,
            customWebEventName: SUBMIT_PAYMENT_INFO,
            customWebEventAction: 'failed',
            customWebEventLabel: 'already subscribed',
          });
          trackAmplitudeFailedEvent('already subscribed')
          dispatch(clearUserInfo());
          sessionStorage.removeItem(VERIFY_SENT);

          dispatch(
            hasError(
              'You already have a subscription. \n Please log in to the app or enter new phone number to start using Burner!'
            )
          );

          cookie.remove(AUTHENTICATION_KEY);
          cookie.remove(CURRENT_USER);
        } else {
          dispatch(setSubscribeExist(false, true));
        }
      })
      .catch((error) => {
        trackAmplitudeFailedEvent(extractError(error));
        dispatch(hasError(extractError(error), error.status))
      });
    }
}

/**
 * Handles when a user clicks on the handle verify submit. Will call backend to get the user and token, then stripe to get auth token. Finally, will call backend with the token
 */
export function validateCodeAndRegister(phone, pin) {
  return (dispatch) => {
    dispatch(requesting());

    return axios
      .post(
        `${BASE_URL}/register`,
        {
          country: getCountry(phone),
          phoneNumber: phone,
          code: pin,
          trackingId: '',
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then((resp) => {
        amplitude.setUserId(resp.data.id);
        verifyNumberSignUpEvents();

        const authToken = resp.headers.authentication;
        cookie.save(AUTHENTICATION_KEY, authToken, defaultSessionOption);
        cookie.save(CURRENT_USER, resp.data, defaultSessionOption);

        // check to see if user has a subscription already
        dispatch(hasSubscriptionCheck());
      })
      .catch((error) => {
        if (extractError(error)?.includes('expired')) {
          trackAmplitudeFailedEvent('expired verification code');
        } else {
          trackAmplitudeFailedEvent('invalid phone verification - signup');
        }

        dispatch(validationCodeError(extractError(error)))
      });
  };
}

/**
 * Handles when a user clicks on the submit. Will call the backend for VC
 * validation, then if that's successful will pop up the verify code modal
 */
export function handleSubmit(phoneNumber) {
  amplitude.track(AMPLITUDE_VERIFY_CODE_SENT, amplitudeDeviceProperty);
  return fetchVerifyCode(phoneNumber, false);
}

/**
 * Handles a resend verify code action
 */
export function resendVerifyCode(number) {
  amplitude.track(AMPLITUDE_VERIFY_CODE_SENT, amplitudeDeviceProperty);
  return fetchVerifyCode(number, true);
}

function receiveNumbers(numbers) {
  return {
    type: RECEIVE_PHONE_NUMBERS,
    numbers,
  };
}

export function requestNumber(areaCode) {
  return (dispatch) => {
    dispatch(requesting());

    // gets the user first, then gets the list of burners for that user
    return axios
      .get(`${V2_BASE_URL}/availableNumbers?areaCode=${areaCode}`, {})
      .then((resp) => {
        dispatch(receiveNumbers(resp.data));

        /*clear information about global errors as we begin the registration process from scratch */
        dispatch(clearErrors());
      })
      .catch(() => {
        dataLayer.push({
          event: CUSTOM_WEB_EVENT,
          customWebEventName: NUMBERS_RECEIVED,
          customWebEventAction: false,
          customWebEventLabel: 'invalid area code',
        });
        trackAmplitudeFailedEvent('invalid area code');

        dispatch(handleError('Whoops! That is not a valid area code.'));
      })
  };
}

export function requestAndSelectNumber(areaCode) {
  return (dispatch) => {
    dispatch(requesting());

    // gets the available numbers then selects one
    return axios
      .get(`${V2_BASE_URL}/availableNumbers?areaCode=${areaCode}`, {})
      .then((resp) => {
        dispatch(receiveNumbers(resp.data));
        dispatch(setSelectedNumber(resp.data[0].phoneNumber));
      })
      .catch((error) => {
        trackAmplitudeFailedEvent(extractError(error));

        dispatch(hasError(extractError(error), error.status, false, '/areaCode'));
      });
  };
}
export function setChooseNumber(phoneNumber) {
  return (dispatch) => {
    addStorageSelectedNumber(phoneNumber)

    dispatch(setSelectedNumber(phoneNumber));
  }
}

// cal this reducer when user navigate between tabs
export function subscribePaymentFailed() {
  return (dispatch) => {
    dispatch(hasError('Oops. Something is wrong with selected number. Try again'))
  }
}
