import React from 'react';
import classNames from 'classnames';

export function AppStoreBadge({width, height, className}) {
  return (
    <div className={classNames(className)}>
      <svg width={width} height={height} viewBox="0 0 101 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M28.8271 0.730892C29.2168 0.702933 29.6077 0.761793 29.9718 0.903226C30.3359 1.04466 30.6641 1.26514 30.9327 1.54876C31.2013 1.83239 31.4036 2.17209 31.525 2.54336C31.6464 2.91463 31.6839 3.30822 31.6348 3.69573C31.6348 5.60198 30.6045 6.69773 28.8271 6.69773H26.6719V0.730892H28.8271ZM27.5986 5.85389H28.7236C29.002 5.87053 29.2806 5.82489 29.5392 5.72027C29.7977 5.61566 30.0297 5.45471 30.2182 5.24914C30.4067 5.04358 30.5469 4.79857 30.6288 4.53195C30.7107 4.26533 30.7321 3.98382 30.6914 3.70789C30.7291 3.43306 30.7056 3.15329 30.6224 2.88862C30.5393 2.62396 30.3987 2.38096 30.2106 2.17703C30.0226 1.9731 29.7917 1.81329 29.5346 1.70905C29.2776 1.60481 29.0006 1.55873 28.7236 1.5741H27.5986V5.85389Z"
          fill="white"/>
        <path
          d="M32.6819 4.44429C32.6536 4.14837 32.6875 3.8498 32.7813 3.56773C32.8751 3.28566 33.0269 3.02632 33.2268 2.80634C33.4268 2.58636 33.6705 2.41059 33.9423 2.29032C34.2142 2.17004 34.5082 2.10791 34.8055 2.10791C35.1027 2.10791 35.3967 2.17004 35.6686 2.29032C35.9404 2.41059 36.1841 2.58636 36.3841 2.80634C36.584 3.02632 36.7358 3.28566 36.8296 3.56773C36.9235 3.8498 36.9573 4.14837 36.929 4.44429C36.9578 4.74051 36.9244 5.03949 36.8308 5.32202C36.7372 5.60454 36.5855 5.86436 36.3855 6.08477C36.1856 6.30519 35.9417 6.48132 35.6695 6.60186C35.3974 6.72239 35.1031 6.78466 34.8055 6.78466C34.5078 6.78466 34.2135 6.72239 33.9414 6.60186C33.6693 6.48132 33.4254 6.30519 33.2254 6.08477C33.0254 5.86436 32.8737 5.60454 32.7801 5.32202C32.6865 5.03949 32.6531 4.74051 32.6819 4.44429ZM36.0149 4.44429C36.0149 3.46822 35.5764 2.89742 34.8069 2.89742C34.0345 2.89742 33.5999 3.46822 33.5999 4.4443C33.5999 5.42819 34.0345 5.99459 34.8069 5.99459C35.5765 5.99458 36.0149 5.42427 36.0149 4.44429H36.0149Z"
          fill="white"/>
        <path
          d="M42.5742 6.69775H41.6523L40.7217 3.38134H40.6514L39.7246 6.69775H38.8115L37.5703 2.19482H38.4717L39.2783 5.63082H39.3447L40.2705 2.19482H41.1231L42.0488 5.63082H42.1191L42.9219 2.19482H43.8105L42.5742 6.69775Z"
          fill="white"/>
        <path
          d="M44.8555 2.19487H45.7109V2.9102H45.7773C45.89 2.65328 46.08 2.43791 46.3209 2.2941C46.5617 2.15029 46.8415 2.08522 47.1211 2.10795C47.3402 2.09148 47.5602 2.12451 47.7648 2.20461C47.9694 2.2847 48.1533 2.40981 48.303 2.57065C48.4527 2.73149 48.5643 2.92396 48.6295 3.13378C48.6947 3.3436 48.7118 3.5654 48.6797 3.78275V6.69775H47.791V4.00591C47.791 3.28228 47.4766 2.92241 46.8193 2.92241C46.6706 2.91548 46.5221 2.9408 46.384 2.99663C46.246 3.05246 46.1216 3.13748 46.0195 3.24587C45.9174 3.35425 45.8399 3.48344 45.7924 3.62457C45.7448 3.7657 45.7284 3.91544 45.7441 4.06352V6.6978H44.8555L44.8555 2.19487Z"
          fill="white"/>
        <path d="M50.0938 0.437012H50.9824V6.69775H50.0938V0.437012Z" fill="white"/>
        <path
          d="M52.2171 4.44437C52.1888 4.14843 52.2226 3.84985 52.3165 3.56777C52.4104 3.28569 52.5621 3.02634 52.7621 2.80635C52.9621 2.58636 53.2058 2.4106 53.4777 2.29032C53.7495 2.17004 54.0435 2.10791 54.3408 2.10791C54.6381 2.10791 54.9321 2.17004 55.204 2.29032C55.4759 2.4106 55.7196 2.58636 55.9196 2.80635C56.1195 3.02634 56.2713 3.28569 56.3652 3.56777C56.459 3.84985 56.4929 4.14843 56.4646 4.44437C56.4934 4.74061 56.4599 5.03959 56.3663 5.32211C56.2727 5.60464 56.121 5.86445 55.921 6.08486C55.721 6.30527 55.4771 6.48139 55.2049 6.60192C54.9328 6.72245 54.6385 6.78472 54.3408 6.78472C54.0432 6.78472 53.7489 6.72245 53.4767 6.60192C53.2046 6.48139 52.9607 6.30527 52.7607 6.08486C52.5607 5.86445 52.409 5.60464 52.3153 5.32211C52.2217 5.03959 52.1882 4.74061 52.2171 4.44437ZM55.5501 4.44437C55.5501 3.4683 55.1116 2.8975 54.3421 2.8975C53.5696 2.8975 53.1351 3.4683 53.1351 4.44438C53.1351 5.42827 53.5696 5.99467 54.3421 5.99467C55.1116 5.99466 55.5501 5.42435 55.5501 4.44437H55.5501Z"
          fill="white"/>
        <path
          d="M57.4023 5.42431C57.4023 4.61376 58.0059 4.14648 59.0771 4.08007L60.2969 4.00976V3.62109C60.2969 3.1455 59.9824 2.87695 59.375 2.87695C58.8789 2.87695 58.5352 3.05908 58.4365 3.37744H57.5762C57.667 2.604 58.3945 2.10791 59.416 2.10791C60.5449 2.10791 61.1816 2.66991 61.1816 3.62109V6.69775H60.3262V6.06494H60.2559C60.1131 6.29193 59.9127 6.47698 59.6751 6.60119C59.4375 6.7254 59.1712 6.78432 58.9033 6.77194C58.7143 6.7916 58.5232 6.77144 58.3425 6.71274C58.1617 6.65404 57.9953 6.55811 57.8538 6.43114C57.7124 6.30417 57.5992 6.14898 57.5214 5.97556C57.4437 5.80215 57.4031 5.61436 57.4023 5.42431ZM60.2969 5.03954V4.66308L59.1973 4.73339C58.5771 4.77489 58.2959 4.98583 58.2959 5.3828C58.2959 5.78807 58.6474 6.02391 59.1309 6.02391C59.2725 6.03825 59.4156 6.02395 59.5516 5.98186C59.6876 5.93977 59.8138 5.87075 59.9226 5.77891C60.0313 5.68706 60.1205 5.57428 60.1849 5.44726C60.2492 5.32024 60.2873 5.18158 60.2969 5.03954Z"
          fill="white"/>
        <path
          d="M62.3477 4.44435C62.3477 3.0215 63.0791 2.12013 64.2168 2.12013C64.4982 2.10716 64.7775 2.17457 65.022 2.31446C65.2666 2.45436 65.4662 2.66096 65.5977 2.91013H65.6641V0.437012H66.5527V6.69775H65.7012V5.98632H65.6309C65.4893 6.23381 65.2826 6.43785 65.0334 6.57633C64.7841 6.71481 64.5017 6.78246 64.2168 6.77196C63.0713 6.77201 62.3477 5.87062 62.3477 4.44435ZM63.2657 4.44435C63.2657 5.39943 63.7159 5.97414 64.4688 5.97414C65.2178 5.97414 65.6807 5.39114 65.6807 4.44826C65.6807 3.50978 65.2129 2.91847 64.4688 2.91847C63.7207 2.91847 63.2656 3.49708 63.2656 4.44435H63.2657Z"
          fill="white"/>
        <path
          d="M70.2327 4.44429C70.2044 4.14837 70.2382 3.8498 70.3321 3.56773C70.4259 3.28566 70.5777 3.02632 70.7776 2.80634C70.9776 2.58636 71.2213 2.41059 71.4931 2.29032C71.765 2.17004 72.059 2.10791 72.3562 2.10791C72.6535 2.10791 72.9475 2.17004 73.2193 2.29032C73.4912 2.41059 73.7349 2.58636 73.9348 2.80634C74.1348 3.02632 74.2865 3.28566 74.3804 3.56773C74.4742 3.8498 74.5081 4.14837 74.4798 4.44429C74.5086 4.74051 74.4752 5.03949 74.3816 5.32202C74.288 5.60454 74.1363 5.86436 73.9363 6.08477C73.7363 6.30519 73.4924 6.48132 73.2203 6.60186C72.9482 6.72239 72.6539 6.78466 72.3562 6.78466C72.0586 6.78466 71.7643 6.72239 71.4922 6.60186C71.22 6.48132 70.9761 6.30519 70.7761 6.08477C70.5762 5.86436 70.4245 5.60454 70.3309 5.32202C70.2373 5.03949 70.2039 4.74051 70.2327 4.44429ZM73.5657 4.44429C73.5657 3.46822 73.1272 2.89742 72.3577 2.89742C71.5852 2.89742 71.1507 3.46822 71.1507 4.4443C71.1507 5.42819 71.5853 5.99459 72.3577 5.99459C73.1272 5.99458 73.5657 5.42427 73.5657 4.44429Z"
          fill="white"/>
        <path
          d="M75.6719 2.19487H76.5273V2.9102H76.5938C76.7064 2.65328 76.8964 2.43791 77.1373 2.2941C77.3782 2.15029 77.6579 2.08522 77.9375 2.10795C78.1566 2.09148 78.3766 2.12451 78.5812 2.20461C78.7858 2.2847 78.9698 2.40981 79.1194 2.57065C79.2691 2.73149 79.3807 2.92396 79.4459 3.13378C79.5111 3.3436 79.5283 3.5654 79.4961 3.78275V6.69775H78.6074V4.00591C78.6074 3.28228 78.293 2.92241 77.6357 2.92241C77.487 2.91548 77.3385 2.9408 77.2004 2.99663C77.0624 3.05246 76.938 3.13748 76.8359 3.24587C76.7338 3.35425 76.6563 3.48344 76.6088 3.62457C76.5612 3.7657 76.5448 3.91544 76.5605 4.06352V6.6978H75.6719V2.19487Z"
          fill="white"/>
        <path
          d="M84.5146 1.07373V2.21533H85.4902V2.96387H84.5146V5.2793C84.5146 5.75098 84.709 5.95752 85.1514 5.95752C85.2646 5.95716 85.3778 5.95031 85.4902 5.93701V6.67724C85.3307 6.70579 85.169 6.72098 85.0068 6.72265C84.0186 6.72265 83.625 6.37499 83.625 5.50683V2.96383H82.9102V2.21529H83.625V1.07373H84.5146Z"
          fill="white"/>
        <path
          d="M86.707 0.437012H87.5879V2.91846H87.6582C87.7763 2.65914 87.9716 2.44255 88.2172 2.2982C88.4629 2.15386 88.7472 2.08877 89.0312 2.11182C89.2492 2.09996 89.467 2.13635 89.6693 2.21839C89.8716 2.30043 90.0533 2.42611 90.2014 2.58644C90.3495 2.74678 90.4604 2.93783 90.5262 3.14597C90.592 3.3541 90.611 3.57419 90.582 3.79053V6.69776H89.6924V4.00976C89.6924 3.29052 89.3574 2.92626 88.7295 2.92626C88.5768 2.91374 88.4232 2.93473 88.2794 2.98779C88.1356 3.04084 88.0052 3.12467 87.8972 3.23341C87.7892 3.34214 87.7062 3.47316 87.6542 3.6173C87.6021 3.76144 87.5822 3.91521 87.5957 4.06786V6.69774H86.7071L86.707 0.437012Z"
          fill="white"/>
        <path
          d="M95.7614 5.48201C95.6407 5.89355 95.3791 6.24953 95.0224 6.48768C94.6657 6.72583 94.2366 6.83098 93.8102 6.78474C93.5136 6.79257 93.2187 6.73575 92.9462 6.61822C92.6737 6.50069 92.4301 6.32527 92.2322 6.10413C92.0343 5.88299 91.8868 5.62144 91.8001 5.33761C91.7135 5.05379 91.6896 4.75451 91.7301 4.46053C91.6906 4.16564 91.7149 3.86571 91.8014 3.58104C91.8879 3.29636 92.0346 3.0336 92.2314 2.81052C92.4283 2.58745 92.6708 2.40928 92.9425 2.28807C93.2142 2.16686 93.5088 2.10545 93.8063 2.10799C95.0592 2.10799 95.8151 2.96399 95.8151 4.37799V4.68808H92.6354V4.73788C92.6215 4.90312 92.6423 5.06945 92.6964 5.2262C92.7505 5.38295 92.8368 5.52666 92.9497 5.64812C93.0626 5.76957 93.1997 5.86609 93.352 5.93149C93.5044 5.99689 93.6688 6.02972 93.8346 6.02788C94.0472 6.05339 94.2626 6.01512 94.4533 5.91791C94.6441 5.8207 94.8016 5.66896 94.9059 5.48198L95.7614 5.48201ZM92.6354 4.03084H94.9098C94.921 3.87971 94.9005 3.72791 94.8495 3.58519C94.7985 3.44247 94.7183 3.31199 94.6139 3.20213C94.5095 3.09228 94.3833 3.00547 94.2434 2.9473C94.1035 2.88912 93.9529 2.86086 93.8014 2.86434C93.6477 2.8624 93.4952 2.89126 93.3528 2.94921C93.2104 3.00715 93.0811 3.09301 92.9724 3.20172C92.8638 3.31044 92.778 3.4398 92.7201 3.5822C92.6622 3.7246 92.6334 3.87714 92.6354 4.03084H92.6354Z"
          fill="white"/>
        <path
          d="M15.77 12.3008C15.7807 11.4662 16.0024 10.6478 16.4145 9.92182C16.8265 9.19587 17.4154 8.58593 18.1265 8.14876C17.6748 7.5036 17.0788 6.97266 16.386 6.5981C15.6932 6.22355 14.9226 6.01571 14.1354 5.99108C12.4561 5.81482 10.8282 6.99591 9.97247 6.99591C9.1002 6.99591 7.7827 6.00858 6.36397 6.03777C5.44629 6.06742 4.55195 6.33427 3.76807 6.81233C2.98419 7.29039 2.33752 7.96335 1.89105 8.76564C-0.0429511 12.1141 1.39964 17.0351 3.25225 19.7417C4.17915 21.0671 5.26243 22.5475 6.67988 22.495C8.06694 22.4375 8.58498 21.6105 10.2593 21.6105C11.918 21.6105 12.4041 22.495 13.8503 22.4616C15.3387 22.4375 16.2764 21.1304 17.1708 19.7925C17.8368 18.8481 18.3492 17.8044 18.6892 16.7C17.8245 16.3343 17.0866 15.7221 16.5675 14.9398C16.0483 14.1575 15.771 13.2397 15.77 12.3008Z"
          fill="white"/>
        <path
          d="M13.0395 4.21083C13.8511 3.23663 14.2509 1.98445 14.154 0.720215C12.9142 0.850435 11.769 1.44299 10.9465 2.37982C10.5443 2.83749 10.2363 3.36993 10.0401 3.9467C9.84384 4.52347 9.76319 5.13326 9.80276 5.74121C10.4229 5.7476 11.0364 5.61319 11.5971 5.3481C12.1577 5.08301 12.6509 4.69416 13.0395 4.21083Z"
          fill="white"/>
        <path
          d="M33.3047 19.1397H28.5713L27.4346 22.4961H25.4297L29.9131 10.0781H31.9961L36.4795 22.4961H34.4404L33.3047 19.1397ZM29.0615 17.5908H32.8135L30.9639 12.1436H30.9121L29.0615 17.5908Z"
          fill="white"/>
        <path
          d="M46.1612 17.9699C46.1612 20.7834 44.6553 22.591 42.3828 22.591C41.8072 22.6211 41.2347 22.4885 40.7309 22.2084C40.2271 21.9283 39.8124 21.5119 39.5342 21.007H39.4912V25.4914H37.6328V13.4426H39.4317V14.9485H39.4659C39.7568 14.446 40.1785 14.0318 40.6862 13.7499C41.1938 13.4681 41.7683 13.3291 42.3487 13.3479C44.6465 13.3479 46.1612 15.1643 46.1612 17.9699ZM44.251 17.9699C44.251 16.1369 43.3037 14.9319 41.8584 14.9319C40.4385 14.9319 39.4834 16.1623 39.4834 17.9699C39.4834 19.7942 40.4385 21.0158 41.8584 21.0158C43.3037 21.0158 44.251 19.8196 44.251 17.9699Z"
          fill="white"/>
        <path
          d="M56.126 17.9699C56.126 20.7834 54.6201 22.591 52.3477 22.591C51.772 22.6211 51.1995 22.4885 50.6957 22.2084C50.1919 21.9283 49.7772 21.5119 49.4991 21.007H49.4561V25.4914H47.5977V13.4426H49.3965V14.9484H49.4307C49.7216 14.446 50.1433 14.0318 50.651 13.7499C51.1586 13.4681 51.7332 13.3291 52.3135 13.3479C54.6114 13.3479 56.126 15.1643 56.126 17.9699ZM54.2158 17.9699C54.2158 16.1369 53.2686 14.9318 51.8233 14.9318C50.4033 14.9318 49.4483 16.1623 49.4483 17.9699C49.4483 19.7942 50.4033 21.0158 51.8233 21.0158C53.2686 21.0158 54.2159 19.8195 54.2159 17.9699H54.2158Z"
          fill="white"/>
        <path
          d="M62.711 19.0362C62.8487 20.2676 64.045 21.0762 65.6797 21.0762C67.2461 21.0762 68.3731 20.2676 68.3731 19.1572C68.3731 18.1934 67.6934 17.6162 66.084 17.2207L64.4746 16.833C62.1944 16.2822 61.1358 15.2158 61.1358 13.4854C61.1358 11.3428 63.003 9.87109 65.6543 9.87109C68.2783 9.87109 70.0772 11.3428 70.1377 13.4854H68.2617C68.1494 12.2461 67.125 11.4981 65.6279 11.4981C64.1309 11.4981 63.1065 12.2549 63.1065 13.3565C63.1065 14.2344 63.7608 14.751 65.3613 15.1465L66.7295 15.4824C69.2773 16.0849 70.3359 17.1084 70.3359 18.9248C70.3359 21.248 68.4854 22.7031 65.542 22.7031C62.7881 22.7031 60.9287 21.2822 60.8086 19.0361L62.711 19.0362Z"
          fill="white"/>
        <path
          d="M74.3476 11.2998V13.4424H76.0693V14.9141H74.3476V19.9053C74.3476 20.6807 74.6924 21.042 75.4492 21.042C75.6536 21.0384 75.8577 21.0241 76.0605 20.999V22.4619C75.7203 22.5255 75.3744 22.5543 75.0283 22.5478C73.1953 22.5478 72.4805 21.8593 72.4805 20.1035V14.9141H71.1641V13.4424H72.4804V11.2998H74.3476Z"
          fill="white"/>
        <path
          d="M77.0664 17.9697C77.0664 15.1211 78.7441 13.3311 81.3604 13.3311C83.9854 13.3311 85.6553 15.1211 85.6553 17.9697C85.6553 20.8262 83.9941 22.6084 81.3604 22.6084C78.7275 22.6084 77.0664 20.8262 77.0664 17.9697ZM83.7617 17.9697C83.7617 16.0156 82.8662 14.8623 81.3603 14.8623C79.8545 14.8623 78.96 16.0244 78.96 17.9697C78.96 19.9316 79.8545 21.0762 81.3603 21.0762C82.8662 21.0762 83.7617 19.9316 83.7617 17.9697H83.7617Z"
          fill="white"/>
        <path
          d="M87.1875 13.4425H88.96V14.9835H89.003C89.1229 14.5022 89.4048 14.0769 89.8014 13.779C90.198 13.4811 90.6851 13.3288 91.1807 13.3478C91.3949 13.347 91.6084 13.3703 91.8174 13.4171V15.1554C91.5471 15.0728 91.265 15.0348 90.9824 15.0431C90.7124 15.0321 90.4433 15.0797 90.1935 15.1826C89.9436 15.2855 89.719 15.4412 89.535 15.6391C89.351 15.837 89.2121 16.0724 89.1276 16.3291C89.0432 16.5857 89.0153 16.8576 89.0459 17.1261V22.4962H87.1875L87.1875 13.4425Z"
          fill="white"/>
        <path
          d="M100.386 19.8369C100.136 21.4805 98.5352 22.6084 96.4873 22.6084C93.8535 22.6084 92.2188 20.8437 92.2188 18.0127C92.2188 15.1729 93.8623 13.3311 96.4092 13.3311C98.9141 13.3311 100.489 15.0518 100.489 17.7969V18.4336H94.0947V18.5459C94.0652 18.8791 94.1069 19.2148 94.2171 19.5306C94.3273 19.8464 94.5034 20.1352 94.7338 20.3778C94.9641 20.6203 95.2434 20.8111 95.5532 20.9374C95.8629 21.0637 96.196 21.1226 96.5303 21.1103C96.9694 21.1515 97.4102 21.0498 97.7869 20.8203C98.1636 20.5909 98.4562 20.246 98.6211 19.8369L100.386 19.8369ZM94.1035 17.1348H98.6299C98.6466 16.8352 98.6011 16.5354 98.4965 16.2541C98.3918 15.9729 98.2301 15.7164 98.0216 15.5006C97.8131 15.2849 97.5622 15.1145 97.2847 15.0003C97.0073 14.8861 96.7092 14.8305 96.4092 14.8369C96.1065 14.8351 95.8066 14.8933 95.5265 15.008C95.2465 15.1227 94.9919 15.2918 94.7776 15.5054C94.5632 15.7191 94.3933 15.973 94.2776 16.2527C94.1619 16.5323 94.1027 16.8321 94.1035 17.1348V17.1348Z"
          fill="white"/>
      </svg>
    </div>
  )
}
