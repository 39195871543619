import React from 'react';

export default function GooglePlayIcon() {
  return (
    <svg
      className="app__icon"
      width="75"
      height="82"
      viewBox="0 0 75 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8156_64403)">
        <path
          d="M34.7068 38.8691L0.568359 74.636C0.951731 75.9946 1.64066 77.2476 2.58249 78.2991C3.52432 79.3507 4.6941 80.173 6.00243 80.7032C7.31076 81.2333 8.72298 81.4574 10.1312 81.3581C11.5393 81.2588 12.9062 80.8389 14.1272 80.1305L52.5394 58.2535L34.7068 38.8691Z"
          fill="#EA4335"/>
        <path
          d="M69.2262 32.7898L52.6154 23.2754L33.918 39.683L52.6915 58.2024L69.1757 48.79C70.6361 48.0254 71.8592 46.8756 72.7127 45.4653C73.5661 44.0551 74.0173 42.4381 74.0173 40.7898C74.0173 39.1414 73.5661 37.5244 72.7127 36.1142C71.8592 34.7039 70.6361 33.5542 69.1757 32.7895L69.2262 32.7898Z"
          fill="#FBBC04"/>
        <path
          d="M0.5672 6.76562C0.360972 7.52851 0.258254 8.31565 0.261808 9.10591V72.2952C0.263844 73.0852 0.366467 73.8717 0.5672 74.6358L35.876 39.7848L0.5672 6.76562Z"
          fill="#4285F4"/>
        <path
          d="M34.9612 40.7018L52.6158 23.2763L14.2545 1.29736C12.8112 0.452114 11.1696 0.00447303 9.49704 1.76696e-05C5.3463 -0.00804928 1.69716 2.74682 0.568359 6.7414L34.9612 40.7018Z"
          fill="#34A853"/>
      </g>
      <defs>
        <clipPath id="clip0_8156_64403">
          <rect width="73.755" height="81.5339" fill="white" transform="translate(0.261719)"/>
        </clipPath>
      </defs>
    </svg>
  )
}
