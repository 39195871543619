import React from 'react';

export default function BranchIcon({className}) {
  return (
    <svg width="44" height="103" viewBox="0 0 44 103" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        d="M1.41016 98.4251C1.41016 98.4251 6.31714 105.475 16.3965 101.795C16.3965 101.795 11.2742 92.5918 1.41016 98.4251Z"
        fill="#111111"/>
      <path
        d="M1.34993 97.6497C1.34993 97.6497 -2.62571 90.0339 5.30557 82.8086C5.30557 82.8086 11.0888 91.6111 1.34993 97.6497Z"
        fill="#111111"/>
      <path
        d="M11.5762 92.8632C11.5762 92.8632 17.6348 98.9518 26.918 93.5692C26.918 93.5692 20.2736 85.3975 11.5762 92.8632Z"
        fill="#111111"/>
      <path
        d="M11.3815 92.1065C11.3815 92.1065 6.13904 85.3018 12.6934 76.8047C12.6934 76.8047 19.9237 84.4656 11.3815 92.1065Z"
        fill="#111111"/>
      <path
        d="M19.7695 83.9219C19.7695 83.9219 26.6644 89.0442 35.0463 82.3446C35.0463 82.3446 27.2652 75.2495 19.7695 83.9219Z"
        fill="#111111"/>
      <path
        d="M19.4633 83.1999C19.4633 83.1999 13.2695 77.2464 18.4919 67.873C18.4919 67.873 26.7787 74.3773 19.4633 83.1999Z"
        fill="#111111"/>
      <path
        d="M26.3477 74.3439C26.3477 74.3439 34.3841 77.3782 40.608 68.6357C40.608 68.6357 31.1795 63.9491 26.3477 74.3439Z"
        fill="#111111"/>
      <path
        d="M25.8636 73.7373C25.8636 73.7373 18.2778 69.7116 20.7212 59.2617C20.7212 59.2617 30.4701 63.2424 25.8636 73.7373Z"
        fill="#111111"/>
      <path
        d="M31.0664 64.2798C31.0664 64.2798 39.6236 65.0059 43.2287 54.9015C43.2287 54.9015 32.879 52.9637 31.0664 64.2798Z"
        fill="#111111"/>
      <path
        d="M30.4287 63.8314C30.4287 63.8314 22.0317 62.0238 21.541 51.3086C21.541 51.3086 32.0059 52.4803 30.4287 63.8314Z"
        fill="#111111"/>
      <path
        d="M33.3978 52.6693C33.3978 52.6693 41.9199 51.6028 43.352 40.9727C43.352 40.9727 32.822 41.223 33.3978 52.6693Z"
        fill="#111111"/>
      <path
        d="M32.6828 52.3583C32.6828 52.3583 24.0956 52.3333 21.3867 41.9535C21.3867 41.9535 31.8666 40.927 32.6828 52.3583Z"
        fill="#111111"/>
      <path
        d="M32.8593 42.0242C32.8593 42.0242 41.1712 39.8611 41.2062 29.1309C41.2062 29.1309 30.8014 30.7482 32.8593 42.0242Z"
        fill="#111111"/>
      <path
        d="M32.1134 41.8131C32.1134 41.8131 23.5913 42.9047 19.5605 32.9605C19.5605 32.9605 29.8202 30.5822 32.1134 41.8131Z"
        fill="#111111"/>
      <path
        d="M29.9767 30.6905C29.9767 30.6905 37.8229 27.1955 36.0954 16.6055C36.0954 16.6055 26.0962 19.9102 29.9767 30.6905Z"
        fill="#111111"/>
      <path
        d="M29.1999 30.6012C29.1999 30.6012 20.9732 33.0747 15.3652 23.9317C15.3652 23.9317 25.0941 19.9009 29.1999 30.6012Z"
        fill="#111111"/>
      <path
        d="M25.8588 19.7496C25.8588 19.7496 32.4882 14.2868 28.0068 4.54297C28.0068 4.54297 19.2494 10.3913 25.8588 19.7496Z"
        fill="#111111"/>
      <path
        d="M21.451 13.751C21.451 13.751 22.8931 5.28397 13.1242 0.847656C13.1242 0.847656 10.3252 11.0021 21.451 13.751Z"
        fill="#111111"/>
      <path
        d="M25.0939 19.8749C25.0939 19.8749 17.8236 24.4514 9.98242 17.131C9.98242 17.131 18.2892 10.6568 25.0939 19.8749Z"
        fill="#111111"/>
    </svg>
  )
}
