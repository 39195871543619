import React, { Component } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import PropTypes from 'prop-types';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { ENVIRONMENT, ROUTERS, SELECTED_PLAN, STRIPE_PUBLISHABLE_KEY } from '../constants';
import { getStoragePlanData, removeStorageData, setUserSignUpStep} from '../utils/sessionStorage';
import { BackwardArrow } from '../components/icons/backwardArrow';
import { BurnerLogo } from '../components/icons/burnerLogo';
import { getParamsByName } from '../utils/query';
import { getCurrentUser } from '../utils/user';

import * as stripeSubscribeActions from '../ducks/stripe-subscribe';

import '!style-loader!css-loader!sass-loader!../../styles/stripe-checkout.scss';
import withRegistrationCheck from '../components/WithRegistrationCheck';
import Container from '../components/common/Container';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

class CheckoutPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sku: this.props?.selectedSku || getStoragePlanData('sku'),
    }

    this.handleBack = this.handleBack.bind(this);
  }

  componentDidMount() {
    const { sku } = this.state;
    const { router } = this.context;
    const sessionId = getParamsByName('session_id');
    const user = getCurrentUser();

    if (!sessionId && user) {
      setUserSignUpStep(ROUTERS.checkout);
    }

    if (!user || !sku) {
      router.push(ROUTERS.homePage);
    }
  }

  componentWillUnmount() {
    const stripeElement = document.getElementById('stripe-element-checkout');

    if (stripeElement) {
      stripeElement.innerHTML = '';
    }
  }

  handleBack() {
    const { router } = this.context;

    router.push(ROUTERS.paywall);
    removeStorageData(SELECTED_PLAN); // remove the plan and close access to checkout page for user
  }

  handleFetchClientSecret = () => {
    const { getStripeSession } = this.props;
    const { sku } = this.state;

    if (sku) {
      return getStripeSession(sku);
    }
  }

  render() {
   const { sku } = this.state;

    return (
      <Container classNameWrapper="StripeEmbeddedCheckout__wrapper" isHideHeader>
        <div id="checkout" className="StripeEmbeddedCheckout u-height-full">
          <div className={
            classNames(
              'BackButton__stripe Checkout__header',
              ENVIRONMENT === 'QA' ? 'button-margin__small' : 'button-margin__default'
            )
          }>
            <button
              className="BackButton"
              type="button"
              onClick={this.handleBack}
            >
              <BackwardArrow width="24" height="24" />
              <BurnerLogo width="80" height="20" />
            </button>
          </div>
          {sku && <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{
              fetchClientSecret: this.handleFetchClientSecret,
            }}
          >
            <EmbeddedCheckout className="StripeEmbeddedCheckout__form" id="stripe-element-checkout"/>
          </EmbeddedCheckoutProvider>}
        </div>
      </Container>
    );
  }
}

CheckoutPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    selectedSku: state.subscribePage.selectedSku,
    paymentError: state.subscribePage.paymentError,
  };
}

export default connect(mapStateToProps, {...stripeSubscribeActions})(withRegistrationCheck(CheckoutPage));
