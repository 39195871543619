import React from 'react';
import { QuotesIcon } from '../icons/quotes';

import '!style-loader!css-loader!sass-loader!../../../styles/query-box.scss';

function QuoteBox({text, author }) {
  return (
    <div className="grid-x align-center-middle">
      <div className="QuoteBox__container small-12">
        <div className="QuoteBox__icon">
          <QuotesIcon />
        </div>
        <p className="QuoteBox__text">{text}</p>
        <cite className="QuoteBox__author">{author}</cite>
      </div>
    </div>
  )
}

export default QuoteBox;
