import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as subscribeActions from '../ducks/subscribe';
import * as errorActions from '../ducks/error';
import * as verifyActions from '../ducks/verify';
import * as progressBarActions from '../ducks/progressBar';

import { ROUTERS, SELECTED_NUMBER, US, VERIFY_SENT } from '../constants';
import { removeStorageData } from '../utils/sessionStorage';
import PhoneNumberContainer from '../containers/PhoneNumberAuthContainer';

import '!style-loader!css-loader!sass-loader!../../styles/phone-number.scss';

class PhoneNumberSignupPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: null,
      selectedCountry: {
        name: 'United States',
        dialCode: 1,
        countryCode: US,
      },
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleVerifySubmit = this.handleVerifySubmit.bind(this);
  }

  componentDidMount() {
    const { selectedNumber, setCurrentStep } = this.props;
    const { router } = this.context;

    setCurrentStep(3);

    const storageNumber = localStorage.getItem(SELECTED_NUMBER);

    if (!selectedNumber && !storageNumber) {
      router.push(ROUTERS.homePage)
    }
  }

  componentDidUpdate() {
    const { isAccessToNextStep } = this.props;
    const { router } = this.context;

    if (isAccessToNextStep) {
      router.push(ROUTERS.socialProof);
    }
  }

  componentWillUnmount() {
    const { redirectToNextSubscribePage, clearErrors } = this.props;

    removeStorageData(VERIFY_SENT);
    clearErrors();
    redirectToNextSubscribePage(false);
  }

  handleVerifySubmit(number, verifyCode) {
    const { validateCodeAndRegister } = this.props;

    validateCodeAndRegister(number, verifyCode);
  }

  handleSubmit(number) {
    const { fetchVerifyCode } = this.props;

    fetchVerifyCode(number);
  }

  render() {
    return (
      <div id="signup-page">
        <PhoneNumberContainer
          handleSubmitNumber={this.handleSubmit}
          handleSubmitVerify={this.handleVerifySubmit}
        />
      </div>
    );
  }
}

PhoneNumberSignupPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    error: state.error,
    codeWasSent: state.verifyPage.codeWasSent,
    verifyErrorMessage: state.verifyPage.verifyErrorMessage,
    isResend: state.verifyPage.isResend,
    selectedNumber: state.subscribePage.selectedNumber,
    isAccessToNextStep: state.subscribePage.isAccessToNextStep,
  };
}

const mapDispatchToProps = {
  ...verifyActions,
  ...subscribeActions,
  ...errorActions,
  ...progressBarActions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PhoneNumberSignupPage);
