import React from 'react';

export default function TrashIcon() {
  return (
    <svg width="109" height="96" viewBox="0 0 109 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M42.7259 8.88889V0H19.9111V8.88889H0V14.8148H62.4593V8.88889H42.7259ZM25.837 5.92593H36.8V8.88889H25.837V5.92593Z"
        fill="#111111"/>
      <path
        d="M4.38477 16.2969V66.9635H58.2514V16.2969H4.38477ZM23.644 54.1043H17.7181V29.1561H23.644V54.1043ZM34.3107 54.1043H28.3848V29.1561H34.3107V54.1043ZM44.9774 54.1043H39.0514V29.1561H44.9774V54.1043Z"
        fill="#111111"/>
      <path
        d="M70.6387 56.5918H64.7128V84.6214L56.0017 75.9696L51.8535 80.177L67.6757 95.9992L83.498 80.177L79.2905 75.9696L70.6387 84.6214V56.5918Z"
        fill="#111111"/>
      <path
        d="M77.334 65.1269L81.4821 69.3343L90.1933 60.6825V88.7714H96.1192V60.6825L104.771 69.3343L108.978 65.1269L93.1562 49.3047L77.334 65.1269Z"
        fill="#111111"/>
    </svg>
  )
}

