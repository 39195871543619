import React from 'react';

export default function ScoreIcon() {
  return (
    <svg width="213" height="35" viewBox="0 0 213 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.844 0.214844L22.0199 13.3948H35.5406L24.6043 21.5422L28.7803 34.7271L17.844 26.5796L6.9029 34.7271L11.0788 21.5422L0.142578 13.3948H13.6632L17.844 0.214844Z"
        fill="#E2E269"/>
      <path
        d="M62.1995 0.214844L66.3754 13.3948H79.8961L68.9598 21.5422L73.1357 34.7271L62.1995 26.5796L51.2584 34.7271L55.4343 21.5422L44.498 13.3948H58.0187L62.1995 0.214844Z"
        fill="#E2E269"/>
      <path
        d="M106.553 0.214844L110.734 13.3948H124.254L113.313 21.5422L117.494 34.7271L106.553 26.5796L95.6168 34.7271L99.7927 21.5422L88.8516 13.3948H102.377L106.553 0.214844Z"
        fill="#E2E269"/>
      <path
        d="M150.914 0.214844L155.09 13.3948H168.616L157.675 21.5422L161.855 34.7271L150.914 26.5796L139.973 34.7271L144.154 21.5422L133.213 13.3948H146.734L150.914 0.214844Z"
        fill="#E2E269"/>
      <path
        d="M195.274 0.214844L199.45 13.3948H212.97L202.034 21.5422L206.21 34.7271L195.274 26.5796L184.333 34.7271L188.509 21.5422L177.572 13.3948H191.093L195.274 0.214844Z"
        fill="#E2E269"/>
    </svg>
  )
}
