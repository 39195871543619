import React from 'react';

export default function InfinityIcon() {
  return (
    <svg width="111" height="56" viewBox="0 0 111 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M89.1293 0H74.4052L54.9741 19.3707L35.5431 0H21.0603L0 21.0603V36.5086L19.4914 56H37.1121L54.9741 38.1379L72.8362 56H90.6983L110.19 36.5086V21.0603L89.1293 0ZM43.931 31.1983L30.8362 44.2931H25.7672L12.6724 31.1983V26.3103L25.0431 13.9397H31.5603L43.931 26.3103V31.1983ZM97.5173 31.1983L84.4224 44.2931H79.1121L66.0173 31.1983V26.3103L78.3879 13.9397H85.1466L97.5173 26.3103V31.1983Z"
        fill="#111111"/>
    </svg>
  )
}
