import React from 'react';

export function GoogleIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.51017 8.51C5.82209 7.5663 6.42408 6.74521 7.23025 6.16387C8.03642 5.58252 9.00559 5.27062 9.99951 5.27267C11.1262 5.27267 12.1448 5.67267 12.9448 6.32733L15.2728 4C13.8542 2.76333 12.0362 2 9.99951 2C6.84617 2 4.13151 3.79867 2.82617 6.43333L5.51017 8.51Z"
        fill="#EA4335"/>
      <path
        d="M12.6929 14.0084C11.9662 14.4771 11.0436 14.7271 9.99955 14.7271C9.0096 14.7291 8.04409 14.4197 7.23971 13.8426C6.43533 13.2656 5.83285 12.4501 5.51755 11.5117L2.82422 13.5564C3.48505 14.8939 4.50773 16.0193 5.7761 16.8048C7.04447 17.5902 8.50769 18.0042 9.99955 17.9997C11.9549 17.9997 13.8229 17.3044 15.2222 15.9997L12.6936 14.0084H12.6929Z"
        fill="#34A853"/>
      <path
        d="M15.2227 16.0006C16.686 14.6352 17.636 12.6032 17.636 10.0006C17.636 9.52723 17.5633 9.01857 17.4547 8.5459H10V11.6372H14.2907C14.0793 12.6766 13.5107 13.4812 12.694 14.0092L15.2227 16.0006Z"
        fill="#4A90E2"/>
      <path
        d="M5.51808 11.5123C5.35498 11.0249 5.27212 10.5142 5.27274 10.0003C5.27274 9.47893 5.35608 8.97826 5.51074 8.51026L2.82674 6.43359C2.27766 7.54205 1.99461 8.76327 2.00008 10.0003C2.00008 11.2803 2.29674 12.4869 2.82474 13.5569L5.51808 11.5123Z"
        fill="#FBBC05"/>
    </svg>
  )
}
