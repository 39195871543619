import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as actionCreators from '../ducks/subscribe';

import PickNumberPage from '../pages/PickNumberPage';
import Container from '../components/common/Container';
import {CUSTOM_WEB_EVENT, NUMBERS_RECEIVED, ROUTERS} from '../constants';

const propTypes = {
  isNumberTaken: PropTypes.bool,
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  reprovisionNumber: PropTypes.func.isRequired,
  setSelectedNumber: PropTypes.func.isRequired,
  subscription: PropTypes.shape({
    totalConcurrentBurners: PropTypes.number,
    totalBurnersAllowedInPeriod: PropTypes.number,
    sku: PropTypes.string,
  }),
};

const defaultProps = {
  isNumberTaken: false,
  subscription: null,
};

class PickNumberPageContainer extends Component {
  constructor(props) {
    super(props);
    const { location, params } = this.props;

    this.areaCode = params.areaCode || location.query.areacode || '';
  }

  componentDidMount() {
    const { requestNumber, currentStep, setCurrentStep, numbers } = this.props;

    if (currentStep === 1 || currentStep > 2) {
      setCurrentStep(2);
    }

    if (this.areaCode && !numbers) {
      requestNumber(this.areaCode);
    }

    if (numbers?.length) {
      dataLayer.push({
        event: CUSTOM_WEB_EVENT,
        customWebEventName: NUMBERS_RECEIVED,
        customWebEventAction: true,
      });
    } else {
      dataLayer.push({
        event: CUSTOM_WEB_EVENT,
        customWebEventName: NUMBERS_RECEIVED,
        customWebEventAction: false,
      });
    }
  }

  componentDidUpdate() {
    const { error, numbers } = this.props;
    const { router } = this.context;

    if (error && !numbers) {
      router?.push(ROUTERS.homePage)
    }
  }

  render() {
    const { isNumberTaken, reprovisionNumber, setChooseNumber, subscription } = this.props;

    return (
      <Container isShowBar classNameWrapper="u-height-full">
        <PickNumberPage
          areaCode={this.areaCode}
          isNumberTaken={isNumberTaken}
          reprovisionNumber={reprovisionNumber}
          setSelectedNumber={setChooseNumber}
          subscription={subscription}
        />
      </Container>
    );
  }
}

PickNumberPageContainer.propTypes = propTypes;
PickNumberPageContainer.defaultProps = defaultProps;
PickNumberPageContainer.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isNumberTaken: state.subscribePage.isNumberTaken,
  subscription: state.subscribePage.subscription,
  numbers: state.subscribePage.numbers,
  isLoading: state.loading.isLoading,
  error: state.chooseNumberPage.error,
});

const mapDispatchToProps = actionCreators;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PickNumberPageContainer);
