import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {connect} from 'react-redux';
import {Component} from 'react';

import '!style-loader!css-loader!sass-loader!../../../styles/progress-bar.scss';

class HorizontalLinearStepper extends Component {
  render() {
   const { currentStep, totalSteps } = this.props;
   const steps = Array(totalSteps || 4).fill(0);

    return (
      <Box sx={{ width: '100%' }} className="Progress-bar__container u-width-full">
        <Stepper activeStep={currentStep}>
          {steps.map((_, index) => (
            <Step key={index}>
              <StepLabel />
            </Step>
          ))}
        </Stepper>
      </Box>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentStep: state.progressBar.currentStep,
    totalSteps: state.progressBar.totalSteps,
  };
}
export default connect(mapStateToProps)(HorizontalLinearStepper);

