import React from 'react';

export function AppleIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
        d="M12.1998 4.55467C12.7598 3.87867 13.1358 2.93667 13.0331 2C12.2278 2.03267 11.2531 2.53667 10.6765 3.21267C10.1585 3.81133 9.70514 4.76933 9.82781 5.68733C10.7251 5.75733 11.6425 5.23133 12.2011 4.55467H12.1998ZM14.2145 10.5C14.2371 12.9213 16.3385 13.7273 16.3611 13.738C16.3445 13.7947 16.0265 14.886 15.2545 16.0133C14.5878 16.988 13.8958 17.9593 12.8051 17.9793C11.7338 17.9987 11.3898 17.344 10.1645 17.344C8.94048 17.344 8.55781 17.9593 7.54314 17.9987C6.49114 18.0387 5.68981 16.9447 5.01648 15.974C3.64314 13.9873 2.59314 10.3607 4.00314 7.91267C4.70248 6.69667 5.95448 5.92667 7.31314 5.90733C8.34648 5.88733 9.32181 6.60267 9.95315 6.60267C10.5851 6.60267 11.7705 5.74267 13.0165 5.86867C13.5385 5.89067 15.0025 6.07933 15.9431 7.456C15.8671 7.50267 14.1958 8.476 14.2138 10.5"
        fill="#111111"
      />
    </svg>
  )
}
