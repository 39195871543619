import {AUTHENTICATION_KEY, CHANGE_TAB, CURRENT_USER, DEVICE_TYPE, ROUTERS, SELECTED_NUMBER} from '../constants.js';
import cookie from 'react-cookie';
import {clearStorage} from './sessionStorage';

export function getCurrentUser() {
  const item = cookie.load(CURRENT_USER);
  if (!item) {
    return null;
  }
  return item;
}

export function getAccessToPage() {
  const storageNumber = localStorage.getItem(SELECTED_NUMBER);
  const storageUser = getCurrentUser();

  return !storageUser || !storageNumber
}

export function userDeviceType(deviceType) {
  const isIos = /iPhone|iPod/i.test(deviceType);
  const isAndroid = /Android/i.test(deviceType);

  if (isIos) {
   return DEVICE_TYPE.ios;
  }

  if (isAndroid) {
    return DEVICE_TYPE.android;
  }

  return DEVICE_TYPE.default;
}

export function handleAuthCheck(router, authFunc) {
  const storageUser = getCurrentUser();
  const tabInfo = sessionStorage.getItem(CHANGE_TAB);

  if (document.visibilityState === 'visible' && !storageUser && tabInfo) {
    clearStorage();
    cookie.remove(AUTHENTICATION_KEY);
    cookie.remove(CURRENT_USER);

    return authFunc(router.push(ROUTERS.login));
  }

  return sessionStorage.setItem(CHANGE_TAB, 'true');
}
