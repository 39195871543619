import React, { Component } from 'react';
import { CUSTOM_WEB_EVENT, START_FLOW_SQUEEZE_WEB } from '../constants';
import ChooseAreaCode from './ChooseAreaCode';

class ChoosePhoneNumberPage extends Component {
  componentDidMount() {
    dataLayer.push({
      event: CUSTOM_WEB_EVENT,
      customWebEventName: START_FLOW_SQUEEZE_WEB,
    });
  }

  render() {
    return (
      <ChooseAreaCode />
    );
  }
}

export default React.memo(ChoosePhoneNumberPage);
