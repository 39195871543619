import React, {Component} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import PhoneReview from '../icons/paywall/PhoneReview';
import TextSlider from './TextSlider';
import { ReviewsData } from '../../constants';
import ProgressiveBackgroundImage from './ProgressiveBackgroundImage';

import '!style-loader!css-loader!sass-loader!../../../styles/reviews.scss';

class Reviews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: '1',
      isFreeTrial: false,
      isYearly: false,
    };
  }

  render() {
    const backgroundHigh = require('../../../public/images/bg/pixel-stars-high.webp');
    const backgroundLow = require('../../../public/images/bg/pixel-stars-low.webp');

    return (
      <section
        className="Reviews__container Main__container Main__container--minHeight grid-y grid-padding-x"
      >
        <ProgressiveBackgroundImage
          alt="Reviews background"
          lowQualitySrc={backgroundLow}
          highQualitySrc={backgroundHigh}
        />

        <div className="grid-x u-width-full align-center-middle">
          <div className="Reviews__column--wrapper small-12 grid-x text-justify" style={{padding: '0 24px'}}>
            <div className="Reviews__column--left medium-6">
              <PhoneReview width="407" height="363" className="Reviews__img show-for-medium hide-for-small"/>
              <div className="Reviews__twinkleStar star-1"/>
              <div className="Reviews__twinkleStar star-2"/>
              <div className="Reviews__twinkleStar star-3"/>
              <div className="Reviews__twinkleStar star-4"/>
              <div className="Reviews__twinkleStar star-5"/>
            </div>
            <div className="Reviews__column--right small-12 medium-6">
              <h1 className="Reviews__title h2 medium">The stars say you like us</h1>
              <TextSlider slides={ReviewsData}/>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Reviews.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    selectedNumber: state.subscribePage.selectedNumber,
  };
}

export default connect(mapStateToProps)(Reviews);
