import React, { Component } from 'react';
import { connect } from 'react-redux';
import HorizontalLinearStepper from './Stepper';

class Header extends Component {
  renderBurnerImage(logo) {
    return (
      <a href="https://www.burnerapp.com/" aria-label="Redirect to burnerapp.com">
        <img src={logo} alt="Burner" title="Burner" width="140" height="33" />
      </a>
    );
  }

  renderMobileHeader() {
    const GoogleBadge = require('images/play-store-badge.png');
    const AppleBadge = require('images/app-store-badge.png');

    return (
      <div className="align-right-middle cell small-8 medium-9">
        <div className="NavMenu align-right">
         <div className="menu-container">
           <div
             className="title-bar align-right text-right hide-for-medium"
             data-responsive-toggle="responsive-menu"
             data-hide-for="medium"
           >
             <button
               className="menu-icon"
               type="button"
               data-toggle="responsive-menu"
             />
             <div className="title-bar-title"></div>
           </div>
           <div className="top-bar" id="responsive-menu">
             <ul
               className="vertical medium-horizontal dropdown menu"
               data-dropdown-menu
             >
               <li>
                 <div className="Header__downloadBadges hide-for-medium">
                   <a
                     href="https://apps.apple.com/us/app/burner-second-phone-number/id505800761"
                     title="Link to download the app from the Apple Store"
                   >
                     <img
                       src={AppleBadge}
                       loading="lazy"
                       alt="Download on the App Store"
                     />
                   </a>
                   <a
                     title="Link to download the app from Google Play"
                     href="https://play.google.com/store/apps/details?id=com.adhoclabs.burner&hl=en"
                   >
                     <img
                       src={GoogleBadge}
                       loading="lazy"
                       alt="Get it on Google Play"
                     />
                   </a>
                 </div>
               </li>
             </ul>
           </div>
         </div>
        </div>
      </div>
    )
  }

  render() {
    const logo = require('images/logo-wordmark.png');
    const { isShowBar } = this.props;

    return (
      <header className="Header align-left grid-x">
        <div className="Header__logo cell small-4 medium-3">
          {this.renderBurnerImage(logo)}
        </div>

        {isShowBar && (
          <div className="Progress-bar__container medium-10 small-12">
            <HorizontalLinearStepper />
          </div>
        )}
      </header>
    );
  }
}

Header.defaultProps = {
  isShowBar: false,
}

function mapStateToProps(state) {
  return {
    currentStep: state.progressBar.currentStep,
    totalSteps: state.progressBar.totalSteps,
  };
}
export default connect(mapStateToProps)(Header);
