/**
 * On December 6, 2024, the payment method was updated for new users.
 * Old users still rely on Stripe tokens and need the script to update payment methods.
 * This script ensures backward compatibility for old users.
 */

import { STRIPE_KEY } from '../constants';

export const handleLoadStripeV2 = () => {
  const script = document.createElement('script');
  script.src = 'https://js.stripe.com/v2/';
  script.async = true;

  script.onload = () => {
    Stripe?.setPublishableKey(STRIPE_KEY);
  };

  script.onerror = () => {
    console.error('Failed to load Stripe script');
  };

  document.body.appendChild(script);
}
