import React from 'react';

export default function MarkIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.77735 11.1106L2.66624 7.99953L1.77734 8.88856L5.77735 12.8886L14.444 4.2219L13.6292 3.25879L5.77735 11.1106Z"
        fill="#111111"
      />
    </svg>
  )
}
