import React, { Fragment } from 'react';

export function TcIcon() {
  return (
    <Fragment>
      <svg
        width="140"
        height="21"
        viewBox="0 0 140 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="hide-for-small show-for-large"
      >
        <g clipPath="url(#clip0_8187_7838)">
          <path d="M39.3946 0.887695H26.4648V7.42469H39.3946V0.887695Z" fill="#333333"/>
          <path d="M0.605469 0.887695V7.42469H7.09438V20.5464H13.5352V7.42469H20.0241V0.887695H0.605469Z"
                fill="#333333"/>
          <path d="M20.0234 20.5465H26.4643H39.4421V14.0095H26.4643V7.4248H20.0234V20.5465Z" fill="#333333"/>
          <path d="M55.9292 5.84961H46.4121V8.56938H49.5845V17.1104H52.7568V8.56938H55.9292V5.84961Z" fill="#333333"/>
          <path
            d="M59.4851 8.47461C57.226 8.47461 55.5918 9.95378 55.5918 12.8644C55.5918 15.441 56.9857 17.2542 59.5813 17.2542C60.9271 17.2542 61.9846 16.9202 63.042 16.2999L61.9365 14.439C61.0713 14.8685 60.4945 15.0116 59.7255 15.0116C58.9083 15.0116 58.3796 14.5822 58.2835 13.6756H63.2823C63.2823 13.5324 63.2823 13.3416 63.2823 13.103C63.2823 10.0015 61.8404 8.47461 59.4851 8.47461ZM58.2835 11.9101C58.3796 11.0512 58.7641 10.6218 59.4371 10.6218C60.3023 10.6218 60.5907 11.0512 60.7348 11.9101H58.2835Z"
            fill="#333333"/>
          <path
            d="M68.8102 14.9151C67.9931 14.9151 67.6086 14.1993 67.6086 12.8633C67.6086 11.5273 67.9931 10.7638 68.7141 10.7638C69.3389 10.7638 69.6273 11.0978 70.0119 11.8613L72.0306 10.3344C71.2616 9.14151 70.3483 8.42578 68.7622 8.42578C66.0224 8.42578 64.7246 10.1912 64.7246 12.8156C64.7246 15.6785 66.2147 17.2531 68.7141 17.2531C70.2041 17.2531 71.1654 16.6805 72.1268 15.2491L70.3003 13.8176C69.7715 14.6288 69.387 14.9151 68.8102 14.9151Z"
            fill="#333333"/>
          <path
            d="M78.9055 8.47552C77.8962 8.47552 77.3194 8.80953 76.7426 9.23896V5.37402L73.8105 6.51919V17.112H76.7426V12.2927C76.7426 11.243 77.1271 10.9567 77.752 10.9567C78.3287 10.9567 78.7133 11.243 78.7133 12.3405V17.112H81.6453V11.9587C81.6453 9.71612 80.732 8.47552 78.9055 8.47552Z"
            fill="#333333"/>
          <path
            d="M88.663 14.5829C87.3172 14.5829 86.7404 13.1992 86.7404 11.4814C86.7404 9.71596 87.3172 8.37993 88.615 8.37993C89.6724 8.37993 90.0569 9.04794 90.4415 10.0977L93.2773 9.00023C92.5083 6.85304 91.4028 5.66016 88.615 5.66016C85.5868 5.66016 83.4238 7.85506 83.4238 11.4814C83.4238 14.8215 85.2023 17.3027 88.615 17.3027C91.3066 17.3027 92.5564 15.8712 93.3254 14.3921L90.6818 13.0083C90.105 14.058 89.6724 14.5829 88.663 14.5829Z"
            fill="#333333"/>
          <path
            d="M98.0824 9.28577V8.66547H95.1504V17.1111H98.0824V12.4827C98.0824 11.433 98.515 11.099 99.236 11.099C99.8128 11.099 100.245 11.3852 100.582 11.8147L101.255 8.80862C100.918 8.61776 100.486 8.47461 99.8609 8.47461C99.1399 8.47461 98.5631 8.80862 98.0824 9.28577Z"
            fill="#333333"/>
          <path
            d="M107.55 13.4862C107.55 14.536 107.165 14.8223 106.589 14.8223C106.012 14.8223 105.627 14.536 105.627 13.4385V8.66699H102.695V13.8202C102.695 16.0629 103.609 17.3035 105.435 17.3035C106.444 17.3035 107.021 16.9695 107.55 16.54V17.1126H110.482V8.66699H107.55V13.4862Z"
            fill="#333333"/>
          <path
            d="M117.647 8.47461C116.637 8.47461 116.06 8.80862 115.484 9.23805V8.66547H112.6V17.1111H115.532V12.2918C115.532 11.2421 115.916 10.9558 116.541 10.9558C117.118 10.9558 117.502 11.2421 117.502 12.3395V17.1111H120.434V11.9578C120.434 9.71521 119.521 8.47461 117.647 8.47461Z"
            fill="#333333"/>
          <path
            d="M126.201 14.9151C125.384 14.9151 124.999 14.1993 124.999 12.8633C124.999 11.5273 125.384 10.7638 126.105 10.7638C126.73 10.7638 127.018 11.0978 127.402 11.8613L129.421 10.3344C128.652 9.14151 127.739 8.42578 126.153 8.42578C123.413 8.42578 122.115 10.1912 122.115 12.8156C122.115 15.6785 123.605 17.2531 126.105 17.2531C127.595 17.2531 128.556 16.6805 129.517 15.2491L127.691 13.8176C127.162 14.6288 126.778 14.9151 126.201 14.9151Z"
            fill="#333333"/>
          <path
            d="M136.296 8.47552C135.287 8.47552 134.71 8.80953 134.133 9.23896V5.37402L131.201 6.51919V17.112H134.133V12.2927C134.133 11.243 134.518 10.9567 135.143 10.9567C135.719 10.9567 136.104 11.243 136.104 12.3405V17.112H139.036V11.9587C139.036 9.71612 138.123 8.47552 136.296 8.47552Z"
            fill="#333333"/>
        </g>
        <defs>
          <clipPath id="clip0_8187_7838">
            <rect width="138.43" height="19.6587" fill="white" transform="translate(0.603516 0.886719)"/>
          </clipPath>
        </defs>
      </svg>
      <svg
        width="102"
        height="15"
        viewBox="0 0 102 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="show-for-small hide-for-large"
      >
        <g clipPath="url(#clip0_8187_5221)">
          <path d="M28.5818 0H19.0547V4.98786H28.5818V0Z" fill="#111111"/>
          <path d="M0 0V4.98786H4.78125V15H9.52708V4.98786H14.3083V0H0Z" fill="#111111"/>
          <path d="M14.3086 14.9999H19.0544H28.6169V10.0121H19.0544V4.98779H14.3086V14.9999Z" fill="#111111"/>
          <path d="M40.7664 3.78662H33.7539V5.86186H36.0914V12.3789H38.4289V5.86186H40.7664V3.78662Z" fill="#111111"/>
          <path
            d="M43.3844 5.78906C41.7198 5.78906 40.5156 6.9177 40.5156 9.13858C40.5156 11.1046 41.5427 12.4881 43.4552 12.4881C44.4469 12.4881 45.226 12.2332 46.0052 11.7599L45.1906 10.34C44.5531 10.6677 44.1281 10.7769 43.5615 10.7769C42.9594 10.7769 42.5698 10.4493 42.499 9.75751H46.1823C46.1823 9.64829 46.1823 9.50265 46.1823 9.32062C46.1823 6.95411 45.1198 5.78906 43.3844 5.78906ZM42.499 8.41042C42.5698 7.75508 42.8531 7.42741 43.349 7.42741C43.9865 7.42741 44.199 7.75508 44.3052 8.41042H42.499Z"
            fill="#111111"/>
          <path
            d="M50.2565 10.7039C49.6544 10.7039 49.3711 10.1578 49.3711 9.13836C49.3711 8.11895 49.6544 7.53642 50.1857 7.53642C50.6461 7.53642 50.8586 7.79128 51.1419 8.3738L52.6294 7.20875C52.0628 6.29856 51.3898 5.75244 50.2211 5.75244C48.2023 5.75244 47.2461 7.09953 47.2461 9.10196C47.2461 11.2864 48.344 12.4879 50.1857 12.4879C51.2836 12.4879 51.9919 12.051 52.7003 10.9588L51.3544 9.86652C50.9648 10.4855 50.6815 10.7039 50.2565 10.7039Z"
            fill="#111111"/>
          <path
            d="M57.6956 5.78887C56.9518 5.78887 56.5268 6.04372 56.1018 6.37139V3.42236L53.9414 4.29615V12.3787H56.1018V8.70149C56.1018 7.90052 56.3852 7.68207 56.8456 7.68207C57.2706 7.68207 57.5539 7.90052 57.5539 8.7379V12.3787H59.7143V8.44664C59.7143 6.73547 59.0414 5.78887 57.6956 5.78887Z"
            fill="#111111"/>
          <path
            d="M64.8838 10.4489C63.8922 10.4489 63.4672 9.39305 63.4672 8.08237C63.4672 6.73529 63.8922 5.71587 64.8484 5.71587C65.6276 5.71587 65.9109 6.22558 66.1943 7.02655L68.2838 6.18917C67.7172 4.55082 66.9026 3.64062 64.8484 3.64062C62.6172 3.64062 61.0234 5.31538 61.0234 8.08237C61.0234 10.6309 62.3339 12.5241 64.8484 12.5241C66.8318 12.5241 67.7526 11.4319 68.3193 10.3032L66.3713 9.24742C65.9463 10.0484 65.6276 10.4489 64.8838 10.4489Z"
            fill="#111111"/>
          <path
            d="M71.8245 6.40799V5.93469H69.6641V12.3789H71.8245V8.84731C71.8245 8.04634 72.1432 7.79149 72.6745 7.79149C73.0995 7.79149 73.4182 8.00994 73.6661 8.33761L74.162 6.04392C73.9141 5.89829 73.5953 5.78906 73.1349 5.78906C72.6036 5.78906 72.1786 6.04392 71.8245 6.40799Z"
            fill="#111111"/>
          <path
            d="M78.8036 9.61176C78.8036 10.4127 78.5203 10.6312 78.0953 10.6312C77.6703 10.6312 77.387 10.4127 77.387 9.57535V5.93457H75.2266V9.86661C75.2266 11.5778 75.8995 12.5244 77.2453 12.5244C77.9891 12.5244 78.4141 12.2695 78.8036 11.9419V12.3787H80.9641V5.93457H78.8036V9.61176Z"
            fill="#111111"/>
          <path
            d="M86.2383 5.78906C85.4945 5.78906 85.0695 6.04392 84.6445 6.37159V5.93469H82.5195V12.3789H84.68V8.70168C84.68 7.90071 84.9633 7.68227 85.4237 7.68227C85.8487 7.68227 86.132 7.90071 86.132 8.73809V12.3789H88.2925V8.44683C88.2925 6.73566 87.6195 5.78906 86.2383 5.78906Z"
            fill="#111111"/>
          <path
            d="M92.5456 10.7039C91.9435 10.7039 91.6602 10.1578 91.6602 9.13836C91.6602 8.11895 91.9435 7.53642 92.4747 7.53642C92.9352 7.53642 93.1477 7.79128 93.431 8.3738L94.9185 7.20875C94.3518 6.29856 93.6789 5.75244 92.5102 5.75244C90.4914 5.75244 89.5352 7.09953 89.5352 9.10196C89.5352 11.2864 90.6331 12.4879 92.4747 12.4879C93.5727 12.4879 94.281 12.051 94.9893 10.9588L93.6435 9.86652C93.2539 10.4855 92.9706 10.7039 92.5456 10.7039Z"
            fill="#111111"/>
          <path
            d="M99.9807 5.78887C99.237 5.78887 98.812 6.04372 98.387 6.37139V3.42236L96.2266 4.29615V12.3787H98.387V8.70149C98.387 7.90052 98.6703 7.68207 99.1307 7.68207C99.5557 7.68207 99.8391 7.90052 99.8391 8.7379V12.3787H101.999V8.44664C101.999 6.73547 101.327 5.78887 99.9807 5.78887Z"
            fill="#111111"/>
        </g>
        <defs>
          <clipPath id="clip0_8187_5221">
            <rect width="102" height="15" fill="white"/>
          </clipPath>
        </defs>
      </svg>

    </Fragment>
  )
}
