import React from 'react';
export function ArrowIcon(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
    <path
      d="M9.03863 2.30962L13.8666 7.13754V7.35009H2V8.39408H13.8666V8.59646L9.03863 13.4244L9.84825 14.234L15.0117 9.07055V6.66346L9.84825 1.5L9.03863 2.30962Z"
      fill={props.fill}/>
  </svg>)
}
