import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import clipboard from 'clipboard-js';
import moment from 'moment';

import * as actionCreators from '../ducks/burnerDetails';
import { renderLocalNumber } from '../utils/phone';
import { guid } from '../utils/uuid';
import {
  isBurnerInSubscription,
  canAssignBurnerToSubscription,
} from '../utils/subscription';
import { isValidUrl } from '../utils/validation';
import { isUnlimited, isMms } from '../utils/burner';
import AppButtons from '../components/AppButtons';
import ConfirmConnectModal from '../components/modal/ConfirmConnectModal';
import LoggedInHeader from '../components/common/LoggedInHeader';
import Footer from '../components/oauth/Footer';
import SuccessModal from '../components/modal/SuccessModal';
import '!style-loader!css-loader!sass-loader!../../styles/dashboard-rebrand.scss';
import {ROUTERS} from '../constants';

const propTypes = {
  associateBurnerWithSub: PropTypes.func.isRequired,
  authToken: PropTypes.string.isRequired,
  burner: PropTypes.shape({
    callerIdEnabled: PropTypes.bool,
    remainingTexts: PropTypes.number,
    name: PropTypes.string,
    expirationDate: PropTypes.number,
    phoneNumberId: PropTypes.string,
    hexColor: PropTypes.number,
    features: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        featureName: PropTypes.string,
      })
    ),
    expirationWarning: PropTypes.shape({
      id: PropTypes.number,
    }),
    alias: PropTypes.string,
    version: PropTypes.number,
    id: PropTypes.string,
    autoReplyActive: PropTypes.bool,
    dateCreated: PropTypes.number,
    lastUpdatedDate: PropTypes.number,
    renewalDate: PropTypes.number,
    ringer: PropTypes.bool,
    autoReplyType: PropTypes.number,
    remainingMinutes: PropTypes.number,
    productId: PropTypes.string,
    userId: PropTypes.string,
    autoReplyText: PropTypes.string,
    totalMinutes: PropTypes.number,
    notifications: PropTypes.bool,
    useSip: PropTypes.bool,
    totalTexts: PropTypes.number,
  }),
  confirmDevConnection: PropTypes.func.isRequired,
  developerSettings: PropTypes.shape({
    url: PropTypes.string,
    inboundWebhookUrl: PropTypes.string,
    paused: PropTypes.string,
  }),
  getBurnerDetails: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  outboundWebhookTest: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  rules: PropTypes.shape({
    inboxRecentMessageAgeLimit: PropTypes.number,
    countries: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        code: PropTypes.string,
      })
    ),
    allowCrossgrade: PropTypes.bool,
    systemNumbers: PropTypes.arrayOf(PropTypes.string),
    subscriptions: PropTypes.arrayOf(
      PropTypes.shape({
        totalConcurrentBurners: PropTypes.number,
        totalBurnersAllowedInPeriod: PropTypes.number,
        sku: PropTypes.string,
      })
    ),
    connections: PropTypes.shape({
      defaultBotHeading: PropTypes.string,
    }),
  }).isRequired,
  subscriptions: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string,
      price: PropTypes.number,
      store: PropTypes.string,
      burnersAssignedInPeriod: PropTypes.number,
      id: PropTypes.string,
      receipt: PropTypes.string,
      renewalDate: PropTypes.number,
      creationDate: PropTypes.number,
      burnerIds: PropTypes.arrayOf(PropTypes.string),
    })
  ).isRequired,
  updateDevConnectionSettings: PropTypes.func.isRequired,
  user: PropTypes.shape({
    trackingId: PropTypes.string,
    sip: PropTypes.shape({
      uri: PropTypes.string,
      password: PropTypes.string,
    }),
    phoneNumber: PropTypes.string,
    totalNumberBurners: PropTypes.number,
    credits: PropTypes.number,
    version: PropTypes.number,
    id: PropTypes.string,
    dateCreated: PropTypes.number,
    carrierName: PropTypes.string,
    createdSample: PropTypes.bool,
    lastUpdatedDate: PropTypes.number,
    countryCode: PropTypes.string,
    superUser: PropTypes.bool,
    platform: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    lifetimeSubscriptions: PropTypes.number,
  }).isRequired,
};

const defaultProps = {
  burner: null,
  developerSettings: null,
};

class BurnerDetailsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: null,
      url: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.renderExpandedDevConnection =
      this.renderExpandedDevConnection.bind(this);
    this.renderAssignBurnerToSubButton =
      this.renderAssignBurnerToSubButton.bind(this);
    this.renderInboundUrl = this.renderInboundUrl.bind(this);
    this.renderInboundSection = this.renderInboundSection.bind(this);
    this.renderInboundActions = this.renderInboundActions.bind(this);
    this.renderExpiresText = this.renderExpiresText.bind(this);
    this.renderRemaining = this.renderRemaining.bind(this);
    this.renderTextsRemaining = this.renderTextsRemaining.bind(this);
    this.renderMinutesRemaining = this.renderMinutesRemaining.bind(this);
    this.renderDescription = this.renderDescription.bind(this);
    this.renderIsPictureEnabled = this.renderIsPictureEnabled.bind(this);
  }

  componentDidMount() {
    const { user, authToken, getBurnerDetails, params } = this.props;
    const { router } = this.context;

    if (user) {
      getBurnerDetails(authToken, user.id, params.burnerId);
    } else {
      router.push(ROUTERS.homePage);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { router } = this.context;

    if (!nextProps.isAuthenticated) {
      router.push(ROUTERS.homePage);
    } else if (nextProps.developerSettings && nextProps.developerSettings.url) {
      this.setState(() => ({
        url: nextProps.developerSettings.url,
      }));
    }
  }

  updateInboundToken(token = guid()) {
    const { user, authToken, burner, updateDevConnectionSettings } = this.props;

    updateDevConnectionSettings(user.id, authToken, burner.id, {
      inboundWebhookToken: token,
    });
  }

  handleChange(event) {
    const value = event.target.value;

    if (isValidUrl(value)) {
      this.setState(() => ({
        hasError: false,
        url: value,
      }));
    } else {
      this.setState(() => ({
        hasError: true,
        url: value,
      }));
    }
  }

  renderExpandedDevConnection() {
    const { url, hasError } = this.state;
    const {
      updateDevConnectionSettings,
      outboundWebhookTest,
      user,
      burner,
      authToken,
    } = this.props;

    return (
      <div id="connection-details">
        <div className="row connection-expanded">
          <div className="large-12 columns">
            <h5>Outgoing webhook</h5>
            <p>
              Designate a URL to receive real-time posts of your Burner activity
            </p>
            <input
              className="full-width inbound-input"
              onChange={this.handleChange}
              value={url}
              placeholder="https://burner.myurl.com/messages"
            />
            {hasError ? (
              <span className="url-error">Please enter a valid url</span>
            ) : null}
          </div>
          <div className="large-12 columns button-container">
            <button
              className="flat button"
              disabled={!url || hasError}
              onClick={() =>
                updateDevConnectionSettings(user.id, authToken, burner.id, {
                  url,
                })
              }
              type="button"
            >
              Save
            </button>
            <button
              className="flat button"
              disabled={!url || hasError}
              onClick={() => outboundWebhookTest(user.id, authToken, burner.id)}
              type="button"
            >
              Send test to URL
            </button>
          </div>
        </div>

        {this.renderInboundSection()}
      </div>
    );
  }

  renderAssignBurnerToSubButton() {
    const {
      subscriptions,
      burner,
      rules,
      associateBurnerWithSub,
      user,
      authToken,
    } = this.props;

    if (
      !subscriptions ||
      !subscriptions[0] ||
      isBurnerInSubscription(burner, subscriptions) ||
      !canAssignBurnerToSubscription(rules, subscriptions)
    ) {
      return null;
    }

    return (
      <button
        className="flat button submit convert-to-premium-btn"
        onClick={() =>
          associateBurnerWithSub(
            authToken,
            user.id,
            subscriptions[0].id,
            burner.id
          )
        }
        type="button"
      >
        Convert to an Auto-renewing Number
      </button>
    );
  }

  renderInboundUrl() {
    const { developerSettings } = this.props;

    if (developerSettings && developerSettings.inboundWebhookUrl) {
      return <code>{developerSettings.inboundWebhookUrl}</code>;
    }
  }

  renderInboundSection() {
    return (
      <div className="row connection-expanded">
        <div className="large-12 columns">
          <h5>Incoming webhook</h5>
          <p>
            Designate a URL to receive real-time posts of your Burner activity.
          </p>

          <div className="incoming-url-container">
            {this.renderInboundUrl()}
          </div>
        </div>
        {this.renderInboundActions()}
      </div>
    );
  }

  renderInboundActions() {
    const { developerSettings } = this.props;

    if (developerSettings && developerSettings.inboundWebhookUrl) {
      return (
        <div className="large-12 columns button-container">
          <button
            className="flat button"
            onClick={() => this.updateInboundToken()}
            type="button"
          >
            Regenerate
          </button>
          <button
            className="flat button"
            onClick={() => clipboard.copy(developerSettings.inboundWebhookUrl)}
            type="button"
          >
            Copy
          </button>
          <button
            className="flat button"
            onClick={() => this.updateInboundToken('')}
            type="button"
          >
            Delete
          </button>
        </div>
      );
    }

    return (
      <div className="large-12 columns button-container">
        <button
          className="flat button"
          onClick={() => this.updateInboundToken()}
          type="button"
        >
          Generate
        </button>
      </div>
    );
  }

  renderExpiresText() {
    const { burner, subscriptions } = this.props;

    if (burner) {
      return (
        <div className="Feature__row grid-x">
          <div className="cell small-12">
            <h6>
              {isBurnerInSubscription(burner, subscriptions)
                ? 'Auto-Renews'
                : 'Expires'}
            </h6>
            {isBurnerInSubscription(burner, subscriptions)
              ? moment(subscriptions[0].renewalDate).format('LLLL')
              : moment(burner.expirationDate).format('LLLL')}
          </div>
        </div>
      );
    }
  }

  renderRemaining(isText) {
    const { burner } = this.props;

    if (!burner) {
      return null;
    }

    let text;

    if (isUnlimited(burner)) {
      text = `Unlimited ${isText ? 'texts' : 'minutes'} remaining`;
    } else {
      const remaining = isText
        ? burner.remainingTexts
        : burner.remainingMinutes;
      text = `${remaining} ${isText ? 'text' : 'minute'}${
        remaining > 0 ? 's' : ''
      } remaining`;
    }

    return (
      <div className="Feature__row grid-x">
        <div className="cell small-12">
          <h6>{isText ? 'Texts' : 'Minutes'}</h6>
          {text}
        </div>
      </div>
    );
  }

  renderTextsRemaining() {
    return this.renderRemaining(true);
  }

  renderMinutesRemaining() {
    return this.renderRemaining(false);
  }

  renderDescription() {
    const { burner, subscriptions } = this.props;

    if (isBurnerInSubscription(burner, subscriptions)) {
      return (
        <p>
          This is a <b className="highlight">premium</b> number. To start using
          Burner, download the app for either Android or iOS and sign in with
          your account phone number.
        </p>
      );
    }
    return (
      <p>
        To <b className="highlight">extend</b> this number, download the app for
        either Android or iOS and sign in with your account phone number.
      </p>
    );
  }

  renderIsPictureEnabled() {
    const { burner, subscriptions } = this.props;

    return (
      <div className="Feature__row grid-x">
        <div className="cell small-12">
          <h6>Picture enabled</h6>
          {isMms(burner) || isBurnerInSubscription(burner, subscriptions)
            ? 'Yes'
            : 'No'}
        </div>
      </div>
    );
  }

  render() {
    const { burner } = this.props;
    return (
      <div>
        <LoggedInHeader />
        <div className="Dashboard__container grid-x grid-padding-x align-center-middle">
          <div className="cell small-10 large-7">
            <h1 className="title-heading h2 u-margin-top-35px">
              {burner ? `${burner.name}` : ''}
            </h1>
            <h4 className="u-margin-top-35px">
              {burner ? `${renderLocalNumber(burner.phoneNumberId)}` : ''}
            </h4>
            <p className="u-margin-top-20px">
              To extend this number, download the app for either Android or iOS
              and sign in with your account phone number.
            </p>
          </div>
        </div>
        <div className="Dashboard__secondaryContainer Dashboard__container--bgWhite grid-x grid-padding-x align-center-middle">
          <div className="cell large-7 small-10">
            <div className="grid-x grid-padding-x align-center-middle">
              <div className="cell large-5">
                <AppButtons />
                {/*<LinkTexting userPhoneNumber={user.phoneNumber} />*/}
              </div>
            </div>
          </div>
          <SuccessModal />
          <ConfirmConnectModal />
        </div>
        <div className="DashboardFeature__container grid-x grid-padding-x align-center-middle">
          <div className="cell small-10 large-7">
            <h3>Details</h3>
            <div className="u-margin-top-20px feature row">
              {this.renderTextsRemaining()}
            </div>
            {this.renderMinutesRemaining()}
            {this.renderExpiresText()}
            {this.renderIsPictureEnabled()}
            {this.renderAssignBurnerToSubButton()}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

BurnerDetailsPage.propTypes = propTypes;
BurnerDetailsPage.defaultProps = defaultProps;
BurnerDetailsPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    authToken: state.auth.authToken,
    burner: state.burnerDetailsPage.burner,
    developerSettings: state.burnerDetailsPage.developerSettings,
    isAuthenticated: state.auth.isAuthenticated,
    rules: state.burnerDetailsPage.rules,
    subscriptions: state.burnerDetailsPage.subscriptions,
    user: state.auth.user,
  };
}

const mapDispatchToProps = actionCreators;

export default connect(mapStateToProps, mapDispatchToProps)(BurnerDetailsPage);
