import React, { Component } from 'react';
import ReviewsRating from '../icons/paywall/reviewsRating';

import '!style-loader!css-loader!sass-loader!../../../styles/text-slider.scss';

class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSlide: 0,
      fade: true,
      autoplayStarted: false,
    };
    this.interval = null;
    this.sliderRef = React.createRef();
  }

  componentDidMount() {
    this.setupIntersectionObserver();
  }

  componentWillUnmount() {
    this.stopAutoplay();
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  setupIntersectionObserver = () => {
    const options = {
      root: null,
      rootMargin: '-70px 0px',
      threshold: 0.5,
    };

    this.observer = new IntersectionObserver(this.handleIntersection, options);

    if (this.sliderRef.current) {
      this.observer.observe(this.sliderRef.current);
    }
  };

  handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && !this.state.autoplayStarted) {
        const { delay = 3000 } = this.props;
        this.startAutoplay(delay);
        this.setState({ autoplayStarted: true });
      } else if (!entry.isIntersecting && this.state.autoplayStarted) {
        this.stopAutoplay();
        this.setState({ autoplayStarted: false });
      }
    });
  };

  startAutoplay = (delay) => {
    this.interval = setInterval(this.nextSlide, delay);
  };

  stopAutoplay = () => {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  };

  nextSlide = () => {
    const { currentSlide } = this.state;
    const { slides } = this.props;

    this.setState({ fade: false }, () => {
      setTimeout(() => {
        this.setState({
          currentSlide: (currentSlide + 1) % slides.length,
          fade: true,
        });
      }, 500);
    });
  };

  handleNextClick = () => {
    this.stopAutoplay();
    this.nextSlide();
  };

  render() {
    const { slides } = this.props;
    const { currentSlide, fade } = this.state;
    const { text, author } = slides[currentSlide];

    return (
      <div ref={this.sliderRef} className="slider__container">
        <div className={`slide ${fade ? 'fade-in' : 'fade-out'}`}>
          <ReviewsRating className="slider__rating" />
          <p className="slider__text medium">{text}</p>
          <p className="slider__author">{author}</p>
        </div>
        <button onClick={this.handleNextClick} className="slider__button--wrapper u-width-fullbold">
          <span>Next</span>
          <div className="slider__arrow" />
        </button>
      </div>
    );
  }
}

export default Slider;
