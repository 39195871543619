import axios from 'axios';
import cookie from 'react-cookie';
import { BASE_URL, AUTHENTICATION_KEY, CURRENT_USER } from '../constants';
import { hasError } from './error';
import {
  NO_CONNECTION_ACTION,
  RECEIVE_CONNECTION_SETTINGS,
  DEV_CONNECT_SUCCESS,
  DEV_UNLINK_SUCCESS,
} from './burnerDetails';
import { RECEIVE_SUBSCRIPTIONS, RECEIVE_PAYMENT_INFO, RECEIVE_SUBSCRIPTIONS_INFO } from './dashboard';
import { CANCEL_CONFIRM_MODAL } from './confirm';
import { LOGOUT_SUCCESS } from './verify';
import { extractError } from '../utils/request';
import { isStripeSubscription } from '../utils/subscription';
import * as amplitude from '@amplitude/analytics-browser';
import { clearUserData } from './auth';

// Action Types
export const RECEIVE_BURNERS_LIST = 'burner-web/general/RECEIVE_BURNERS_LIST';
export const REQUESTING = 'burner-web/general/REQUESTING';

export function requesting() {
  return {
    type: REQUESTING,
  };
}

export function receiveBurnersList(burners) {
  return {
    type: RECEIVE_BURNERS_LIST,
    burners,
  };
}

export function closeConfirmModal() {
  return {
    type: CANCEL_CONFIRM_MODAL,
  };
}

export function receivePaymentInfo(paymentInfo) {
  return {
    type: RECEIVE_PAYMENT_INFO,
    paymentInfo,
  };
}
export function receiveSubscribtions(subscriptions) {
  return {
    type: RECEIVE_SUBSCRIPTIONS_INFO,
    subscriptions,
  };
}

export function subscriptionRequest(dispatch, authToken, userId, successFn) {
  dispatch(requesting());

  // Check if a user has a subscription available
  axios
    .get(`${BASE_URL}/user/${userId}/subscriptions`, {
      headers: {
        Authentication: authToken,
        'Cache-Control': 'no-cache, no-store, must-revalidate',
      },
    })
    .then((resp) => {
      receiveSubscribtions(resp.data);
      dispatch(successFn(resp.data))
    })
    .catch((error) => dispatch(hasError(extractError(error), error.status)));
}

export function getBurnersList(params, successFn = receiveBurnersList) {
  return (dispatch) => {
    dispatch(requesting());

    return axios
      .get(`${BASE_URL}/user/${params.userId}/burners`, {
        headers: {
          Authentication: params.authToken,
        },
      })
      .then((resp) => {
        dispatch(successFn(resp.data, params));
      })
      .catch((error) => {
        dispatch(hasError(extractError(error), error.status));
      });
  };
}

function receiveDevConnectionSettings(burnerId, developerSettings) {
  return {
    type: RECEIVE_CONNECTION_SETTINGS,
    burnerId,
    developerSettings,
  };
}

export function getDevConnectionSettings(userId, authToken, burnerId) {
  return (dispatch) => {
    dispatch(requesting());
    axios
      .get(`${BASE_URL}/user/${userId}/burners/${burnerId}/integration/settings/com.adhoclabs.connections.developer`, {
        headers: {
          Authentication: authToken,
        },
      })
      .then((resp) => {
        dispatch(receiveDevConnectionSettings(burnerId, resp.data));
      })
      .catch((error) => {
        dispatch(hasError(extractError(error), error.status));
      });
  };
}

function noDevConnectionAction(burnerId) {
  return {
    type: NO_CONNECTION_ACTION,
    burnerId,
  };
}

function receiveDevConnections(authToken, userId, burnerId, connections) {
  if (
    !connections ||
    !connections.find(
      (connection) => connection.service === 'com.adhoclabs.connections.developer' && connection.status === 'connected'
    )
  ) {
    return noDevConnectionAction(burnerId);
  }
  return getDevConnectionSettings(userId, authToken, burnerId);
}

/**
 * Gets the burner connections for this burner. If it's set then
 */
export function getBurnerConnections(authToken, userId, burnerId) {
  return (dispatch) => {
    dispatch(requesting());

    axios
      .get(`${BASE_URL}/user/${userId}/burners/${burnerId}/integrations`, {
        headers: {
          Authentication: authToken,
        },
      })
      .then((resp) => {
        dispatch(receiveDevConnections(authToken, userId, burnerId, resp.data));
      })
      .catch((error) => {
        dispatch(hasError(extractError(error), error.status));
      });
  };
}

function connectDevSuccess(burnerId) {
  return {
    type: DEV_CONNECT_SUCCESS,
    burnerId,
  };
}

export function requestDevConnect(userId, authToken, burnerId) {
  return (dispatch) => {
    dispatch(requesting());
    dispatch(closeConfirmModal()); // also close the confirm modal

    axios
      .put(
        `${BASE_URL}/user/${userId}/burners/${burnerId}/connect?token=DEVELOPER&service=com.adhoclabs.connections.developer`,
        {},
        {
          headers: {
            Authentication: authToken,
            'Content-Type': 'application/json',
          },
        }
      )
      .then(() => dispatch(connectDevSuccess(burnerId)))
      .catch((error) => dispatch(hasError(extractError(error), error.status)));
  };
}

function unlinkSuccess() {
  return {
    type: DEV_UNLINK_SUCCESS,
  };
}

export function requestDevUnlink(userId, authToken, burnerId) {
  return (dispatch) => {
    dispatch(requesting());
    dispatch(closeConfirmModal()); // also close the confirm modal

    axios
      .put(
        `${BASE_URL}/user/${userId}/burners/${burnerId}/disconnect?service=com.adhoclabs.connections.developer`,
        {},
        {
          headers: {
            Authentication: authToken,
            'Content-Type': 'application/json',
          },
        }
      )
      .then(() => dispatch(unlinkSuccess()))
      .catch((error) => dispatch(hasError(extractError(error), error.status)));
  };
}

function getPaymentInfo(authToken, userId) {
  return (dispatch) => {
    dispatch(requesting());
    axios
      .get(`${BASE_URL}/user/${userId}/subscriptions/stripe/sources`, {
        headers: {
          Authentication: authToken,
        },
      })
      .then((resp) => {
        dispatch(receivePaymentInfo(resp.data.sources[0]));
      })
      .catch((error) => {
        dispatch(hasError(extractError(error), error.status));
      });
  };
}

function receiveSubscriptionInfo(rules, subscriptions) {
  return {
    type: RECEIVE_SUBSCRIPTIONS,
    subscriptions,
    rules,
  };
}

function processSubscriptionInfo(rules, subscriptions, authToken, userId) {
  return (dispatch) => {
    if (isStripeSubscription(subscriptions[0])) {
      dispatch(getPaymentInfo(authToken, userId));
    }
    dispatch(receiveSubscriptionInfo(rules, subscriptions));
  };
}

// retrieves the subscription rules (ie. total max concurrent burners, total allowed in period
function getSubscriptionRules(subscriptions, authToken, userId) {
  return (dispatch) => {
    axios
      .get(`${BASE_URL}/rules`)
      .then((resp) => dispatch(processSubscriptionInfo(resp.data, subscriptions, authToken, userId)))
      .catch((error) => {
        dispatch(hasError(extractError(error), error.status));
      });
  };
}

export function getSubscriptionInfo(authToken, userId) {
  return (dispatch) => {
    subscriptionRequest(dispatch, authToken, userId, (json) => getSubscriptionRules(json, authToken, userId));
  };
}

function receiveLogout() {
  return {
    type: LOGOUT_SUCCESS,
    isFetching: false,
    isAuthenticated: false,
  };
}

export function clearUserInfo() {
  return (dispatch) => {
    amplitude.reset();

    cookie.remove(AUTHENTICATION_KEY);
    cookie.remove(CURRENT_USER);

    dispatch(receiveLogout());
  };
}

export function logOutUser() {
  return (dispatch) => {
    dispatch(requesting());

    cookie.remove(AUTHENTICATION_KEY);
    cookie.remove(CURRENT_USER);

    dispatch(receiveLogout());
    dispatch(clearUserData())
  };
}
