import React from 'react';

export default function ReviewsRating({className}) {
  return (
    <svg width="100" height="17" viewBox="0 0 122 17" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        d="M5.0713 17L2.37913 15.042L6.26087 9.72761L0 7.7075L1.00174 4.56856L7.32522 6.55759V0H10.6748V6.55759L16.967 4.56856L18 7.7075L11.6765 9.72761L15.5896 15.042L12.8661 17L8.98435 11.6545L5.0713 17Z"
        fill="#FFFF5C"/>
      <path
        d="M31.0713 17L28.3791 15.042L32.2609 9.72761L26 7.7075L27.0017 4.56856L33.3252 6.55759V0H36.6748V6.55759L42.967 4.56856L44 7.7075L37.6765 9.72761L41.5896 15.042L38.8661 17L34.9843 11.6545L31.0713 17Z"
        fill="#FFFF5C"/>
      <path
        d="M57.0713 17L54.3791 15.042L58.2609 9.72761L52 7.7075L53.0017 4.56856L59.3252 6.55759V0H62.6748V6.55759L68.967 4.56856L70 7.7075L63.6765 9.72761L67.5896 15.042L64.8661 17L60.9843 11.6545L57.0713 17Z"
        fill="#FFFF5C"/>
      <path
        d="M83.0713 17L80.3791 15.042L84.2609 9.72761L78 7.7075L79.0017 4.56856L85.3252 6.55759V0H88.6748V6.55759L94.967 4.56856L96 7.7075L89.6765 9.72761L93.5896 15.042L90.8661 17L86.9843 11.6545L83.0713 17Z"
        fill="#FFFF5C"/>
      <path
        d="M109.071 17L106.379 15.042L110.261 9.72761L104 7.7075L105.002 4.56856L111.325 6.55759V0H114.675V6.55759L120.967 4.56856L122 7.7075L115.677 9.72761L119.59 15.042L116.866 17L112.984 11.6545L109.071 17Z"
        fill="#FFFF5C"/>
    </svg>
  )
}
