import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-modal';

import * as actionCreators from '../../ducks/confirm';
import { guid } from '../../utils/uuid';

const propTypes = {
  authToken: PropTypes.string.isRequired,
  burnerId: PropTypes.string,
  cancelModal: PropTypes.func.isRequired,
  confirmConnect: PropTypes.func.isRequired,
  confirmUnlink: PropTypes.func.isRequired,
  showPrompt: PropTypes.bool,
  text: PropTypes.string,
  title: PropTypes.string,
  toggleOn: PropTypes.bool,
  user: PropTypes.shape({
    trackingId: PropTypes.string,
    sip: PropTypes.shape({
      uri: PropTypes.string,
      password: PropTypes.string,
    }),
    phoneNumber: PropTypes.string,
    totalNumberBurners: PropTypes.number,
    credits: PropTypes.number,
    version: PropTypes.number,
    id: PropTypes.string,
    dateCreated: PropTypes.number,
    carrierName: PropTypes.string,
    createdSample: PropTypes.bool,
    lastUpdatedDate: PropTypes.number,
    countryCode: PropTypes.string,
    superUser: PropTypes.bool,
    platform: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    lifetimeSubscriptions: PropTypes.number,
  }).isRequired,
};

const defaultProps = {
  burnerId: null,
  showPrompt: false,
  text: null,
  title: null,
};

class ConfirmConnectModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.modalId = guid();
  }

  componentDidUpdate(prevProps) {
    const { showPrompt } = this.props;

    if (prevProps.showPrompt === showPrompt) {
      return;
    }
    if (showPrompt) {
      this.openModal();
    } else if (!showPrompt) {
      this.closeModal();
    }
  }

  openModal() {
    this.setState(() => ({
      modalIsOpen: true,
    }));
  }

  closeModal() {
    const { cancelModal } = this.props;

    this.setState(() => ({
      modalIsOpen: false,
    }));
    cancelModal();
  }

  performConfirmAction() {
    const { toggleOn, confirmConnect, user, authToken, burnerId, confirmUnlink } = this.props;

    return toggleOn ? confirmConnect(user.id, authToken, burnerId) : confirmUnlink(user.id, authToken, burnerId);
  }

  render() {
    const { modalIsOpen } = this.state;
    const { title, text } = this.props;

    return (
      <Modal
        contentLabel="Confirm Connection Modal"
        isOpen={modalIsOpen}
        onRequestClose={this.closeModal}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        bodyOpenClassName={null}
        className="burner-modal"
        overlayClassName="burner-modal-overlay"
        ariaHideApp={false}
      >
        <div id={this.modalId}>
          <div className="row">
            <div className="large-12 columns">
              <h2 className="renewal-title">{title}</h2>
              <p className="renewal-message">{text}</p>
              <button type="submit" onClick={() => this.closeModal()} className="cancel-button submit">
                Cancel
              </button>
              <button
                type="submit"
                onClick={() => this.performConfirmAction()}
                className="flat button selection-button submit"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

ConfirmConnectModal.propTypes = propTypes;
ConfirmConnectModal.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    authToken: state.auth.authToken,
    burnerId: state.confirm.burnerId,
    showPrompt: state.confirm.showPrompt,
    text: state.confirm.text,
    title: state.confirm.title,
    toggleOn: state.confirm.toggleOn,
    user: state.auth.user,
  };
}

const mapDispatchToProps = actionCreators;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmConnectModal);
