import React from 'react';

export function TnwIcon() {
  return (
    <svg width="89" height="22" viewBox="0 0 89 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M43.4479 6.98342C44.9431 8.48078 45.7839 10.512 45.7852 12.6306V21.6251H37.8175V13.7332C37.8162 13.0267 37.5351 12.3496 37.0359 11.8508L34.2839 9.09414C33.7851 8.59486 33.109 8.31444 32.404 8.31447H29.8498V21.6251H21.882V0.328125H33.5042C34.5505 0.328123 35.5866 0.5347 36.5533 0.936058C37.52 1.33742 38.3984 1.9257 39.1383 2.66731L43.4479 6.98342ZM80.3121 0.328125V8.21997C80.3107 8.92645 80.0296 9.60349 79.5305 10.1023L76.7765 12.8589C76.5299 13.1062 76.2371 13.3023 75.9149 13.4361C75.5927 13.5699 75.2473 13.6387 74.8985 13.6387H71.0712C71.9002 12.3523 72.3422 10.8539 72.3443 9.32251V0.328125H64.3766V8.21997C64.3753 8.92645 64.0942 9.60349 63.595 10.1023L60.8411 12.8589C60.5945 13.1062 60.3017 13.3023 59.9794 13.4361C59.6572 13.5699 59.3119 13.6387 58.9631 13.6387H56.4089V0.328125H48.4412V21.6251H60.0633C62.177 21.6251 64.2042 20.7837 65.6991 19.2859L68.3605 16.6194V21.6251H75.9987C78.1124 21.6251 80.1397 20.7837 81.6346 19.2859L85.9425 14.9697C87.4377 13.4723 88.2785 11.4411 88.2798 9.32251V0.328125H80.3121ZM0.634766 8.31447H5.94659V21.6251H13.9143V8.31447H19.2261V0.328125H0.634766V8.31447Z"
        fill="#333333"/>
    </svg>
  )
}
