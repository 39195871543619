import axios from 'axios';
import {BURNER_PAGE_STATE, CURRENT_USER, IS_SECURED, V2_BASE_URL} from '../constants';
import { LOGOUT_SUCCESS } from './verify';
import { hasError } from './error';
import { requesting, RECEIVE_BURNERS_LIST } from './general';
import { extractError } from '../utils/request';
import { removeNulls } from '../utils/general';
import { clearStorage } from '../utils/sessionStorage';
import cookie from 'react-cookie';
import {sampleCreated} from './auth';

export const RECEIVE_PHONE_NUMBERS = 'burner-app/chooseNumber/RECEIVE_PHONE_NUMBERS';
export const CHOSEN_NUMBER = 'burner-app/chooseNumber/CHOSEN_NUMBER';
export const CREATED_BURNER = 'burner-app/chooseNumber/CREATED_SAMPLE';
export const RESET = 'burner-app/chooseNumber/RESET';
export const SET_MAX_RESULTS = 'burner-app/chooseNumber/SET_MAX_RESULTS';
export const ERROR_NUMBER = 'burner-app/chooseNumber/ERROR_NUMBER';
export const CLEAR_ERROR_NUMBER = 'burner-app/chooseNumber/CLEAR_ERROR_NUMBER';
export default function reducer(state = {}, action) {
  const { selectedNumber, type } = action;

  switch (type) {
    case RECEIVE_PHONE_NUMBERS:
      return Object.assign({}, state, {
        numbers: action.numbers,
        areaCode: action.areaCode,
        error: null,
      });
    case CHOSEN_NUMBER:
      return Object.assign({}, state, {
        numbers: null,
        selectedNumber,
      });
    case CREATED_BURNER:
      return Object.assign({}, state, {
        burner: action.burner,
        isCreatedNumber: true,
        selectedNumber: null,
        numbers: null,
        areaCode: null,
      });
    case RESET:
      return Object.assign({}, state, {
        isCreatedNumber: false,
        numbers: null,
        areaCode: null,
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isCreatedNumber: false,
      });
    case ERROR_NUMBER:
      return Object.assign({}, state, {
        selectedNumber: null,
        error: action.error,
      });
    case RECEIVE_BURNERS_LIST: // reset here so we show the first flow
      return Object.assign({}, state, {
        isCreatedNumber: false,
        numbers: null,
        areaCode: null,
        selectedNumber: null,
      });
    case SET_MAX_RESULTS:
      return {
        maxResultsCount: action.maxResultsCount,
      };
    case CLEAR_ERROR_NUMBER:
      return {
        error: null,
      }
    default:
      return state;
  }
}

export function receivePhoneNumbers(numbers, areaCode) {
  return {
    type: RECEIVE_PHONE_NUMBERS,
    numbers,
    areaCode,
  };
}

export function handleError(error) {
  return {
    type: ERROR_NUMBER,
    error,
  };
}

export function clearRequestError() {
  return {
    type: ERROR_NUMBER,
    error: null,
  }
}

/**
 * An action that will dispatch when user has chosen a number.
 */
export function chosenNumber(phoneNumber) {
  return {
    type: CHOSEN_NUMBER,
    selectedNumber: phoneNumber,
  };
}

export function createdBurner(burner) {
  return {
    type: CREATED_BURNER,
    burner,
  };
}

export function clearErrorNumber() {
  return (dispatch) => {
    dispatch(clearRequestError());
  }
}

export function createBurner(authToken, userId, name, phoneNumber, subscriptionId, sampleSku, instanceJwt = null) {
  const defaultSampleSku = !!sampleSku ? sampleSku : 'com.adhoclabs.burner.free_sample';
  const sku = !!subscriptionId ? null : defaultSampleSku; // don't send in sku details if there is a subscription id

  const headers = {
    Authentication: authToken,
    Authorization: `Bearer ${instanceJwt}`,
    'Content-Type': 'application/json',
  };

  return (dispatch) => {
    dispatch(requesting());
    return axios
      .post(
        `${V2_BASE_URL}/user/${userId}/burners`,
        removeNulls({
          name,
          phoneNumber,
          subscriptionId,
          sku,
        }),
        {
          headers,
        }
      )
      .then((resp) => {
        dispatch(createdBurner(resp.data));

        const cookies = cookie.load(CURRENT_USER);

        const updatedObject = { ...cookies, eligibleForPaywall: false };
        cookie.save(CURRENT_USER, JSON.stringify(updatedObject), { path: '/', secure: IS_SECURED, maxAge: 604800 });
        dispatch(sampleCreated());

        // clear info about selected plan
        localStorage.removeItem(BURNER_PAGE_STATE);
        clearStorage();
      })
      .catch((error) => {
        dispatch(hasError(extractError(error), error.status));
      });
  };
}

export function reset() {
  return {
    type: RESET,
  };
}

export function setMaxSearchResults(maxResultsCount) {
  return {
    type: SET_MAX_RESULTS,
    maxResultsCount,
  };
}

function sort(availableNumbers) {
  return availableNumbers.sort((a, b) => {
    if (b.type === 'match' || (b.type === 'overlay' && a.type === 'available')) {
      return 1;
    }
    return -1;
  });
}

export function searchAreaCode(authToken, userId, areaCode) {
  return (dispatch) => {
    dispatch(requesting());
    return axios
      .get(`${V2_BASE_URL}/user/${userId}/phoneNumbers?areaCode=${areaCode}`, {
        headers: {
          Authentication: authToken,
        },
      })
      .then((response) => {
        if (response.data.length === 0) {
          dispatch(hasError('There are no numbers for the area code you selected, please try again.'));
        } else {
          dispatch(receivePhoneNumbers(sort(response.data), areaCode));
        }
      })
      .catch((error) => dispatch(hasError('Whoops! That is not a valid area code.', error.status)));
  };
}
