import React from 'react';

export function PhoneIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.9998 18.1274C11.3112 18.1274 9.64874 17.71 8.16033 16.9125C6.67192 16.1149 5.40367 14.9619 4.46837 13.556L4.44837 13.526C3.32631 11.848 2.72464 9.876 2.71879 7.85738C2.71295 5.83877 3.30319 3.86336 4.41551 2.17885L4.62266 1.85742H9.77694V8.90742H7.59408C7.68582 9.63826 7.94652 10.3377 8.35551 10.9503C8.74189 11.5732 9.28336 12.0851 9.92694 12.436L10.7712 10.406L17.2855 13.1131L15.3041 17.8731L14.9255 17.9446C14.2908 18.0657 13.646 18.127 12.9998 18.1274ZM5.40123 3.28599C4.54027 4.72033 4.10477 6.36984 4.1455 8.04223C4.18622 9.71462 4.70149 11.341 5.63123 12.7317L5.65266 12.7631C6.57935 14.1561 7.88298 15.2568 9.41149 15.9369C10.94 16.6171 12.6304 16.8486 14.2855 16.6046L15.4155 13.8903L11.5427 12.2746L10.7541 14.1746L10.1755 14.0546C10.1027 14.0389 8.40408 13.666 7.15408 11.7246C5.91837 9.80599 6.16266 8.15314 6.17408 8.08171L6.2698 7.47885H8.34551V3.28599H5.40123Z"
        fill="#111111"/>
    </svg>
  )
}
