import React, { Component } from 'react';
import {Box} from '@mui/material';
import Header from './Header';
import Loader from '../Loader';
import ErrorModal from '../modal/ErrorModal';
import Footer from '../oauth/Footer';
import VerifyCodeModal from '../modal/VerifyCodeModal';
import LoggedInHeader from './LoggedInHeader';
import ResendCodeModal from '../modal/ResendCodeModal';

class Container extends Component {
  handleRenderHeader = () => {
    const { isShowDefaultHeader, isShowBar } = this.props;

    if (isShowDefaultHeader) {
      return <Header isShowBar={isShowBar}/>;
    }

    return <LoggedInHeader/>;
  }

  render() {
    const {
      children,
      redirectBackAction,
      classNameWrapper,
      isShowFooter,
      isShowVerifyModal,
      isHideErrorModal,
      isHideHeader,
    } = this.props;

    return (
      <Box className={classNameWrapper}>
        {!isHideHeader && this.handleRenderHeader()}
        <main className="u-height-full">
          {children}
        </main>
        <Loader />
        {!isHideErrorModal && <ErrorModal redirectBackAction={redirectBackAction}/>}
        {isShowFooter && <Footer/>}
        {isShowVerifyModal && <VerifyCodeModal />}
        <ResendCodeModal />
      </Box>
    );
  }
}

Container.defaultProps = {
  isShowFooter: false,
  isShowBar: false,
  classNameWrapper: null,
  isShowDefaultHeader: true,
  isHideErrorModal: false,
}

export default Container;
