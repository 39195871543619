import React, { Component, createRef } from 'react';
import classNames from 'classnames';

import { ChevronArrow } from '../../icons/chevronArrow';

import '!style-loader!css-loader!sass-loader!../../../../styles/select-dropdown.scss';

class CountrySelectComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: this.props.input.value.countryCode || this.props.options[0].countryCode,
      isDropdownOpen: false,
    };

    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.handleSelectOption = this.handleSelectOption.bind(this);
    this.closeDropdown = this.closeDropdown.bind(this);

    this.buttonRef = createRef();
    this.selectContainer = createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleSelectOption(option) {
    this.setState({
      isDropdownOpen: false,
      selectedOption: option.countryCode,
    });

    this.props.input.onChange(option);
    if (this.props.onChange) {
      this.props.onChange(option);
    }
  }

  toggleDropdown(event) {
    event.stopPropagation();
    this.setState((prevState) => ({ isDropdownOpen: !prevState.isDropdownOpen }));
  }

  closeDropdown(event) {
    event.stopPropagation();
    this.setState({ isDropdownOpen: false });
  }

  handleClickOutside(event) {
    if (
      this.selectContainer.current &&
      !this.selectContainer.current.contains(event.target)
    ) {
      this.setState({ isDropdownOpen: false });
    }
  }

  render() {
    const { options } = this.props;
    const { selectedOption, isDropdownOpen } = this.state;
    const selectedOptionLabel = options.find(option => option.countryCode === selectedOption);

    return (
      <div
        className="custom-select"
        ref={this.selectContainer}
      >
        <select
          value={selectedOption}
          style={{ display: 'none' }}
          onChange={this.handleSelectOption}
        >
          {options.map(option => (
            <option key={option.countryCode} value={option.countryCode}>
              +{option.dialCode} {option.name}
            </option>
          ))}
        </select>

        <div
          className={
            classNames(
              'select-selected',
              isDropdownOpen ? 'select-arrow-active' : ''
            )
          }
          onClick={this.toggleDropdown}
        >
          +{selectedOptionLabel.dialCode}
        </div>

        <div className={
          classNames('select-items', isDropdownOpen ? '' : 'select-hide')
        }>
          <button
            ref={this.buttonRef}
            type="button"
            aria-label="close dropdown"
            className="select-button--close"
            onClick={this.closeDropdown}
          >
            <ChevronArrow />
          </button>

          {options.map((option) => (
            <div
              key={option.countryCode}
              onClick={() => this.handleSelectOption(option)}
              className="select-items--option"
            >
              +{option.dialCode} {option.name}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default React.memo(CountrySelectComponent);
