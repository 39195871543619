import React, { Component } from 'react';

import '!style-loader!css-loader!sass-loader!../../../styles/progressive-image.scss'

class ProgressiveBackgroundImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      src: props.lowQualitySrc,
      loading: true,
    };
  }

  componentDidMount() {
    const { highQualitySrc } = this.props;
    const highQualityImage = new Image();
    highQualityImage.src = highQualitySrc;

    highQualityImage.onload = () => {
      this.setState({
        src: highQualitySrc,
        loading: false,
      });
    };
  }

  render() {
    const { alt } = this.props;
    const { src, loading } = this.state;

    return (
      <img
        className="ProgressiveImage--image"
        src={src}
        alt={alt}
        style={{
          filter: loading ? 'blur(20px)' : 'none',
        }}
        width="1440"
        height="1024"
      />
    );
  }
}

export default React.memo(ProgressiveBackgroundImage);
