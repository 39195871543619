import React from 'react';
import classNames from 'classnames';

import { AppStoreBadge } from './AppStoreBadge';
import { PlayMarketBadge } from './PlayMarketBadge';
import { DEVICE_TYPE } from '../../constants';

import '!style-loader!css-loader!sass-loader!../../../styles/download-badge.scss'

function DownloadBadge({ className, deviceType}) {
  const isIosMobile = deviceType === DEVICE_TYPE.ios;
  const isAndroidMobile = deviceType === DEVICE_TYPE.android;
  const isUnknownDevice = !isIosMobile && !isAndroidMobile;

  return (
    <ul
      className={classNames(className)}
    >
      <li className="AppBadge__container">
        <div className="AppBadge__content">
          {(isIosMobile || isUnknownDevice) && (
            <a
              className="AppBadge__item"
              href="https://apps.apple.com/us/app/burner-second-phone-number/id505800761"
            >
              <AppStoreBadge height="100%" width="100%" className="AppBade__cta AppBade__cta--apple"/>
            </a>
          )}

          {(isAndroidMobile || isUnknownDevice) && (
            <a
              className="AppBadge__item"
              href="https://play.google.com/store/apps/details?id=com.adhoclabs.burner&hl=en"
            >
              <PlayMarketBadge height="100%" width="100%" className="AppBade__cta AppBade__cta--google"/>
            </a>
          )}
        </div>
      </li>
    </ul>
  )
}

export default DownloadBadge;
