import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  BURNER_CURRENT_STEP,
  CUSTOM_WEB_EVENT,
  experimentParams,
  FIND_NUMBER,
} from '../constants';
import AreaCodeInput from '../components/subscribe/AreaCodeInput';
import { signupAnalyticEvents } from '../utils/analytics';
import * as chooseActions from '../ducks/chooseNumber';
import * as progressBar from '../ducks/progressBar';
import * as actionCreators from '../ducks/subscribe';
import * as errorActions from '../ducks/error';

import '!style-loader!css-loader!sass-loader!../../styles/subscribe-rebrand.scss';
import '!style-loader!css-loader!sass-loader!../../styles/number-picker.scss';
import Container from '../components/common/Container';
import { clearStorage } from '../utils/sessionStorage';
import withRegistrationCheck from '../components/WithRegistrationCheck';

const propTypes = {
  location: PropTypes.object,
  resetSearch: PropTypes.func.isRequired,
  setMaxSearchResults: PropTypes.func.isRequired,
};

/**
 * Subscribe page that looks like the home page.
 */
class ChooseAreaCode extends Component {
  constructor(props) {
    super(props);

    this.areaCodeChanged = this.areaCodeChanged.bind(this);
    this.findNumbers = this.findNumbers.bind(this);
  }

  componentDidMount() {
    const { location, setMaxSearchResults, currentStep, setCurrentStep } = this.props;
    const query = location?.query;
    const results = query?.results;

    const userSignupStep = localStorage.getItem(BURNER_CURRENT_STEP);
    if (currentStep > 1) {
      setCurrentStep(1);
    }

    if (!userSignupStep) {
      clearStorage();
      sessionStorage.removeItem('tab_is_change')
    }

    signupAnalyticEvents();
    $(document).foundation();

    if (results) {
      setMaxSearchResults(parseInt(results, 10));
    }
  }

  async findNumbers(areaCode) {
    const { requestNumber, setTotalSteps } = this.props;
    const { router } = this.context;

    dataLayer.push({
      event: CUSTOM_WEB_EVENT,
      customWebEventName: FIND_NUMBER,
      customWebEventAction: areaCode,
    });

    await requestNumber(areaCode);

    if (!this.props.error) {
      router.push(`/areacode/${areaCode}`);

      setTotalSteps(4);
    }
  }

  areaCodeChanged(areaCode) {
    const { location, resetSearch } = this.props;

    const queryParams = location && { ...location.query };
    let newParams;

    /* Handle query parameters passed in for experiments */
    for (const param in queryParams) {
      if (queryParams.hasOwnProperty(param)) {
        if (experimentParams.indexOf(param) > -1) {
          newParams = newParams
            ? `${newParams}&${param}=${queryParams[param]}`
            : `?${param}=${queryParams[param]}`;
        }
      }
    }
    resetSearch();
    this.findNumbers(areaCode);
  }

  render() {
    return (
      <Container isShowBar classNameWrapper="u-height-full" >
        <div className="Main__container Main__container--minHeight Main__container--secondary grid-y gxrid-padding-x align-center-middle">
          <h1 className="Header__title" id="search-page-headline">
            Choose your area code
          </h1>
          <h2 className="Header__subtitle PickNumber__subscribe--subtitle h5">
            Enter a US area code to create number
          </h2>
          <AreaCodeInput
            template="SubscribePage"
            onAreaCodeEnter={this.areaCodeChanged}
          />
        </div>
      </Container>
    );
  }
}

ChooseAreaCode.propTypes = propTypes;
ChooseAreaCode.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    error: state.chooseNumberPage.error,
    currentStep: state.progressBar.currentStep,
    isShowBar: state.progressBar.isShowBar,
  };
}

const mapDispatchToProps = { ...actionCreators, ...errorActions, ...chooseActions, ...progressBar };

export default connect(mapStateToProps, mapDispatchToProps)(withRegistrationCheck(ChooseAreaCode));
