import React from 'react';

export default function PhoneReview({className}) {
  return (
    <svg width="407" height="363" viewBox="0 0 407 363" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <g opacity="0.1">
        <path
          d="M219.591 117.646H267.731L272.365 156.261L335.178 117.646H380.744V25.4858H219.591V117.646ZM251.77 198.738L245.077 143.132H194.105V0H406.23V143.132H342.386L251.77 198.738Z"
          fill="#FFFFE1" fillOpacity="0.6"/>
        <path
          d="M55.8638 25.4858C14.4172 93.1904 15.7042 178.658 60.24 245.075L60.7549 245.848C105.033 312.265 183.55 346.504 262.067 334.404L289.869 267.729L195.649 228.6C195.649 228.6 179.174 267.729 177.629 272.363C168.876 272.106 128.46 261.809 100.914 218.817C73.6266 176.341 79.0325 135.667 80.8345 127.944C97.0528 127.944 128.46 127.944 128.46 127.944V25.7432L55.8638 25.4858ZM229.888 362.207C154.203 362.207 82.3793 324.622 39.1307 260.006L38.6158 259.234C-12.6132 182.262 -12.8706 82.8932 37.8435 5.66351L41.9624 0H153.688V152.915H104.003C104.776 164.242 108.38 183.034 122.281 204.659C136.183 226.283 152.401 236.58 162.698 241.214L182.006 194.876L323.336 253.571L280.602 356.544L273.909 357.831C258.72 360.92 244.304 362.207 229.888 362.207Z"
          fill="#FFFFE1" fillOpacity="0.6"/>
      </g>
    </svg>
  )
}
