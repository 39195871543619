import React from 'react';
function AppStoreIcon() {
  return (
    <svg
      className="app__icon"
      height="81.9"
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 256 256"
      width="81.9"
      xmlns="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/1999/xlink"
    >
      <linearGradient id="a" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0" stopColor="#17c9fb"/>
        <stop offset="1" stopColor="#1a74e8"/>
      </linearGradient>
      <path
        d="m56.0638473 0h143.8723057c30.963208 0 56.063847 25.1006394 56.063847 56.0638473v143.8723057c0 30.963208-25.100639 56.063847-56.063847 56.063847h-143.8723057c-30.9632079 0-56.0638473-25.100639-56.0638473-56.063847v-143.8723057c0-30.9632079 25.1006394-56.0638473 56.0638473-56.0638473z"
        fill="url(#a)"/>
      <path
        d="m82.041678 185.810289.0240687.0076-8.7526543 15.160002c-3.1948063 5.533779-10.2712641 7.429888-15.8050435 4.234828-5.533526-3.194553-7.4296347-10.27101-4.2348284-15.80479l6.4478841-11.167887.6186929-1.071692c1.1051344-1.588789 3.8322473-4.329583 9.2872333-3.814512 0 0 12.8367366 1.393199 13.7655359 8.065049.0002534 0 .1269309 2.195574-1.3508887 4.391402zm124.143432-38.721002h-27.294185c-1.858612-.124397-2.670615-.788187-2.989082-1.174046l-.020269-.035217-29.217656-50.6061362-.03775.0253355-1.752456-2.5125213c-2.871525-4.3914018-7.431661 6.8405844-7.431661 6.8405844-5.444599 12.5154826.772479 26.7451646 2.940437 31.0458656l40.581387 70.289031c3.194553 5.533779 10.271011 7.429888 15.80479 4.234828 5.533526-3.194553 7.429635-10.27101 4.234829-15.80479l-10.147881-17.576248c-.19635-.425636-.538632-1.582202 1.542425-1.586762h13.787072c6.389865 0 11.569961-5.180096 11.569961-11.569962s-5.180096-11.569962-11.569961-11.569962zm-53.014022 15.729037s1.456791 7.410633-4.180358 7.410633h-5.637148v.000254h-95.2617241c-6.3898659 0-11.5699617-5.180096-11.5699617-11.569962s5.1800958-11.569962 11.5699617-11.569962h25.9400025c4.1879578-.242714 5.1808559-2.660227 5.1808559-2.660227l.022802.011655 33.8603857-58.6481304-.010134-.0020269c.617426-1.1330034.103369-2.2034282.013934-2.3729227l-11.182328-19.3682278c-3.1950597-5.5337794-1.298951-12.6099839 4.234828-15.8047902 5.53378-3.1950596 12.609731-1.2989509 15.80479 4.2348285l5.186177 8.982954 5.177055-8.9669927c3.194807-5.5337794 10.271011-7.429888 15.80479-4.2348284 5.53378 3.1950596 7.429888 10.2712641 4.234829 15.8047901l-47.117692 81.6099445c-.205977.496829-.269063 1.276656 1.263735 1.413974h28.164206l.00608.274384s16.278057.253355 18.494914 15.454653z"
        fill="#fff"/>
    </svg>
  )
}
export default AppStoreIcon;
