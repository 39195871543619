import React from 'react';

export function CnetIcon() {
  return (
    <svg width="66" height="49" viewBox="0 0 66 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_8187_7853)">
        <path
          d="M62.9732 34.1155C63.8112 34.1155 65.4489 34.1155 65.4489 34.1155V37.1015C65.4489 37.1015 62.2496 37.1015 61.6021 37.1015C60.3452 37.1015 59.1264 36.489 59.4692 36.6421C57.6791 35.8382 56.9936 33.8858 56.8412 31.7037V6.70526H60.8784V12.9453V12.9836H64.7252V15.893H60.8784V15.9313V32.01C60.9546 33.6178 61.9068 34.1155 62.9732 34.1155ZM40.3876 31.8186V18.1134C40.2734 10.3804 53.8323 10.3421 53.6038 18.3048V24.8894C53.6038 25.3105 53.261 25.6168 52.8802 25.6168H44.4248V32.01C44.4629 35.0343 49.6428 34.996 49.6428 32.01V28.9474H53.6419V31.8186C53.8323 39.743 40.2734 39.5516 40.3876 31.8186ZM49.5666 22.5925V18.1134C49.5666 15.1274 44.4248 15.1274 44.3487 18.1134V22.5925H49.5666ZM32.5798 17.7306C32.5798 14.936 27.857 15.0126 27.7808 17.8455V37.1015H21.9535V34.1155H23.8197V15.9696H21.6107V13.0219H27.2476V15.204C27.2476 15.204 27.9332 14.1703 29.7232 13.175C32.0085 11.9117 34.2556 13.175 33.9128 13.0219C35.7029 13.8258 36.3885 15.7782 36.5408 17.9603V34.1538H39.0927V37.1015H32.6179L32.5798 17.7306ZM16.6213 0.962891H19.5159V48.4714H16.6213V0.962891ZM0.738999 31.8186V18.1134C0.624738 10.3804 14.1837 10.3421 13.9552 18.3048V20.6784H9.99415L9.95607 18.1134C9.95607 15.1657 4.8524 15.2422 4.73814 18.2283V32.01C4.77623 35.0343 9.95607 34.996 9.95607 32.01V28.9474H13.9552V31.8186C14.1837 39.743 0.624738 39.5516 0.738999 31.8186Z"
          fill="#333333"/>
      </g>
      <defs>
        <clipPath id="clip0_8187_7853">
          <rect width="64.7099" height="47.5085" fill="white" transform="translate(0.740234 0.962891)"/>
        </clipPath>
      </defs>
    </svg>
  )
}
