import React, {Fragment} from 'react';

export function TimeIcon() {
  return (
    <Fragment>
      <svg
        width="77"
        height="23"
        viewBox="0 0 77 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="show-for-large hide-for-small"
      >
        <path
          d="M0.910156 0.556959H20.2986V4.98017H19.9463L19.9873 2.14604H12.3286V22.0996H14.5402V22.7549H6.97159V22.0996H9.01937V2.14604H1.27876V4.98017H0.910156V0.556959ZM21.2241 0.556959H28.8419V1.17129H26.6712V22.1078H28.8337V22.7631H21.216V22.1078H23.4276V1.1631H21.216V0.556959H21.2241ZM35.5586 0.507812H37.0166L44.1429 17.6273L51.2201 0.507812H52.6371L56.6098 22.0996H58.6085V22.7549H50.745V22.0996H52.7682L50.3108 8.29757L44.0201 22.7713H42.5457L36.3204 8.30576L33.6992 22.116H35.6078L35.5668 22.7713H29.7757L29.8166 22.116H31.5859L35.5586 0.507812ZM59.2719 0.556959H76.0392L76.0801 4.93921H75.7771L75.6378 2.23614L64.6617 2.11327V10.8778H73.9996L74.2207 8.83H74.6303C74.532 10.6976 74.532 12.516 74.6303 14.3672H74.2207L73.9996 12.2539H64.6617V21.3133H76.0474L76.0801 18.2989H76.3914V22.7631H59.2719V22.1406L61.4835 22.1078V1.21225L59.2719 1.17129V0.56515V0.556959Z"
          fill="#333333"/>
      </svg>
      <svg
        width="56"
        height="16"
        viewBox="0 0 56 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="hide-for-large show-for-small"
      >
        <path
          d="M0 0.0353201H14.3844V3.21413H14.1231L14.1534 1.17734H8.47141V15.5173H10.1122V15.9882H4.49702V15.5173H6.01628V1.17734H0.273467V3.21413H0V0.0353201ZM15.0711 0.0353201H20.7227V0.476821H19.1123V15.5232H20.7167V15.9941H15.065V15.5232H16.7058V0.470934H15.065V0.0353201H15.0711ZM25.7059 0H26.7876L32.0747 12.3032L37.3252 0H38.3766L41.3239 15.5173H42.8067V15.9882H36.9728V15.5173H38.4738L36.6507 5.59823L31.9835 16H30.8896L26.2711 5.60412L24.3264 15.5291H25.7424L25.712 16H21.4155L21.4459 15.5291H22.7585L25.7059 0ZM43.299 0.0353201H55.7387L55.7691 3.18469H55.5442L55.4409 1.24209L47.2977 1.15379V7.45254H54.2255L54.3896 5.98087H54.6934C54.6205 7.32303 54.6205 8.62987 54.6934 9.96026H54.3896L54.2255 8.4415H47.2977V14.9522H55.7448L55.7691 12.7859H56V15.9941H43.299V15.5467L44.9398 15.5232V0.506255L43.299 0.476821V0.0412067V0.0353201Z"
          fill="#111111"/>
      </svg>
    </Fragment>

  )
}
