import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import PhoneInput from '../form/input/PhoneInput';
import Container from '../common/Container';
import CountrySelectComponent from '../form/select/CountrySelectComponent';
import { BackwardArrow } from '../icons/backwardArrow';
import Button from '../form/button/Button';

import {AU, AVAILABLE_COUNTRIES, NUMBER_FORMAT, VERIFY_SENT} from '../../constants';
import { validatePhoneWithCountryCode } from '../../utils/validation';
import { removeStorageData, setStoragePhone } from '../../utils/sessionStorage';
import {trackAmplitudeFailedEvent} from '../../utils/analytics';

class PhoneNumberContent extends Component {
  constructor() {
    super();

    this.state = {
      isShowError: false,
    }

    this.handleBack = this.handleBack.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmitHandler = this.handleSubmitHandler.bind(this);
    this.handleActivateValidation = this.handleActivateValidation.bind(this);
    this.handleTrackFailureEvent = this.handleTrackFailureEvent.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  componentDidMount() {
    if (this.props.serverError) {
      this.handleActivateValidation();
    }

    removeStorageData(VERIFY_SENT);
  }

  handleBack() {
    window.history.back();
  }

  handleSubmitHandler() {
    this.handleActivateValidation();

    const { formState: { values }, change, submitHandler } = this.props;
    const { phoneNumber, selectedCountry } = values;

    const phone = parsePhoneNumberFromString(
      phoneNumber,
      selectedCountry.countryCode
    );

    setStoragePhone(phone.number);
    change('phoneNumber', phone.number);

    submitHandler();
  }

  handleActivateValidation() {
    this.setState({
      isShowError: true,
    });
  }

  handleChange() {
    const { handleClearServerError } = this.props;
    handleClearServerError();

    this.setState({
      isShowError: false,
    });
  }

  handleBlur() {
    const { isShowError } = this.state;

    if (!isShowError) {
      this.handleActivateValidation();
    }

    this.handleTrackFailureEvent();
  }

  handleTrackFailureEvent() {
    const { invalid } = this.props;

    if (invalid) {
      trackAmplitudeFailedEvent('invalid phone - signup', 'web app');
    }
  }

  renderError = () => {
    const { serverError, formState, submitFailed, dirty } = this.props;
    const { isShowError } = this.state;

    const errorToShow = serverError || formState?.syncErrors?.phoneNumber;
    const isError = (isShowError && errorToShow) || (submitFailed && !dirty);

    return (
      isError && (
        <div className="PhoneNumber__error text-error">
          <Field
            name="phoneNumber"
            component={({ meta: { error: errorProps } }) => (
              <span>{errorToShow || errorProps}</span>
            )}
          />
        </div>
      )
    );
  };

  render() {
    const {
      handleCountryChange, selectedCountry,
      handleSubmit, isShowBar,
    } = this.props;

    return (
      <Container
        isShowBar={isShowBar}
        classNameWrapper="u-height-full PhoneNumber__container--wrapper"
        isHideErrorModal
      >
        <section className="PhoneNumber__container Main__container Main__container--secondary grid-x gxrid-padding-x grid-x align-center-middle">
          <div className="PhoneNumber__content medium-10 small-12 large-11">
            <button
              className="PhoneNumber__backButton show-for-medium hide-for-small"
              onClick={this.handleBack}
              type="button"
              aria-label="Back button"
            >
              <BackwardArrow/>
            </button>
            <div className="PhoneNumber__column small-10 medium-7 text-center">
              <div className="PhoneNumber__header grid-x">
                <button
                  className="PhoneNumber__backButton--mobile show-for-small hide-for-medium" onClick={this.handleBack}
                  type="button"
                  aria-label="Back button"
                >
                  <BackwardArrow/>
                </button>
                <h1 className={'Header__title PhoneNumber__title'}>
                  Enter phone number
                </h1>
              </div>
              <h2 className={
                'Header__subtitle PhoneNumber__subtitle show-for-medium hide-for-small'
              }>
                {'Burner will send an SMS verification code to your phone. \nCarrier rates may apply.'}
              </h2>
              <form
                className={'PhoneNumber__field--wrapper '}
                onSubmit={handleSubmit(this.handleSubmitHandler)}
                id="contact-form"
              >
                {this.renderError()}

                <div className="PhoneNumber__field">
                  <Field
                    name="selectedCountry"
                    component={CountrySelectComponent}
                    options={AVAILABLE_COUNTRIES}
                    onChange={handleCountryChange}
                  />
                  <Field
                    name="phoneNumber"
                    component={PhoneInput}
                    className="PhoneNumber__field--input"
                    placeholder={'XXX-XXX-XXXX'}
                    numberFormat={selectedCountry?.countryCode === AU ? NUMBER_FORMAT.AU : NUMBER_FORMAT.US}
                    id="phoneNumber"
                    type="tel"
                    handleChange={this.handleChange}
                    handleBlurField={this.handleBlur}
                    country={selectedCountry.countryCode}
                  />
                </div>
                <h3 className="PhoneNumber__subtitle--mobile show-for-small hide-for-medium">
                  {'Burner will send an SMS verification code to your phone. \nCarrier rates may apply.'}
                </h3>
                <div className="Form__submitWrapper">
                  <Button
                    onClick={this.handleTrackFailureEvent}
                    type="submit"
                    withArrow
                    className="PhoneNumber__submit Form__submit--shadow button button--withArrow u-width-full"
                    text="Continue"
                  />
                </div>
              </form>
            </div>
          </div>
        </section>
      </Container>
    );
  }
}

PhoneNumberContent = reduxForm({
  form: 'subscribe',
  validate: validatePhoneWithCountryCode,
  initialValues: {
    phoneNumber: null,
    selectedCountry: AVAILABLE_COUNTRIES[0],
  },
  destroyOnUnmount: false,
})(PhoneNumberContent)

const mapStateToProps = function mapStateToProps(state) {
  return {
    formState: state.form.subscribe,
    error: state.error,
  }
}
export default connect(mapStateToProps)(React.memo(PhoneNumberContent));
