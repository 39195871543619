import moment from 'moment';

export function toHumanDate(epoch) {
  return moment(epoch).format('LL');
}

export function getDiffBetweenDays(dateCreate, dateUpdate) {
  const createDate = moment(
    dateCreate);
  const updateDate = moment(
    dateUpdate);

  return updateDate.diff(createDate, 'days');
}
