import React from 'react';
import { Checkbox } from '@mui/material';
import classNames from 'classnames';
import { bool, func, number, shape, string, arrayOf } from 'prop-types';

import MarkIcon from '../icons/paywall/markIcon';

class SubscriptionCheckbox extends React.Component {
  render() {
    const { planOptions, value, onChange, isFreeTrial } = this.props;

    return planOptions.map(plan => {
      const price = plan.numLines === 1 ? Number(plan.price) : Math.floor(Number(plan.price) / 3 * 100) / 100;
      const isChecked = value === plan.numLines;
      const isMostPopular = (plan.numLines === 1 && !plan.isYearly && isFreeTrial)
        || (!isFreeTrial && plan.numLines === 1);

      return (
        <label className="u-relative">
          <div className={classNames('Paywall__subscriptions--plan grid-y', isChecked && 'active')}>
            {isMostPopular && <span className="Paywall__plan--popular">Most Popular</span>}
            <div className="grid-x Paywall__grid">
              <div className="Paywall__plan--container grid-x align-justify">
                <h3
                  className={classNames('Paywall__plan--title', !plan.isYearly && 'u-width-full')}>{plan.numLines} number
                  plan</h3>
                <p className="Paywall__plan--description">Billed at
                  ${Number(plan.price)}/{plan.isYearly ? 'year' : 'month'}</p>
              </div>
              <p className="Paywall__plan--subtitle grid-y">
                ${price}/number
                {plan.isYearly && <span>per month</span>}
              </p>
            </div>
          </div>
          <div>
            <Checkbox
              value={plan.numLines}
              sx={{'& .MuiSvgIcon-root': {fontSize: 24}}}
              checked={isChecked}
              onChange={onChange}
              checkedIcon={
                <MarkIcon/>
              }
            />
          </div>
        </label>
      )
    })
  }
}

SubscriptionCheckbox.propTypes = {
  value: string.isRequired,
  onChange: func.isRequired,
  planOptions: arrayOf(
    shape({
      price: string.isRequired,
      isYearly: bool.isRequired,
      numLines: number.isRequired,
    })
  ).isRequired,
  isFreeTrial: bool.isRequired,
}

export default SubscriptionCheckbox;
