import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as progressBar from '../../ducks/progressBar';
import * as subscribeActions from '../../ducks/subscribe';
import * as stripeSubscribeActions from '../../ducks/stripe-subscribe';

import SubscriptionCheckbox from '../../components/paywall/SubscriptionCheckbox';
import Button from '../form/button/Button';
import { getCurrentUser } from '../../utils/user';
import {CUSTOM_WEB_EVENT, ROUTERS, SUBSCRIPTION_PURCHASE_START, subscriptionSkuWithPrice} from '../../constants';
import { addStorageSubscription, clearStorage } from '../../utils/sessionStorage';
import SubscriptionCheckboxV2 from './SubscriptionCheckboxV2';
import ScrollButton from './ScrollButton';

import '!style-loader!css-loader!sass-loader!../../../styles/paywall.scss';

class PaywallBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: subscriptionSkuWithPrice.default.oneLineMonthly.sku,
      selectedPlan: subscriptionSkuWithPrice.default.oneLineMonthly,
      isYearly: false,
    };

    this.renderFreeTrialHeader = this.renderFreeTrialHeader.bind(this);
    this.handleLabelClick = this.handleLabelClick.bind(this);
    this.handleRedirectToCheckout = this.handleRedirectToCheckout.bind(this)
  }

  componentDidMount() {
    const { currentStep, setCurrentStep } = this.props;
    const { router } = this.context;
    const storageUser = getCurrentUser();

    if (currentStep < 4) {
      setCurrentStep(4);
    }

    // check if user pass the previous steps and verify the phone number
    if (!storageUser) {
      clearStorage();
      router.push(ROUTERS.homePage)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.error.isUnauthorized !== this.props.error.isUnauthorized) {
      clearStorage();

      const { router } = this.context;
      router.push(ROUTERS.homePage)
    }
  }

  handleDurationChange = () => {
    this.setState(prev => ({
      isYearly: !prev.isYearly,
    }));
  };

  handleSubscriptionChange = (plan) => {
    this.setState({
      selectedOption: plan.sku,
      selectedPlan: plan,
    });
  };

  handleLabelClick(e) {
    const { innerText } = e.target;

    this.setState({
      isYearly: innerText === 'Yearly',
    })
  }

  handleRedirectToCheckout() {
    const { selectSubscriptionPlan, getStripeSession } = this.props;
    const { selectedPlan, selectedOption } = this.state;

    getStripeSession(selectedOption);

    addStorageSubscription(selectedPlan);
    selectSubscriptionPlan(selectedPlan);

    dataLayer.push({
      event: CUSTOM_WEB_EVENT,
      customWebEventName: SUBSCRIPTION_PURCHASE_START,
      customWebEventAction: selectedPlan.sku,
      customWebEventLabel: selectedPlan.price,
    });
    localStorage.setItem('payment_status', 'clear');
  }

  renderSubscriptionOptions() {
    const { selectedOption, isYearly } = this.state;
    const { isFreeTrial } = this.props;

    const activePlan = subscriptionSkuWithPrice[isFreeTrial ? 'offerset1w' : 'default'];
    const yearlySubscriptions = Object.values(activePlan).filter(sub => sub.isYearly);
    const monthlySubscriptions = Object.values(activePlan).filter(sub => !sub.isYearly);

    return (
      <section className="Paywall__subscriptions--container grid-y">
        <SubscriptionCheckbox
          isFreeTrial={isFreeTrial}
          value={selectedOption}
          planOptions={isYearly ? yearlySubscriptions : monthlySubscriptions}
          onChange={this.handleSubscriptionChange}
        />
      </section>
    );
  }

  renderSubscriptionOptionsV2() {
    const { selectedOption } = this.state;

    const activePlan = subscriptionSkuWithPrice.default;

    return (
      <div className="Paywall__subscriptions--container Paywall__subscriptions--container-v2 grid-y">
        <SubscriptionCheckboxV2
          value={selectedOption}
          onChange={this.handleSubscriptionChange}
          planOptions={activePlan}
        />
      </div>
    );
  }

  renderFreeTrialHeader() {
    const { isFreeTrial } = this.props;

    if (isFreeTrial) {
      return (
        <Fragment>
          <h1 className="Header__title Paywall__title">Start your free week</h1>
          <h2 className="Header__subtitle h5 PickNumber__subscribe--title">
            Get unlimited calls, texts and pics with your new number.{'\n'}
            <strong>7 days free</strong> included!
          </h2>
        </Fragment>
      )
    }

    return (
      <Fragment>
        <h1 className="Header__title Paywall__title">Choose your plan</h1>
        <h2 className="Header__subtitle h5 PickNumber__subscribe--title">
          Get unlimited calls, texts and pics for your new number.{'\n'}Plus call spam blocking!
        </h2>
      </Fragment>
    )
  }

  render() {
    const { error } = this.props;

    return (
      <section
        className="Paywall__container Main__container Main__container--minHeight Main__container--secondary grid-y grid-padding-x align-center-middle">
        <div className="Paywall__container-main grid-y grid-padding-x align-center-middle">
        {this.renderFreeTrialHeader()}
        {this.renderSubscriptionOptionsV2()}

        {/* TODO: uncomment next line of code when the redesign will be ready to integrate*/}
        {/*<SwitchButton
          handleLabelClick={this.handleLabelClick}
          isActiveState={isYearly}
          labelLeft="Monthly"
          labelRight="Yearly"
          handleChange={this.handleDurationChange}
          classNameWrapper="Paywall__switch"
        />*/}

        <p className="Paywall__subscriptions--footer-v2 medium text-center">
          No commitment. Cancel anytime.
        </p>

        <Button
          text="Continue"
          type="button"
          withArrow
          className="Paywall__cta Paywall__cta-v2"
          onClick={this.handleRedirectToCheckout}
        />

        {error?.errorMessage && <span className="text-error">
          {error.errorMessage}
        </span>}
        </div>
        <ScrollButton className={'Paywall_scroll'}/>
      </section>
    );
  }
}

PaywallBanner.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    selectedNumber: state.subscribePage.selectedNumber,
    currentStep: state.progressBar.currentStep,
    isFreeTrial: state.subscribePage.isFreeTrial,
    error: state.error,
    clientSecretKey: state.stripeSubscribe.clientSecretKey,
  };
}
const mapDispatchToProps = {
  ...progressBar,
  ...subscribeActions,
  ...stripeSubscribeActions,
}
export default connect(mapStateToProps, mapDispatchToProps)(PaywallBanner);
