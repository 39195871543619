import React from 'react';

export default function ReplyTextIcon() {
  return (
    <svg width="80" height="72" viewBox="0 0 80 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.01758 47.6214H23.9097L26.0529 65.3033L54.9199 47.6214H75.6157V4.01953H4.01758V47.6214ZM50.9013 16.0084V9.3107H71.3292V29.7386H64.6315V20.6967L48.4232 36.9051L43.6678 32.1498L59.8762 15.9414H50.9013V16.0084Z"
        fill="#111111"/>
      <path
        d="M0 0V51.6391H20.294L22.7721 72L55.9926 51.6391H79.6354V0H0ZM75.6168 47.6205H54.9209L26.054 65.3023L23.9107 47.6205H4.0186V4.0186H75.6168V47.6205Z"
        fill="#111111"/>
    </svg>
  )
}
