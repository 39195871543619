import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import {ROUTERS} from '../../constants';

const BurnerAppQrCode = require('images/qr-code-burner-app-download.png');
const Tile01 = require('images/tiles/tile-01.jpeg');

const propTypes = {
  subscriptions: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string,
      price: PropTypes.number,
      store: PropTypes.string,
      burnersAssignedInPeriod: PropTypes.number,
      id: PropTypes.string,
      receipt: PropTypes.string,
      renewalDate: PropTypes.number,
      creationDate: PropTypes.number,
      burnerIds: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  burners: PropTypes.arrayOf(
    PropTypes.shape({
      callerIdEnabled: PropTypes.bool,
      remainingTexts: PropTypes.number,
      name: PropTypes.string,
      expirationDate: PropTypes.number,
      phoneNumberId: PropTypes.string,
      hexColor: PropTypes.number,
      features: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          featureName: PropTypes.string,
        })
      ),
      expirationWarning: PropTypes.shape({
        id: PropTypes.number,
      }),
      alias: PropTypes.string,
      version: PropTypes.number,
      id: PropTypes.string,
      autoReplyActive: PropTypes.bool,
      dateCreated: PropTypes.number,
      lastUpdatedDate: PropTypes.number,
      renewalDate: PropTypes.number,
      ringer: PropTypes.bool,
      autoReplyType: PropTypes.number,
      remainingMinutes: PropTypes.number,
      productId: PropTypes.string,
      userId: PropTypes.string,
      autoReplyText: PropTypes.string,
      totalMinutes: PropTypes.number,
      notifications: PropTypes.bool,
      useSip: PropTypes.bool,
      totalTexts: PropTypes.number,
    })
  ),
};

const defaultProps = {
  burners: null,
  subscriptions: null,
};

function UpgradeBox({ subscriptions = null }) {
  if (subscriptions && subscriptions[0]) {
    return null;
  }

  return (
    <div className="TileContainer__wrapper grid-container full">
      <div className="grid-x align-center-top">
        <div className="cell large-7">
          <div className="cell large-7 small-10">
            <div className="grid-x grid-padding-x align-center-middle u-padding-y-10vh u-bg-solid--brand">
              <div className="cell large-7 large-offset-4 small-11">
                <h2>Upgrade</h2>
                <p className="u-margin-top-20px">
                  Get a subscription for unlimited calls and texts and a line
                  that never expires.
                </p>
                <Link
                  to={ROUTERS.chooseNumber}
                  className="button button--black u-margin-top-20px"
                >
                  Subscribe
                </Link>
              </div>
            </div>
            <div className="grid-x grid-padding-x align-center-middle u-padding-y-5vh">
              <div className="cell large-7 large-offset-4 small-11">
                <div className="grid-x align-middle">
                  <div className="cell large-4 small-4">
                    <img src={BurnerAppQrCode} alt="Scan to download Burner" />
                  </div>
                  <div className="cell large-6 small-offset-1 small-7">
                    <h5 className="Cta--bold">Scan to download Burner</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cell large-5">
          <div className="grid-x align-right-middle">
            <div className="cell small-12">
              <img src={Tile01} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

UpgradeBox.propTypes = propTypes;
UpgradeBox.defaultProps = defaultProps;

export default UpgradeBox;
