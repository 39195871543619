import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { routerReducer } from 'react-router-redux';
import auth from '../ducks/auth';
import confirm from '../ducks/confirm';
import burnerDetailsPage from '../ducks/burnerDetails';
import applicationsPage from '../ducks/applications';
import chooseNumberPage from '../ducks/chooseNumber';
import dashboardPage from '../ducks/dashboard';
import developerPage from '../ducks/developer';
import oauthPage from '../ducks/oauth';
import signupPage from '../ducks/signup';
import subscribePage from '../ducks/subscribe';
import verifyPage from '../ducks/verify';
import error from '../ducks/error';
import loading from '../ducks/loading';
import successModal from '../ducks/successModal';
import sitebuilder from '../ducks/sitebuilder';
import instance from '../ducks/instance';
import progressBar from '../ducks/progressBar';
import stripeSubscribe from '../ducks/stripe-subscribe';

export default combineReducers({
  auth,
  signupPage,
  verifyPage,
  chooseNumberPage,
  dashboardPage,
  subscribePage,
  burnerDetailsPage,
  developerPage,
  oauthPage,
  applicationsPage,
  error,
  loading,
  confirm,
  successModal,
  sitebuilder,
  instance,
  progressBar,
  stripeSubscribe,
  routing: routerReducer,
  form: formReducer,
});
