export function getAnchorValue() {
  const hash = window.location.hash;
  if (hash) {
    return hash.split('#')[1];
  }
  return null;
}

export function getParamsByName(name) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  return urlParams.get(name);
}

export function hideClientSecret() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  urlParams.delete('session_id');
  const newUrl = `${window.location.pathname}${urlParams.toString() ? `?${urlParams.toString()}` : ''}`;
  window.history.replaceState(null, '', newUrl);
}
