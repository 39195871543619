import React from 'react';

export function ChevronArrow() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.68385 9.84661H7.22785L2.40956 5.02832L1.60156 5.83632L6.75471 10.9895H9.15699L14.3101 5.83632L13.5021 5.02832L8.68385 9.84661Z"
        fill="#111111"/>
    </svg>
  )
}
