import React, { Component } from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import DownloadBadge from '../components/confirmation/DownloadBadge';
import { QrCode } from '../components/confirmation/QrCode';
import { BURNER_CURRENT_STEP, BURNER_PAGE_STATE, ROUTERS } from '../constants';
import { handleAuthCheck, userDeviceType} from '../utils/user';
import { getParamsByName, hideClientSecret } from '../utils/query';
import { renderLocalNumber } from '../utils/phone';
import Loader from '../components/Loader';

import * as subscribeActions from '../ducks/subscribe';
import * as generalActions from '../ducks/general';
import * as stripeSubscribeActions from '../ducks/stripe-subscribe';
import * as dashboardActions from '../ducks/dashboard';
import * as errorActions from '../ducks/error';


import '!style-loader!css-loader!sass-loader!../../styles/burner-details-rebrand.scss';
import {checkUserAuth, getStoragePlanData} from '../utils/sessionStorage';
import {clearUserInfo} from '../ducks/general';

class ConfirmationPage extends Component {
  constructor() {
    super();
    this.state = {
      deviceType: null,
    };

    this.handleRedirectUser = this.handleRedirectUser.bind(this);
    this.initializePage = this.initializePage.bind(this);
  }

  componentDidMount() {
    this.initializePage();

    localStorage.removeItem(BURNER_CURRENT_STEP);
    localStorage.removeItem(BURNER_PAGE_STATE);

    document.addEventListener('visibilitychange', this.handleAuthenticateCheck);
  }

  componentDidUpdate() {
    const { paymentError, error, clearErrors } = this.props;
    if (paymentError) {
      return this.context.router.push(ROUTERS.paywall)
    }

    if (error?.errorMessage) {
      clearUserInfo();
      clearErrors();
      return this.context.router.push(ROUTERS.homePage)
    }
  }

  componentWillUnmount() {
    document.removeEventListener('visibilitychange', this.handleAuthenticateCheck);
  }

  setDeviceTypeBasedOnScreenSize() {
    const userAgent = navigator.userAgent;

    if (!this.state.deviceType && window.innerWidth < 753) {
      this.setState({ deviceType: userDeviceType(userAgent) });
    }
  }

  handleAuthenticateCheck = () => {
    const { router } = this.context;
    const { userIsAuthentificate } = this.props;

    handleAuthCheck(router, userIsAuthentificate);
  }

  handleRedirectUser() {
    const { router } = this.context;
    const { setStripeSubscription, selectedNumber, user } = this.props;
    const sessionId = getParamsByName('session_id');
    const sku = getStoragePlanData('sku');

    checkUserAuth(router);

    if (!user) {
      return router.push(ROUTERS.homePage);
    }

    if (!sku && user && !user.eligibleForPaywall) {
      return router.push(ROUTERS.dashboard);
    }

    if (!sessionId) {
      return router.push(user?.eligibleForPaywall ? ROUTERS.paywall : ROUTERS.dashboard);
    }

    setStripeSubscription(sku, sessionId, selectedNumber);
    hideClientSecret();
  }

  initializePage() {
    this.handleRedirectUser();
    this.setDeviceTypeBasedOnScreenSize();
  }

  render() {
    const { deviceType } = this.state;
    const { user, paymentError } = this.props;

    if (paymentError) {
      return <Loader/>
    }

    return (
      <section className="Confirmation__container Main__container Main__container--secondary grid-y gxrid-padding-x">
        <div className="Confirmation__header">
          <h1 className="Header__title">All set!</h1>
          <h2 className="Header__subtitle">
            Follow these two steps to start using your new phone number
          </h2>
        </div>
        <Grid container className="Confirmation__columns" columns={24}>
          <Grid item xs={24} sm={8} lg={8} className="Confirmation__column Confirmation__column--first u-relative">
            <div className="Confirmation__column--number">1</div>
            <p className="Confirmation__column--text medium hide-for-small show-for-medium">
              Scan this QR code with your phone camera and then download the
              Burner app on your phone
            </p>
            <QrCode className="Confirmation__qrcode show-for-middle hide-for-small" />
            <p className="Confirmation__cta-text hide-for-medium show-for-small medium">
              Download the Burner app
            </p>
            <DownloadBadge className="Confirmation__apps vertical medium-horizontal" deviceType={deviceType} />
          </Grid>
          <Grid item xs={24} sm={8} lg={8} className="Confirmation__column u-relative">
            <div className="Confirmation__column--number medium">2</div>
            <p className="Confirmation__column--text medium">
              Sign in with your phone number: <span className="bold">{renderLocalNumber(user?.phoneNumber)}</span>
            </p>
          </Grid>
        </Grid>
      </section>
    );
  }
}

ConfirmationPage.contextTypes = {
  router: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  selectedNumber: state.subscribePage.selectedNumber,
  user: state.auth.user,
  paymentError: state.subscribePage.paymentError,
  isAuthenticated: state.auth.isAuthenticated,
  error: state.error,
});

const mapDispatchToProps = {...subscribeActions, ...generalActions, ...stripeSubscribeActions, ...dashboardActions, ...errorActions }

export default connect(
  mapStateToProps, mapDispatchToProps
)(ConfirmationPage);
