import React from 'react';

export default function NoSimIcon() {
  return (
    <svg width="84" height="96" viewBox="0 0 84 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M20.1992 0H69.2168L83.2215 14.3238V78.7779H49.1778C49.3822 77.4155 49.4881 76.0209 49.4881 74.6015C49.4881 73.4697 49.4208 72.3535 49.2899 71.257H73.7897H76.7897V68.257V22.6396V21.4167L75.9347 20.5423L66.0229 10.4046L65.1403 9.50194H63.8778H29.1856H26.1856V12.5019V47.065C24.6849 46.8154 23.1437 46.6856 21.5722 46.6856C21.1119 46.6856 20.6541 46.6967 20.1992 46.7187V0ZM47.8858 65.257C45.232 57.7844 39.4854 51.7769 32.1856 48.7739V15.5019H62.6153L70.7897 23.8625V65.257H47.8858Z"
            fill="#111111"/>
      <path
        d="M21.485 53.0293C9.6312 53.0293 0 62.6605 0 74.5142C0 86.368 9.6312 95.9992 21.485 95.9992C33.3387 95.9992 42.9699 86.368 42.9699 74.5142C42.9699 62.6605 33.3387 53.0293 21.485 53.0293ZM21.485 89.1968C13.3355 89.1968 6.7351 82.5964 6.7351 74.4469C6.7351 71.6182 7.54334 68.9241 8.95771 66.7015L29.2303 86.9742C27.0077 88.3885 24.3137 89.1968 21.485 89.1968ZM34.0122 82.1923L13.7396 61.9196C15.9622 60.5052 18.6562 59.697 21.485 59.697C29.6344 59.697 36.2348 66.2974 36.2348 74.4469C36.2348 77.2756 35.3592 79.9697 34.0122 82.1923Z"
        fill="#111111"/>
    </svg>
  )
}
