import React, {Fragment} from 'react';

export function BackwardArrow({width = 32, height = 32}) {
  return (
    <Fragment>
      <svg className="button--active" width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14.9462 26.8485L5.29032 17.1927V16.7676L29.0234 16.7676V14.6796L5.29032 14.6796V14.2749L14.9462 4.619L13.3269 2.99977L3.00004 13.3267V18.1409L13.3269 28.4678L14.9462 26.8485Z"
          fill="#111111"/>
      </svg>
      <svg
        className="button--hover"
        width={width} height={height} viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.0952 26.3675L9.02734 18.7876H29.0273V13.7876L9.02734 13.7876L17.0952 5.91979L13.6941 2.51871L2.99847 13.2144V19.0729L13.6941 29.7686L17.0952 26.3675Z"
          fill="#111111"/>
      </svg>
    </Fragment>

  )
}
