import React from 'react';

import { GoogleIcon } from '../icons/google';
import { PhoneIcon } from '../icons/phone';
import { AppleIcon } from '../icons/apple';
import { renderLocalNumber } from '../../utils/phone';
import { FacebookIcon } from '../icons/facebook';

export function ExistAccountLabel({ user }) {
  if (!user) {
    return null;
  }

  function renderPlatformIcon() {
    switch (user?.linkedProviders?.toLowerCase()) {
      case 'facebook':
        return <FacebookIcon />;
      case 'google':
        return <GoogleIcon />;
      case 'apple':
        return <AppleIcon />;
      default:
        return <PhoneIcon />;
    }
  }

  return (
    <ul className="AccountExist__sso">
      <li className="AccountExist__sso--item">
        {renderPlatformIcon()}
        <span className="AccountExist__sso--number medium">
          {user.email || renderLocalNumber(user.phoneNumber)}
        </span>
      </li>
    </ul>
  )
}
