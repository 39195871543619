import React from 'react';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';

import Button from '../form/button/Button';
import { PhoneIcon } from '../icons/phone';

import '!style-loader!css-loader!sass-loader!../../../styles/create-account.scss';

function ContinueButton(props) {
  return (
    <Grid
      className="ContinueButton__list"
      container
      columns={{ xs: 12 }}
    >
      <Grid
        item
        key="Phone-number"
        xs={12}
      >
        <Button
          text="Continue with Phone"
          type="button"
          onClick={props.handleNavigateToPhone}
          icon={<PhoneIcon/>}
          disabled={props.disabled}
          styleType="white"
        />
      </Grid>
    </Grid>
  );
}

ContinueButton.propTypes = {
  handleNavigateToPhone: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

ContinueButton.defaultProps = {
  disabled: false,
};

export default ContinueButton;
