import React from 'react';
import PropTypes from 'prop-types';

import Header from '../common/Header';
import HeaderPartnerOauth from '../common/HeaderPartnerOauth';

const propTypes = {
  clientInfo: PropTypes.shape({
    color: PropTypes.string,
    name: PropTypes.string,
    sampleSku: PropTypes.string,
    thumbnailURL: PropTypes.string,
  }),
  isShowBar: PropTypes.bool,
};

function OauthAwareHeader({ clientInfo = null, isShowBar = false}) {
  if (clientInfo && clientInfo.sampleSku) {
    return <HeaderPartnerOauth clientInfo={clientInfo} />;
  }

  return <Header clientInfo={clientInfo} isShowBar={isShowBar}/>;
}

OauthAwareHeader.propTypes = propTypes;

export default OauthAwareHeader;
