import React from 'react';

export default function AddsFreeIcon() {
  return (
    <svg width="101" height="96" viewBox="0 0 101 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.2031 0V50.364C18.3851 50.167 19.6326 50.1013 20.9459 50.1013C34.6696 50.1013 45.8324 61.2642 45.8324 74.9878C45.8324 78.0084 45.3071 80.9632 44.2565 83.6554H100.859V0H17.2031ZM71.31 33.0288H62.314H55.7476H53.3181V39.5295H71.2442V59.1629H62.2484V65.1383H55.682V59.1629H46.8174V52.5966H55.8133H62.3796H64.8092V46.0959H46.8174V26.4624H55.8133V20.487H62.3796V26.4624H71.3756V33.0288H71.31Z"
        fill="#111111"/>
      <path
        d="M20.9467 54.1074C9.38991 54.1074 0 63.4973 0 75.0541C0 86.6109 9.38991 96.0008 20.9467 96.0008C32.5035 96.0008 41.8934 86.6109 41.8934 75.0541C41.8934 63.4973 32.5035 54.1074 20.9467 54.1074ZM20.9467 89.3688C13.0014 89.3688 6.56636 82.9337 6.56636 74.9884C6.56636 72.2306 7.35436 69.604 8.73329 67.4371L28.498 87.2019C26.3311 88.5808 23.7046 89.3688 20.9467 89.3688ZM33.1601 82.5398L13.3954 62.775C15.5623 61.3961 18.1888 60.6081 20.9467 60.6081C28.892 60.6081 35.3271 67.0432 35.3271 74.9884C35.3271 77.7463 34.4734 80.3729 33.1601 82.5398Z"
        fill="#111111"/>
    </svg>
  )
}

