import React from 'react';

export default function MuteIcon() {
  return (
    <svg width="122" height="97" viewBox="0 0 122 97" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M49.0332 42.2163V5.13847L40.7383 0.747054L23.8142 14.276H21.0929L49.0332 42.2163Z" fill="#111111"/>
      <path d="M8.23815 15.0551V42.9947H23.8142L40.7383 56.5237L46.6023 53.4192L8.23815 15.0551Z" fill="#111111"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M53.683 57.2709L0 3.58786L3.58786 0L57.2709 53.683L53.683 57.2709Z" fill="#111111"/>
      <path
        d="M81.8795 65.0596L86.4167 70.1364H78.2209C78.2209 70.1364 73.5963 74.8946 70.3848 78.2346C72.4063 82.3142 75.5306 85.7458 79.4033 88.1399C83.276 90.534 87.7421 91.7949 92.2951 91.7796C94.9518 91.7775 97.5903 91.3401 100.106 90.4847L81.8795 58.9141V65.0596Z"
        fill="#111111"/>
      <path
        d="M97.4436 70.1575L101.986 65.0808V54.1873L97.4281 49.0898H86.4319L85.6816 49.9275L107.006 86.8626C109.638 84.8892 111.845 82.4059 113.496 79.5609C110.521 75.8869 105.639 70.1575 105.639 70.1575H97.4436Z"
        fill="#111111"/>
      <path
        d="M92.2955 37.6602C84.4383 37.6697 76.9057 40.7955 71.3502 46.3519C65.7948 51.9083 62.6703 59.4415 62.6621 67.2988C62.6743 75.1529 65.8009 82.6814 71.356 88.2337C76.9111 93.7859 84.4414 96.9086 92.2955 96.9167C100.152 96.9086 107.685 93.7839 113.241 88.2283C118.796 82.6727 121.921 75.1401 121.929 67.2833C121.917 59.4288 118.79 51.8995 113.235 46.3464C107.68 40.7933 100.15 37.6697 92.2955 37.6602ZM69.3935 78.5931C67.6478 75.0811 66.7452 71.2104 66.7575 67.2885C66.7572 63.461 67.619 59.6825 69.279 56.2337C70.9389 52.7848 73.3543 49.7543 76.3458 47.3667L101.56 91.0435C98.6093 92.2052 95.4668 92.803 92.2955 92.806C87.5286 92.8269 82.8521 91.5048 78.8017 88.9912C74.7513 86.4775 71.4908 82.8739 69.3935 78.5931ZM106.704 88.3561L81.2633 44.2939C84.7026 42.6423 88.4699 41.787 92.2853 41.7914C99.0536 41.7982 105.543 44.49 110.329 49.2759C115.115 54.0618 117.806 60.5509 117.813 67.3192C117.812 71.7753 116.643 76.1534 114.422 80.0164C112.511 83.3337 109.876 86.1772 106.714 88.3355L106.704 88.3561Z"
        fill="#111111"/>
    </svg>
  )
}
