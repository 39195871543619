import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Box } from '@mui/material';
import { jwtDecode } from 'jwt-decode';

import * as chooseActions from '../ducks/chooseNumber';
import * as progressBar from '../ducks/progressBar';
import * as actionCreators from '../ducks/subscribe';
import * as errorActions from '../ducks/error';
import * as amplitude from '@amplitude/analytics-browser';

import Container from '../components/common/Container';
import ContinueButton from '../components/create-account/ContinueButton';
import { renderLocalNumber } from '../utils/phone';
import {AMPLITUDE_CREATE_ACCOUNT_VIEW, ROUTER_PATH, ROUTERS, SELECTED_NUMBER} from '../constants';
import { amplitudeDeviceProperty } from '../utils/analytics';

import '!style-loader!css-loader!sass-loader!../../styles/create-account.scss';
import withRegistrationCheck from '../components/WithRegistrationCheck';

class CreateAccountPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedNumber: this.props.selectedNumber,
      isSubscribe: false,
      isDisabled: false,
    };

    this.handleUpdateNumber = this.handleUpdateNumber.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleToggleCheckbox = this.handleToggleCheckbox.bind(this);
    this.handleNavigateToPhone = this.handleNavigateToPhone.bind(this);
    this.handleDisableButton = this.handleDisableButton.bind(this);
  }

  componentDidMount() {
    const { router } = this.context;
    const { currentStep, setCurrentStep } = this.props;
    const number = localStorage.getItem(SELECTED_NUMBER);

    amplitude.track(AMPLITUDE_CREATE_ACCOUNT_VIEW, amplitudeDeviceProperty);

    if (!number) {
      router.push(ROUTERS.homePage)
    } else {
      this.handleUpdateNumber(number)
    }

    if (currentStep < 3) {
      setCurrentStep(3);
    }
  }

  async handleSuccess(googleResponce) {
    try {
      const decodedToken = jwtDecode(googleResponce.credential);

      const userInfo = {
        email: decodedToken?.email,
      };

      console.log(userInfo, 'userInfo');
    } catch (error) {
      console.log('Error decoding token or making API request', error);
    }
  }

  handleError(e) {
    console.error(e)
  }

  handleDisableButton() {
    this.setState((prev) => ({
      isDisabled: !prev.isDisabled,
    }))
  }

  handleUpdateNumber(number) {
    this.setState({
      selectedNumber: number,
    })
  }

  handleToggleCheckbox() {
    this.setState(
      (prevState) => ({
        isSubscribe: !prevState.isSubscribe,
      }
    ))
  }

  handleNavigateToPhone() {
    const { router } = this.context;

    this.setState({
      isSubscribe: false,
    });

    router.push(ROUTER_PATH.createAccountPhone)
  }

  render() {
    const { selectedNumber, isDisabled } = this.state;

    return (
      <Container isShowBar classNameWrapper="u-height-full">
        <section className="CreateAccount__container Main__container Main__container--secondary grid-x gxrid-padding-x align-center-middle">
          <div className="CreateAccount__column small-12 medium-10 large-11 text-center">
            <h1 className="Header__title CreateAccount__title">
              Almost there...
            </h1>
            <h2 className="Header__subtitle CreateAccount__subtitle">
              Sign up or log in to save{' '}
              <span className="CreateAccount__selected-number">{renderLocalNumber(selectedNumber)}</span> {' '}
              to your account. It only takes a couple of seconds.
            </h2>
            <div className="CreateAccount__action">
              <ContinueButton
                disabled={isDisabled}
                handleNavigateToPhone={this.handleNavigateToPhone}
              />

              <div className="CreateAccount__policy--links">
                By continuing, you agree to our {' '}
                <Box
                  className="CreateAccount__policy--link u-link"
                  component="a"
                  href="https://www.adhoclabs.co/terms-of-service"
                  target="blank"
                  title="Opens in a new tab"
                  rel="noopener noreferrer"
                >
                  Terms of Service
                </Box>
                {' '}and
                <Box
                  className="CreateAccount__policy--link u-link"
                  component="a"
                  title="Opens in a new tab"
                  href="https://www.adhoclabs.co/privacy-policy"
                  target="blank"
                  rel="noopener noreferrer"
                >
                  {' '}Privacy Policy
                </Box>.
              </div>
            </div>
          </div>
        </section>
      </Container>
    );
  }
}

CreateAccountPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    error: state.chooseNumberPage.error,
    currentStep: state.progressBar.currentStep,
    isShowBar: state.progressBar.isShowBar,
    selectedNumber: state.subscribePage.selectedNumber,
  };
}

const mapDispatchToProps = { ...actionCreators, ...errorActions, ...chooseActions, ...progressBar };

export default connect(mapStateToProps, mapDispatchToProps)(withRegistrationCheck(CreateAccountPage));
