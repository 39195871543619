import React, { Component } from 'react';
import { Router, IndexRoute, Route, browserHistory, applyRouterMiddleware } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import store from './lib/store';

const history = syncHistoryWithStore(browserHistory, store);

import App from 'pages/App';
import DashboardPage from 'pages/DashboardPage';
import PickNumberPageContainer from './containers/PickNumberPageContainer';
import BurnerDetailsPage from 'pages/BurnerDetailsPage';
import OauthPage from 'pages/OauthPage';
import OauthErrorPage from 'pages/OauthErrorPage';
import ApplicationsPage from 'pages/ApplicationsPage';
import ConfirmationPageContainer from './containers/ConfirmationPageContainer';
import { CUSTOM_WEB_PAGEVIEW } from './constants.js';
import CreateAccountPage from './pages/CreateAccountPage';
import SocialProof from './pages/SocialProof';
import NumberCongratsPage from './pages/NumberCongratsPage';
import PaywallPage from './pages/PaywallPage';
import PhoneNumberSignupPage from './pages/PhoneNumberSignupPage';
import PhoneNumberLoginPage from './pages/PhoneNumberLoginPage';
import LoginPage from './pages/LoginPage';
import AppAccountExistPage from './pages/AppAccountExistPage';
import CheckoutPage from './pages/CheckoutPage';
import ChoosePhoneNumberPage from './pages/ChoosePhoneNumberPage';
import ChooseAreaCode from './pages/ChooseAreaCode';

class Routes extends Component {
  handleOnUpdate() {
    const logPageView = () => {
      dataLayer.push({
        event: CUSTOM_WEB_PAGEVIEW,
        virtualPageURL: window.location.pathname,
      });
    };

    logPageView();
  }

  render() {
    return (
      <Router
        history={history}
        onUpdate={this.handleOnUpdate}
        render={applyRouterMiddleware()}
      >
        <Route path="/" component={App}>
          <IndexRoute component={ChooseAreaCode} />
          <Route path="choose-phone-number" component={ChoosePhoneNumberPage} />
          <Route
            path="create-account"
            component={CreateAccountPage}
          />
          <Route
            path="create-account/phone"
            component={PhoneNumberSignupPage}
          />
          <Route
            path="areacode/:areaCode"
            component={PickNumberPageContainer}
          />
          <Route
            path="social-proof"
            component={SocialProof}
          />
          <Route path="checkout" component={CheckoutPage} />
          <Route path="confirmation" component={ConfirmationPageContainer} />
          <Route path="number-congrats" component={NumberCongratsPage} />
          <Route path="paywall" component={PaywallPage} />
          <Route path="dashboard" component={DashboardPage} />
          <Route path="burner/:burnerId" component={BurnerDetailsPage} />
          <Route path="oauth/authorize" component={OauthPage} />
          <Route path="oauth/error" component={OauthErrorPage} />
          <Route path="applications" component={ApplicationsPage} />
          <Route path="login" component={LoginPage} />
          <Route path="login/phone" component={PhoneNumberLoginPage} key={'login'} />
          <Route path="app-account-exists" component={AppAccountExistPage} />
          <Route path="*" component={ChoosePhoneNumberPage} />
        </Route>
      </Router>
    );
  }
}

export default Routes;
