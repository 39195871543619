import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

import * as chooseActions from '../ducks/chooseNumber';
import * as subscribeActions from '../ducks/subscribe';
import * as errorActions from '../ducks/error';
import * as verifyActions from '../ducks/verify';
import * as dashboardActions from '../ducks/dashboard';

import { QrCode } from '../components/confirmation/QrCode';
import {AUTHENTICATION_KEY, ROUTERS} from '../constants';
import Container from '../components/common/Container';
import DownloadBadge from '../components/confirmation/DownloadBadge';
import { ExistAccountLabel } from '../components/subscribe/ExistAccountLabel';
import { getCurrentUser, handleAuthCheck, userDeviceType } from '../utils/user';

import '!style-loader!css-loader!sass-loader!../../styles/account-exist.scss';
import cookie from 'react-cookie';
import { validateSubscriptions } from '../utils/subscription';

class AppAccountExistPage extends Component {
  constructor() {
    super();
    this.state = {
      deviceType: null,
    }

    this.handleSetDeviceType = this.handleSetDeviceType.bind(this);
  }

  async componentDidMount() {
    const userAgent = navigator.userAgent;
    const { loadUserDetails } = this.props;
    const user = getCurrentUser();
    const { router } = this.context;
    const authToken = cookie.load(AUTHENTICATION_KEY);

    if (!user) {
      return router.push(ROUTERS.chooseNumber)
    }

    await loadUserDetails(authToken, user.id, ROUTERS.login);

    if (!this.state.deviceType && window.innerWidth < 753) {
      this.handleSetDeviceType(userAgent);
    }

    document.addEventListener('visibilitychange', this.handleAuthenticateCheck);
  }

  async componentDidUpdate() {
    const { subscriptions } = this.props;
    const { router } = this.context;

    if (subscriptions && !validateSubscriptions(subscriptions)) {
      return router.push(ROUTERS.dashboard)
    }
  }

  componentWillUnmount() {
    document.removeEventListener('visibilitychange', this.handleAuthenticateCheck);
  }

  handleSetDeviceType(deviceType) {
    this.setState({
      deviceType: userDeviceType(deviceType),
    })
  }

  handleAuthenticateCheck = () => {
    const { router } = this.context;
    const { userIsAuthentificate } = this.props;

    handleAuthCheck(router, userIsAuthentificate);
  }

  render() {
    const { deviceType } = this.state;
    const user = getCurrentUser();

    return (
      <Container>
        <section
          className="AccountExist__container Main__container Main__container--secondary grid-x gxrid-padding-x grid-x align-center-middle">
          <div className="medium-10 small-12">
            <h1 className="Header__title AccountExist__title">
              Heads up! You have a mobile account
            </h1>
            <ExistAccountLabel user={user}/>
            <h2 className="Header__subtitle AccountExist__subtitle medium">
              We don’t want you paying for multiple subscriptions. Download the Burner mobile app to login.
            </h2>

            <Grid container className="AccountExist__apps" columns={24}>
              <Grid item xs={24} sm={12} lg={12} className="AccountExist__apps--text u-relative">
                <p className="medium hide-for-small show-for-medium">
                  Scan this QR code with your phone camera and then download the
                  Burner app on your phone
                </p>
                <p className="AccountExist__cta-text hide-for-medium show-for-small medium">
                  Download the Burner app
                </p>
                <DownloadBadge className="AccountExist__badge vertical medium-horizontal" deviceType={deviceType} />
              </Grid>
              <Grid item xs={24} sm={4} lg={4} className="AccountExist__column u-relative show-for-medium hide-for-small">
                <QrCode className="AccountExist__qrcode "/>
              </Grid>
            </Grid>
          </div>
        </section>
      </Container>
    );
  }
}

AppAccountExistPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    error: state.error,
    subscriptions: state.dashboardPage.subscriptions,
  };
}

const mapDispatchToProps = {
  ...verifyActions,
  ...subscribeActions,
  ...errorActions,
  ...chooseActions,
  ...dashboardActions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(AppAccountExistPage));
