import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {ROUTERS, VERIFY_SENT} from '../constants';
import ContinueButton from '../components/create-account/ContinueButton';
import Container from '../components/common/Container';

import '!style-loader!css-loader!sass-loader!../../styles/login.scss';
import { removeStorageData } from '../utils/sessionStorage';
import withRegistrationCheck from '../components/WithRegistrationCheck';

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubscribe: false,
    };

    this.handleNavigateToPhone = this.handleNavigateToPhone.bind(this);
  }

  componentDidMount() {
    removeStorageData(VERIFY_SENT);
    sessionStorage.removeItem('tab_is_change');
  }

  handleNavigateToPhone() {
    const { router } = this.context;

    this.setState({
      isSubscribe: false,
    });

    router.push(ROUTERS.loginByPhone)
  }

  render() {
    return (
      <Container classNameWrapper="u-height-full">
        <section className="Login__container Main__container Main__container--secondary grid-x gxrid-padding-x align-center-middle">
          <div className="Login__column small-12 medium-10 large-11 text-center">
            <h1 className="Header__title Login__title">
              Sign in to your account
            </h1>
            <div className="CreateAccount__action Login__action">
              <ContinueButton
                handleNavigateToPhone={this.handleNavigateToPhone}
                onError={this.handleError}
              />
            </div>
          </div>
        </section>
      </Container>
    );
  }
}

LoginPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default withRegistrationCheck(LoginPage);
