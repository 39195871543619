import React, { Fragment } from 'react';
export function MashableIcon() {
  return (
    <Fragment>
      <svg width="119" height="20" viewBox="0 0 119 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="show-for-large hide-for-small">
        <g clipPath="url(#clip0_8187_7855)">
          <path
            d="M16.1447 0.926758L10.6101 10.5656L5.07551 0.926758H5.05567H0.433594V19.7066H5.05567V10.2271L8.90409 16.9185L9.5984 18.1134H11.6218L12.4946 16.56L16.1447 10.2271V19.7066H20.7668V0.926758H16.1447Z"
            fill="#333333"/>
          <path
            d="M32.1522 12.8973C32.1522 11.4833 31.2596 10.4278 29.9701 10.4278C28.6212 10.4278 27.7087 11.5829 27.7087 12.8973C27.7087 14.2714 28.6212 15.3667 29.9701 15.3667C31.2596 15.3667 32.1522 14.3112 32.1522 12.8973ZM22.7891 12.9371C22.7891 8.83459 25.5861 6.06641 28.4228 6.06641C30.3867 6.06641 31.6365 7.08207 32.3705 8.09774L32.8267 6.42488H36.9727V19.3497H32.8267L32.3705 17.6769C31.6365 18.7124 30.3867 19.7082 28.4228 19.7082C25.5861 19.7281 22.7891 16.9599 22.7891 12.9371Z"
            fill="#333333"/>
          <path
            d="M48.9951 6.9825V10.9257C48.0826 10.4079 46.4163 9.92992 45.0078 9.92992C44.1945 9.92992 43.7581 10.0892 43.7581 10.4079C43.7581 10.7265 44.2143 10.9257 45.4641 11.2045C47.6858 11.6824 49.9076 12.479 49.9076 15.2871C49.9076 17.9955 47.785 19.7082 43.9168 19.7082C41.933 19.7082 40.3064 19.2501 39.2153 18.6527V14.4307C40.3857 15.2074 41.7942 15.8447 43.6986 15.8447C44.6507 15.8447 45.2062 15.6854 45.2062 15.3269C45.2062 14.9087 44.5119 14.7693 42.9646 14.371C40.9809 13.8532 39.0566 12.8773 39.0566 10.4477C39.0566 7.9185 41.1197 6.06641 44.7698 6.06641C46.2972 6.06641 47.9239 6.40496 48.9951 6.9825Z"
            fill="#333333"/>
          <path
            d="M56.671 7.41884H56.6908C57.4049 6.66207 58.5952 6.0447 60.0036 6.0447C62.7411 6.0447 64.4472 7.71756 64.4472 10.6252V19.3081H59.5474V11.8997C59.5474 10.8442 59.0911 10.3862 58.1984 10.3862C57.2661 10.3862 56.6908 11.0035 56.6908 11.8201V19.2882H51.791V0.886719H56.6908V7.41884H56.671Z"
            fill="#333333"/>
          <path
            d="M75.4784 12.8973C75.4784 11.4833 74.5857 10.4278 73.2963 10.4278C71.9474 10.4278 71.0349 11.5829 71.0349 12.8973C71.0349 14.2714 71.9474 15.3667 73.2963 15.3667C74.5857 15.3667 75.4784 14.3112 75.4784 12.8973ZM66.1152 12.9371C66.1152 8.83459 68.9123 6.06641 71.749 6.06641C73.7129 6.06641 74.9626 7.08207 75.6966 8.09774L76.1529 6.42488H80.2989V19.3497H76.1529L75.6966 17.6769C74.9626 18.7124 73.7129 19.7082 71.749 19.7082C68.9123 19.7281 66.1152 16.9599 66.1152 12.9371Z"
            fill="#333333"/>
          <path
            d="M87.5783 12.8556C87.5783 14.2895 88.4709 15.3251 89.7604 15.3251C91.0894 15.3251 92.002 14.2497 92.002 12.8755C92.002 11.5213 91.0895 10.3862 89.7405 10.3862C88.4511 10.3862 87.5783 11.4417 87.5783 12.8556ZM87.6179 8.01629C88.3519 6.92096 89.8595 6.0447 91.4465 6.0447C94.4023 6.0447 96.9216 8.71331 96.9216 12.8556C96.9216 16.998 94.1841 19.6666 91.2283 19.6666C89.3636 19.6666 88.0742 18.6509 87.3601 17.6352L86.9038 19.3081H82.7578V0.886719H87.6378L87.6179 8.01629Z"
            fill="#333333"/>
          <path d="M103.667 0.886719H98.7871V19.3081H103.667V0.886719Z" fill="#333333"/>
          <path
            d="M113.784 11.4829C113.765 10.5867 113.17 9.81004 112.118 9.81004C111.166 9.81004 110.372 10.4473 110.035 11.4829H113.784ZM118.268 14.1913H110.214C110.829 15.4261 112.237 16.0036 113.765 16.0036C115.153 16.0036 116.383 15.5456 117.514 15.0477V18.7917C116.185 19.429 114.697 19.7476 113.07 19.7476C108.151 19.7476 105.473 16.84 105.473 12.9367C105.473 8.89395 108.349 6.08594 112.138 6.08594C115.57 6.08594 118.367 8.37616 118.367 12.7176C118.387 13.1956 118.347 13.6935 118.268 14.1913Z"
            fill="#333333"/>
        </g>
        <defs>
          <clipPath id="clip0_8187_7855">
            <rect width="117.952" height="18.8396" fill="white" transform="translate(0.433594 0.886719)"/>
          </clipPath>
        </defs>
      </svg>
      <svg width="86" height="15" viewBox="0 0 86 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="hide-for-large show-for-small">
        <path
          d="M11.4551 0.0292969L7.41978 7.19209L3.38446 0.0292969H3.37H0V13.9849H3.37V6.9405L6.17592 11.913L6.68214 12.801H8.15742L8.79381 11.6466L11.4551 6.9405V13.9849H14.8251V0.0292969H11.4551Z"
          fill="#111111"/>
        <path
          d="M23.1276 8.92377C23.1276 7.87303 22.4767 7.08867 21.5366 7.08867C20.5531 7.08867 19.8877 7.94702 19.8877 8.92377C19.8877 9.94491 20.5531 10.7589 21.5366 10.7589C22.4767 10.7589 23.1276 9.9745 23.1276 8.92377ZM16.3008 8.95336C16.3008 5.90474 18.3401 3.84766 20.4084 3.84766C21.8403 3.84766 22.7515 4.60241 23.2867 5.35717L23.6193 4.11404H26.6422V13.7187H23.6193L23.2867 12.4756C22.7515 13.2451 21.8403 13.9851 20.4084 13.9851C18.3401 13.9999 16.3008 11.9428 16.3008 8.95336Z"
          fill="#111111"/>
        <path
          d="M35.4064 4.52842V7.45865C34.7411 7.07387 33.5261 6.71869 32.4992 6.71869C31.9062 6.71869 31.588 6.83708 31.588 7.07387C31.588 7.31066 31.9207 7.45865 32.8319 7.66584C34.4518 8.02102 36.0717 8.61298 36.0717 10.6997C36.0717 12.7123 34.5241 13.9851 31.7037 13.9851C30.2574 13.9851 29.0714 13.6447 28.2759 13.2007V10.0633C29.1292 10.6405 30.1561 11.114 31.5446 11.114C32.2389 11.114 32.6438 10.9956 32.6438 10.7293C32.6438 10.4185 32.1376 10.3149 31.0095 10.0189C29.5631 9.63412 28.1602 8.90897 28.1602 7.10347C28.1602 5.22398 29.6644 3.84766 32.3257 3.84766C33.4393 3.84766 34.6254 4.09924 35.4064 4.52842Z"
          fill="#111111"/>
        <path
          d="M41.0033 4.85412H41.0178C41.5385 4.29175 42.4063 3.83298 43.4332 3.83298C45.4291 3.83298 46.673 5.07611 46.673 7.23679V13.6892H43.1005V8.18393C43.1005 7.39958 42.7679 7.0592 42.117 7.0592C41.4372 7.0592 41.0178 7.51797 41.0178 8.12474V13.6744H37.4453V0H41.0178V4.85412H41.0033Z"
          fill="#111111"/>
        <path
          d="M54.7174 8.92377C54.7174 7.87303 54.0665 7.08867 53.1264 7.08867C52.1429 7.08867 51.4776 7.94702 51.4776 8.92377C51.4776 9.94491 52.1429 10.7589 53.1264 10.7589C54.0665 10.7589 54.7174 9.9745 54.7174 8.92377ZM47.8906 8.95336C47.8906 5.90474 49.93 3.84766 51.9983 3.84766C53.4301 3.84766 54.3413 4.60241 54.8765 5.35717L55.2092 4.11404H58.232V13.7187H55.2092L54.8765 12.4756C54.3413 13.2451 53.4301 13.9851 51.9983 13.9851C49.93 13.9999 47.8906 11.9428 47.8906 8.95336Z"
          fill="#111111"/>
        <path
          d="M63.5381 8.89429C63.5381 9.95983 64.1889 10.7294 65.129 10.7294C66.0981 10.7294 66.7634 9.93023 66.7634 8.90909C66.7634 7.90275 66.0981 7.0592 65.1146 7.0592C64.1745 7.0592 63.5381 7.84355 63.5381 8.89429ZM63.567 5.2981C64.1021 4.48414 65.2014 3.83298 66.3584 3.83298C68.5135 3.83298 70.3504 5.81607 70.3504 8.89429C70.3504 11.9725 68.3544 13.9556 66.1994 13.9556C64.8398 13.9556 63.8997 13.2008 63.379 12.4461L63.0463 13.6892H60.0234V0H63.5815L63.567 5.2981Z"
          fill="#111111"/>
        <path d="M75.269 0H71.7109V13.6892H75.269V0Z" fill="#111111"/>
        <path
          d="M82.6462 7.87288C82.6317 7.20691 82.1978 6.62975 81.4312 6.62975C80.737 6.62975 80.1584 7.10332 79.9126 7.87288H82.6462ZM85.9149 9.88556H80.0427C80.4911 10.8031 81.518 11.2323 82.6317 11.2323C83.6441 11.2323 84.5409 10.8919 85.3653 10.5219V13.3042C84.3962 13.7777 83.3115 14.0145 82.1255 14.0145C78.5385 14.0145 76.5859 11.8538 76.5859 8.95321C76.5859 5.94899 78.6831 3.8623 81.4457 3.8623C83.9479 3.8623 85.9872 5.56421 85.9872 8.79042C86.0017 9.1456 85.9728 9.51558 85.9149 9.88556Z"
          fill="#111111"/>
      </svg>
    </Fragment>
  )
}
