import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-modal';

import NumberInput from '../form/input/NumberInput';
import * as actionCreators from '../../ducks/subscribe';
import * as errorActions from '../../ducks/error';
import { formatE164, formatLocal, isValidNumber } from '../../utils/phone';
import { SELECTED_NUMBER } from '../../constants';
import { getStoragePlanData } from '../../utils/sessionStorage';

const propTypes = {
  codeWasSent: PropTypes.bool,
  isFetching: PropTypes.bool.isRequired,
  isResend: PropTypes.bool,
  modalToggled: PropTypes.func.isRequired,
  parentForm: PropTypes.object,
  resendVerifyCode: PropTypes.func.isRequired,
  resetVerifyCodeError: PropTypes.func.isRequired,
  selectedNumber: PropTypes.string,
  sku: PropTypes.string.isRequired,
  subscription: PropTypes.shape({
    totalConcurrentBurners: PropTypes.number,
    totalBurnersAllowedInPeriod: PropTypes.number,
    sku: PropTypes.string,
  }),
  validateCodeAndRegister: PropTypes.func.isRequired,
  verifyErrorMessage: PropTypes.string,
};

const defaultProps = {
  codeWasSent: false,
  isResend: false,
  parentForm: null,
  selectedNumber: null,
  subscription: null,
  verifyErrorMessage: null,
};

class VerifyCodeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      pin: '',
    };

    this.handleVerifyCodeChange = this.handleVerifyCodeChange.bind(this);
    this.handleVerifyCodeSubmit = this.handleVerifyCodeSubmit.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.resendCode = this.resendCode.bind(this);
    this.renderResentMessage = this.renderResentMessage.bind(this);
    this.renderErrorSection = this.renderErrorSection.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { codeWasSent, modalToggled, subscription, paymentError, isFetching } = this.props;

    if (paymentError && this.state.modalIsOpen && isFetching) {
      this.closeModal();
    }

    if (codeWasSent === prevProps.codeWasSent && subscription === prevProps.subscription) {
      return;
    }

    if (codeWasSent) {
      this.openModal();
      modalToggled();
    } else if (subscription) {
      this.closeModal();
    }
  }

  componentWillUnmount() {
    this.closeModal();
  }

  handleVerifyCodeChange(event) {
    const { resetVerifyCodeError } = this.props;
    const { value } = event.target;

    this.setState(() => ({
      pin: value,
    }));
    if (value === '') {
      resetVerifyCodeError();
    }
  }

  handleVerifyCodeSubmit() {
    const { pin } = this.state;
    const { parentForm, selectedNumber, validateCodeAndRegister } = this.props;
    const sessionNumber = localStorage.getItem(SELECTED_NUMBER);

    const formData = {
      email: parentForm.values.email,
      firstName: parentForm.values.firstName,
      lastName: parentForm.values.lastName,
      phoneNumber: formatE164(parentForm.values.phoneNumber),
      number: parentForm.values.creditCard,
      expMonth: parentForm.values.expMonth,
      expYear: parentForm.values.expYear,
      cvc: parentForm.values.cvc,
      zipCode: parentForm.values.zipCode,
      sku: getStoragePlanData('sku'), // these are from the store and not the form, since the redux form doesn't parse hidden fields.
      selectedNumber: selectedNumber || sessionNumber,
    };
    validateCodeAndRegister(formData, pin);
  }

  openModal() {
    this.setState(() => ({
      modalIsOpen: true,
    }));
  }

  closeModal() {
    this.setState(() => ({
      modalIsOpen: false,
    }));
  }

  formatPretty(number) {
    if (isValidNumber(number)) return formatLocal(number);
  }

  resendCode() {
    const { resendVerifyCode, parentForm } = this.props;

    resendVerifyCode(formatE164(parentForm.values.phoneNumber));
  }

  renderResentMessage() {
    const { isResend } = this.props;

    if (!isResend) {
      return null;
    }
    return (
      <div data-alert="" className="info box">
        <strong className="title">Verification Code Resent!</strong>
      </div>
    );
  }

  renderErrorSection() {
    const { verifyErrorMessage } = this.props;

    if (!verifyErrorMessage) {
      return null;
    }
    return <div className="error error-msg center">{verifyErrorMessage}</div>;
  }

  render() {
    const { modalIsOpen, pin } = this.state;
    const { parentForm, isFetching } = this.props;

    return (
      <Modal
        contentLabel="Verify Code Modal"
        isOpen={modalIsOpen}
        onRequestClose={this.closeModal}
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        bodyOpenClassName={null}
        className="burner-modal"
        overlayClassName="burner-modal-overlay"
        ariaHideApp={false}
      >
        <div id="verifyModal">
          <div className="row">
            <div className="large-12 columns">
              {this.renderResentMessage()}
              <p className="verify-desc">A verification code has been sent to:</p>
              <h4 className="to-number">
                {parentForm && parentForm.values && parentForm.values.phoneNumber !== undefined
                  ? this.formatPretty(parentForm.values.phoneNumber)
                  : ''}
              </h4>
              <p className="big-header">Enter verification code</p>
              <NumberInput
                autoFocus
                className="verify-code"
                id="verifyCode"
                maxLength="6"
                onChange={this.handleVerifyCodeChange}
              />
              <hr className="input-bottom-line" />
              {this.renderErrorSection()}
              <button className="resend-text" onClick={this.resendCode} type="button">
                resend code
              </button>
              <button
                type="submit"
                disabled={!pin || isFetching}
                onClick={this.handleVerifyCodeSubmit}
                className="flat button confirm-vc-button submit"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

VerifyCodeModal.propTypes = propTypes;
VerifyCodeModal.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    codeWasSent: state.verifyPage.codeWasSent,
    isFetching: state.verifyPage.isFetching,
    isResend: state.verifyPage.isResend,
    parentForm: state.form.subscribe,
    selectedNumber: state.subscribePage.selectedNumber,
    sku: state.subscribePage.sku,
    subscription: state.subscribePage.subscription,
    paymentError: state.subscribePage.paymentError,
    verifyErrorMessage: state.verifyPage.verifyErrorMessage || state.error.errorMessage,
  };
}

const mapDispatchToProps = { ...actionCreators, ...errorActions };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerifyCodeModal);
