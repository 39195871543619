import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const propTypes = {
  isLoading: PropTypes.bool,
};

function Loader({ isLoading = false }) {
  if (!isLoading) {
    return null;
  }

  return (
    <div className="loader__container">
      <div className="loader">
        <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M33.7082 18.9712C33.7082 27.6021 26.7346 34.6042 18.1146 34.6504C18.0551 34.6507 17.9955 34.6507 17.9359 34.6503C9.3551 34.6005 2.40757 27.6579 2.34984 19.079C2.34938 19.0114 2.34935 18.9439 2.34976 18.8764C2.40077 10.2606 9.40099 3.29186 18.0288 3.29186V0.942383C17.2402 0.942383 16.4554 0.994109 15.6794 1.09611C12.9466 1.4553 10.3216 2.43792 8.01255 3.98079C5.04773 5.96182 2.73692 8.77754 1.37236 12.0719C0.476423 14.2349 0.0148229 16.5404 0.000343506 18.8587C0.000114634 18.8962 0 18.9337 0 18.9712C0 19.0126 0.000139005 19.0538 0.000416648 19.0951C0.00820236 20.2293 0.123038 21.3655 0.346419 22.4885C1.04207 25.9857 2.75915 29.1982 5.28053 31.7195C5.33962 31.7786 5.39909 31.8373 5.45894 31.8955C7.95264 34.3208 11.0963 35.9743 14.5116 36.6536C15.6346 36.877 16.7708 36.9919 17.905 36.9996C17.9462 36.9999 17.9875 37.0001 18.0288 37.0001C18.0652 37.0001 18.1015 37 18.1378 36.9997C20.4573 36.9857 22.7641 36.5241 24.9282 35.6277C28.2225 34.2631 31.0382 31.9523 33.0193 28.9875C34.5621 26.6784 35.5448 24.0534 35.9039 21.3207C36.006 20.5446 36.0577 19.7599 36.0577 18.9712H33.7082Z"
            fill="#88F1C7"
          />
        </svg>
      </div>
    </div>
  );
}

Loader.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    isLoading: state.loading.isLoading,
  };
}

export default connect(mapStateToProps)(Loader);
