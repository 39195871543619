import React from 'react';
import { Checkbox } from '@mui/material';
import classNames from 'classnames';
import { bool, func, number, shape, string } from 'prop-types';

import MarkIcon from '../icons/paywall/markIcon';

class SubscriptionCheckboxV2 extends React.Component {
  render() {
    const { planOptions, value, onChange } = this.props;
    const subscriptions = Object.values(planOptions).filter(sub => sub.numLines === 1);

    return subscriptions.map(plan => {
      const priceYearly = plan.isYearly && <span>${Math.floor(Number(plan.price) / 12 * 100) / 100} per month. </span>
      const isChecked = plan.sku === value;
      const isMostPopular = !plan.isYearly;

      return (
        <label className="u-relative" key={plan.sku}>
          <div className={classNames('Paywall__subscriptions--plan Paywall__subscriptions--plan-v2 grid-y', isChecked && 'active-v2')}>
            {isMostPopular && <span className="Paywall__plan--popular-v2">Most Popular</span>}
            <div className="grid-x Paywall__grid-v2">
              <div className="Paywall__plan--container-v2 grid-x align-justify">
                <h3 className="Paywall__plan--title-v2 bold">{plan.name}</h3>
              </div>
              <p className="Paywall__plan--subtitle Paywall__plan--subtitle-v2">
                {priceYearly}
                <span>Billed {plan.isYearly ? 'yearly' : 'monthly'} at ${plan.price}</span>
              </p>
            </div>
          </div>
          <div>
            <Checkbox
              value={plan.sku}
              sx={{'& .MuiSvgIcon-root': {fontSize: 24}}}
              checked={isChecked}
              onChange={() => onChange(plan)}
              checkedIcon={
                <MarkIcon />
              }
            />
          </div>
        </label>
      )
    })
  }
}

SubscriptionCheckboxV2.propTypes = {
  value: string.isRequired,
  onChange: func.isRequired,
  planOptions:
    shape({
      oneLineMonthly: shape({
        price: string.isRequired,
        isYearly: bool.isRequired,
        numLines: number.isRequired,
      }),
      oneLineYearly: shape({
        price: string.isRequired,
        isYearly: bool.isRequired,
        numLines: number.isRequired,
      }),
    }
  ).isRequired,
}

export default React.memo(SubscriptionCheckboxV2);
